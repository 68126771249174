/* icons */
.iconArrowDownGray {
  height: 6px;
  width: 16px;
  background: url('./assets/downArrowGray.svg') no-repeat;
  display: flex;
  transition: transform 0.3s ease;
}

.logContainer {
  display: flex;
  flex-direction: column;
  padding: 60px 60px 65px 60px;
}

.logContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}

.viewDetailsButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
}

.logBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.logBody.open {
  max-height: 1000px;
  opacity: 1;
}

.logBody div {
  cursor: pointer;
}

.logDate {
  font-family: robotoBold;
}

.logDetails {
}

.iconArrowDownGray.open {
  transform: rotate(180deg);
}
