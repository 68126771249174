.iconPlus {
  height: 34px;
  width: 34px;
  background: url('../../AddService/assets/plus.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  max-width: 1560px;
  width: 100%;
  height: 85vh;
  max-height: 900px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 30px 20px;
  border-bottom: 1px solid #e7e7e7;
  z-index: 10;
}

.modalTitle {
  font-family: 'robotoBold' !important;
  font-size: 24px;
  line-height: 28.13px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.modalContent {
  flex: 1;
  overflow: auto;
  background-color: #fafafa;
  margin-top: -1px;
}
