.container {
  padding-bottom: 24px;
}

.header {
  padding: 36px;
}

.title {
  font-family: robotoBold;
  font-size: 18px !important;
  line-height: 24.67px;
  text-align: left;
}

.body {
  padding: 0 36px;
}

.tableHeader,
.tableRow {
  display: flex;
  align-items: center;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.tableHeader {
  padding: 12px 30px;
  background-color: #f4f7f9;
  border-radius: 10px;
}

.tableHeader > span {
  font-family: robotoBold;
}

.tableRow {
  border-bottom: 1px solid #f2f2f2;
  padding: 18px 30px;
}

.tableRow:last-child {
  border-bottom: none;
}

.columnImage {
  width: 30%;
}

.columnNumber {
  width: 25%;
}

.columnType {
  width: 25%;
}

.columnAmount {
  width: 25%;
  text-align: right;
}
