/* icons */
.iconArrowDown {
  height: 9px;
  width: 12px;
  background: url('./assets/downArrow.svg') no-repeat;
  display: flex;
}

/* invoice subheader */

.invoiceSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
}

.invoiceSubHeader * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

.subHeaderLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invoice {
  font-size: 18px !important;
}

.invoiceNumber {
  font-size: 16px;
  font-family: robotoBold;
}

.subHeaderRight {
  display: flex;
  align-items: center;
  gap: 24px;
}

.invoiceStatus {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #f0f9ff;
}

/* invoice summary */
.invoiceSummary {
  display: flex;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 54px;
}

.sumarryPaidTag {
  margin: 0 32px 0 48px;
  padding: 4px 12px;
  border-radius: 25px;
  font: interBold;
  font-size: 12px;
  font-family: robotoBold;
  color: #44c989;
  background: #e3fff2;
}

.summaryCustomer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
}

.summaryCustomer span {
  text-decoration: underline;
}

.summaryDate {
  display: flex;
  align-items: center;
  font-family: robotoBold;
  gap: 6px;
}

.summaryDate span {
  font-weight: 400;
}

.summaryInvoiceNumber {
  margin-left: auto;
}
