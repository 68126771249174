.orderTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
  border-radius: 8px;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  margin-left: 24px;
  margin-bottom: 16px;
}

.orderTable th {
  padding: 12px 16px;
  text-align: left;
  background: #f4f7f9;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.orderTable td {
  padding: 16px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.orderTable th:first-child,
.orderTable td:first-child {
  padding-left: 42px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.orderTable th:last-child,
.orderTable td:last-child {
  padding-right: 42px;
  text-align: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.spacer {
  min-width: 200px;
}

.viewButton {
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  cursor: pointer;
}
