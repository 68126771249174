.iconEdit {
  height: 16px;
  width: 16px;
  background: url('./assets/editIcon.svg') no-repeat;
  display: flex;
}

.iconUpdate {
  height: 16px;
  width: 16px;
  background: url('./assets/updateIcon.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContainer {
  max-width: 768px;
  width: 100%;
  height: 80vh;
  max-height: 900px;
  background: #f7f9fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.modalHeader {
  padding: 30px 36px 30px 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
}

.headerTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.headerTitle {
  font-family: robotoBold;
  font-size: 22px !important;
  line-height: 25.78px;
}

.headerSubtitle {
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.iconClose {
  height: 34px;
  width: 34px;
  background: url('../../assets/close.svg') no-repeat;
  display: flex;
}

.orderStatusContainer {
  padding: 24px 66px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  gap: 16px;
  align-items: center;
  background: #ffffff;
}

.statusDetailsContainer {
  padding: 24px 66px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  gap: 60px;
  background: #ffffff;
}

.statusColumn {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.statusLabel {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.statusValue {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.orderStatusLabel {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.modalBody {
  padding: 36px;
  height: calc(100% - 200px);
  overflow-y: auto;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 24px 36px;
  margin-top: auto;
}

.footerLeft {
  /* Left footer content */
}

.footerRight {
  display: flex;
  gap: 16px;
}

.materialsTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  display: flex;
  padding: 0 36px 16px 26px;
  gap: 50px;
}

.materialHeader {
  flex: 1;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.qtyHeader {
  width: 80px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: right;
}

.priceHeader {
  width: 80px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: right;
}

.materialsContainer {
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.materialRow {
  display: flex;
  padding: 16px 36px;
  border-bottom: 1px solid #eeeeee;
  background: #ffffff;
  gap: 50px;
}

.materialCell {
  flex: 1;
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.qtyCell {
  width: 80px;
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.priceCell {
  width: 80px;
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.supplierName {
  font-family: robotoRegular;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14.06px;
  color: #5b6064;
  margin-top: 4px;
}

.totalRow {
  display: flex;
  justify-content: flex-end;
  padding: 24px 36px 30px;
  gap: 50px;
  background: #ffffff;
}

.totalLabel {
  width: 80px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: right;
}

.totalAmount {
  width: 80px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: right;
}

.specialInstructions {
  padding: 24px 36px;
  margin-top: 24px;
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #0e1e29;
  background: #ffffff;
  border-radius: 10px;
}
