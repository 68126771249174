/* icons */
.iconBriefCase {
	height: 16px;
	width: 16px;
	background: url("./assets/briefcase.svg") no-repeat;
	display: flex;
}

.subJobs {
	display: flex;
	align-items: center;
	gap: 48px;
	padding: 12px 36px;
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 0px 10px 12px 0px #f3f3f380;
	margin-bottom: 16px;
}

.subJobs * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

.left {
	display: flex;
	align-items: center;
	gap: 12px;
}

.right {
	display: flex;
	align-items: center;
	gap: 12px;
}
