/* Modal */
.fontBig {
  font-size: 18px;
}

.fontMedium {
  font-size: 16px;
}

.fontSmall {
  font-size: 14px;
}

.fontSmaller {
  font-size: 12px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContentPropertyDetails,
.modalContentJobDetails {
  background-color: rgba(247, 249, 251, 1);
  padding: 0px 35px 35px 35px;
  border-radius: 8px;
  width: 1145px;
  height: 80vh;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
  overflow-x: hidden;
}

.modalNotesContainer {
  border-bottom: 1px solid rgba(231, 231, 231, 1);
  padding-bottom: 35px;
  margin: 0px -35px 0px -35px;
  padding: 0px 35px 35px 35px;
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 35px;
  margin: 0px -36px 0px -36px;
  padding: 36px 36px 36px 36px;
  border-bottom: 1px solid rgba(231, 231, 231, 1);
  background-color: white;
  position: sticky;
  top: 0;
}

.modalHeader h2 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  white-space: nowrap;
}

.jobTabContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.jobHeaderTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 8px 28px 8px 28px;
  cursor: pointer;
}

.jobHeaderTabSelected {
  border-radius: 10px;
  background-color: rgba(242, 242, 242, 1);
}

.greyCircle {
  height: 31px;
  width: 31px;
  border-radius: 57px;
  border: 1px solid rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalPropertyLocation,
.customerJobLocation {
  height: 207px;
  width: 717px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  padding: 36px;
  margin-top: 16px;
  margin-bottom: 36px;
}

.customerJobLocation {
  height: 156px;
  margin-bottom: 49px;
}

.modalPropertyFlex {
  display: flex;
  gap: 5px;
}

.selectPropertyLocation,
.contactRelationshipDropdown,
.jobPrioServiceDropdown,
.bookingDropdown,
.jobCallOutDropdown,
.specialBookingDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(246, 246, 246, 1);
  height: 40px;
  width: 645px;
  padding: 10px 24px 10px 24px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 16px;
}

.contactRelationshipDropdown {
  width: 302px;
  padding: 15px 20px 15px 20px;
  box-sizing: border-box;
  margin-top: 9px;
  height: 49px;
}

.createPropertyButton {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 216px;
  height: 44px;
  padding: 13px 20px 13px 20px;
  box-sizing: border-box;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-top: 16px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalInformationContainer {
  margin-top: 36px;
  display: flex;
  gap: 24px;
  height: 100%;
}

.modalJobNotes {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  height: auto;
  width: 332px;
  padding: 20px;
  margin-top: 8px;
}

.modalNotesTextArea {
  box-sizing: border-box;
  width: 100%;
  height: 176px;
  resize: none;
  border: none;
}

.modalJobNotes p {
  font-size: 10px;
  margin-top: 15px;
}

modalNotesTextArea::placeholder {
  font-size: 14px;
  color: rgba(128, 128, 128, 1);
}

.jobDetailsButton,
.cancelBookingButton,
.createBookingButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 148, 255, 1);
  height: 44px;
  width: 332px;
  border-radius: 10px;
  padding: 13px 20px 13px 20px;
  box-sizing: border-box;
  gap: 10px;
  margin-top: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.createBookingButton {
  background-color: rgba(25, 204, 118, 1);
}

.createBookingButton p {
  color: white;
  font-weight: bold;
}

.cancelBookingButton {
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 227, 227, 1);
}
.jobDetailsButton p {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 16px;
  line-height: 18.4px;
}

/* .cancelBookingButton p {
  font-size: 12px;
} */

.textAlign {
  text-align: center;
  margin-top: 29px;
  font-size: 12px;
}

.sameCustomerDetails {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 36px;
}

.propertyContactDetails,
.customerDetails {
  display: flex;
  margin-top: 30px;
  margin-left: 39px;
  justify-content: space-between;
  margin-right: 39px;
}

.propertyContactDetails {
  justify-content: flex-start;
  gap: 10px;
}

.customerDetails {
  margin-top: 20px;
}

.propertyContactCustomer {
  font-size: 16px;
  line-height: 21.93px;
  box-sizing: border-box;
  cursor: pointer;
}

.selectedCustomer {
  border-bottom: 2px solid rgba(0, 148, 255, 1);
  font-weight: bold;
}

.additionalPropertyContact {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 3px;
}

.additionalPropertyContact p {
  font-size: 15px;
  font-family: Arial;
  text-decoration: underline;
}

.propertyContactForm,
.customerDetailsForm {
  display: flex;
  flex-direction: column;
  height: 343px;
  width: 717px;
  padding: 36px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  margin-top: 21px;
}

.propertyContactForm {
  height: auto;
}

.editPropertyContactForm {
  margin: 0px;
}

.customerDetailsForm {
  height: auto;
}

.contactFormNames {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.contactFormNames input {
  height: 52px;
  background-color: rgba(243, 243, 243, 1);
  margin-top: 9px;
  width: 302px;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
}

.contactFormDetails {
  height: 77px;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.contactFormDetails input,
.contactFormNotes input {
  height: 49px;
  background-color: rgba(243, 243, 243, 1);
  padding: 15px 20px;
  margin-top: 9px;
  width: 302px;
  font-size: 16px;
}

.contactFormNotes input {
  width: 644px;
}

.contactFormRelationship {
  margin-top: 29px;
  width: 312px;
  height: 76px;
}

.closeBtn {
  font-size: 24px;
  cursor: pointer;
}

/* Job Details Modal */

.modalJobDetails {
  height: auto;
  width: 717px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  padding: 36px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.industryButtonContainer,
.categoryButtonContainer {
  display: flex;
  gap: 14px;
  margin-top: 12px;
}

.categoryButtonContainer {
  gap: 10px;
}

.industryButton,
.categoryButton {
  height: 45px;
  border: 1px solid rgba(216, 216, 216, 1);
  padding: 13px 27.5px 13px 27.5px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 36px;
  cursor: pointer;
}

.selected {
  border: 2px solid rgba(0, 148, 255, 1) !important;
}

.categoryButton {
  height: 33px;
  padding: 7px 15.5px 7px 15.5px;
}

.jobPrioServiceContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 36px;
}

.jobPrioServiceDropdown,
.jobCallOutDropdown,
.bookingDropdown {
  width: 313px;
  height: 49px;
  padding: 15px 20px 15px 20px;
  margin-top: 9px;
}

.jobCallOutDropdown {
  width: 645px;
  margin-bottom: 36px;
}

.jobTypeSelection {
  width: 642px;
  padding: 15px 20px 15px 20px;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.jobTypeSelection p {
  color: rgba(199, 199, 199, 1);
}

.jobTypeButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 36px;
}

.jobTypeButton {
  padding: 7px 15px 7px 15px;
  border-radius: 6px;
  border: 1px solid rgba(216, 216, 216, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 33px;
}

.dateTimeContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  margin-bottom: 36px;
}

.dateContainer,
.timeContainer {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.dateSelection,
.timeSelection,
.calendarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 15px 20px 15px 20px;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 10px;
  height: 49px;
  width: 251px;
  box-sizing: border-box;
}

.calendarWrapper {
  display: flex;
  flex-direction: column;
}

.calendarContainer {
  background-color: rgb(243, 243, 243);
  font-size: 16px;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 10px;
}

.calendarPopup {
  position: absolute;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.timeSelection {
  width: 251px;
  box-sizing: border-box;
  gap: 6px;
}

.jobDescriptionInput,
.customerJobLocationInput {
  background-color: rgba(246, 246, 246, 1);
  padding: 15px 20px 15px 20px;
  width: 642px;
  height: 183px;
  border: none;
  margin-top: 10px;
  box-sizing: border-box;
  resize: none;
  margin-bottom: 36px;
  font-size: 16px;
}

.customerJobLocationInput {
  margin-bottom: 0px;
}

.customerJobLocation {
  height: auto;
}

.customerJobLocation .customerJobLocationInput {
  height: 49px;
  background-color: rgba(246, 246, 246, 1);
  overflow: hidden;
}

.jobDescriptionInput::placeholder,
.customerJobLocationInput::placeholder {
  font-size: 16px;
}

.jobLocationDropdown {
  height: auto;
  padding: 8px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin-top: 1.77px;
}

.jobLocationDropdownItem {
  height: 36px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 11px 14px;
}

.jobLocationDropdownItem:hover {
  background-color: rgba(250, 250, 250, 1);
}

.customerSelection {
  margin-top: 30px;
}

.customerCard {
  width: 635px;
  height: auto;
  border-radius: 10px;
  background-color: rgba(237, 247, 255, 1);
  padding: 24px;
  margin-top: 5px;
}

.customerCardNameContainer {
  display: flex;
  align-items: center;
  gap: 13px;
  justify-content: space-between;
}

.customerCardRole {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 4px 11px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
  height: 22px;
}

.customerCardRole p {
  font-size: 14px;
}

.customerCardFooter {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customerMobilePhone {
  display: flex;
  gap: 25px;
  align-items: center;
}

.customerCardButton,
.customerCardButtonSelect {
  width: auto;
  padding: 13px 20px;
  border-radius: 10px;
  border: 1px solid rgba(214, 230, 243, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  background-color: white;
  cursor: pointer;
}

.customerCardButtonSelect {
  background-color: rgba(0, 148, 255, 1);
  border: none;
}

.customerCardButtonSelect p {
  color: white;
  font-weight: bold;
}

.customerCardButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customerCardAddendum {
  margin-top: 64px;
}

.customerCardAddendum p {
  line-height: 14.06px;
  font-size: 12px;
  color: rgba(129, 129, 129, 1);
}

.specialBookingDropdown {
  height: 49px;
}

.cancelBookingContainer {
  display: flex;
  margin-top: 16px;
  gap: 10px;
  align-items: center;
}

.cancelArrow,
.cancelBookingHalf,
.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15.5px 20px 15.5px 20px;
  width: 43px;
  height: 43px;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.cancelBookingHalf {
  width: 279px;
  height: 43px;
  padding: 13px 60px 13px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Job Summary Modal */
.summaryCustomerDetails,
.summaryPropertyAddress,
.summaryPropertyContactDetails,
.summaryJobDetails {
  width: 717px;
  height: 650px;
  background-color: rgba(255, 255, 255, 1);
  padding: 36px;
  margin-top: 16px;
  border-radius: 10px;
  box-sizing: border-box;
}

.summaryCustomerDetails {
  height: auto;
}

.summaryPropertyAddress {
  height: 310px;
}

.summaryPropertyContactDetails {
  height: auto;
}


.summaryCustomersContainer {
  display: flex;
  gap: 65px;
  margin-top: 36px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  margin-left: -36px;
  margin-right: -36px;
  padding: 0px 36px 0px 36px;
}

.summaryButtonDetailsContainer,
.summaryButtonDetailsPropertyContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.summaryButtonDetailsPropertyContainer {
  justify-content: flex-end;
}

.summaryAccountSpan {
  width: 141px;
  height: 27px;
  padding: 4px 11px 4px 11px;
  background-color: rgba(0, 148, 255, 1);
  border-radius: 25px;
}

.summaryAccountSpan p {
  font-size: 16px;
  color: white;
}

.summaryDetails,
.summaryMiscellaneous,
.jobDetailsSection {
  background-color: rgba(251, 251, 251, 1);
  width: 642px;
  height: 207px;
  padding: 24px;
  border-radius: 16px;
  box-sizing: border-box;
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.summaryDetails {
  height: auto;
}

.summaryIndivDetails {
  display: flex;
}

.summaryIndivDetailsKey {
  width: 150px;
  font-weight: bold;
}

.summaryPropertyContactIndivDetailsKey {
  width: 200px;
  font-weight: bold;
}

.summaryIndivDetailsValue {
  display: flex;
  gap: 6px;
}

.summaryMiscellaneous {
  background-color: white;
  height: auto;
  margin-top: 24px;
}

.summaryPropertyAddress {
  height: auto;
}

.summaryPropertyHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summaryPropertyFlexContainer {
  display: flex;
  margin-top: 16px;
  gap: 4px;
}

.summaryMap {
  margin-top: 16px;
  width: 100%;
}

.jobDetailsSection {
  background-color: rgba(244, 247, 249, 1);
  display: flex;
  flex-direction: row;
}

.jobDetailsLeft,
.jobDetailsRight {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jobDetailsLeft {
  margin-right: 146px;
}

.jobDetailsGap {
  margin-top: 4px;
}

.jobDetailsFlexItem {
  display: flex;
  margin-top: 4px;
  gap: 6px;
}

.jobDescriptionSection {
  margin-top: 36px;
}

.jobDescriptionText {
  height: 216px;
  width: 642px;
  background-color: rgba(246, 246, 246, 1);
  margin-top: 16px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 24px;
}

.confirmationSection {
  padding: 36px;
  width: 332px;
  height: 216px;
  background-color: rgba(237, 246, 252, 1);
  border-radius: 10px;
  margin-top: 16px;
}

.margin12 {
  margin-top: 12px;
  font-size: 14px;
}

.confirmButtonContainer {
  display: flex;
  gap: 10px;
  margin-top: 36px;
}

.confirmButtonCancel,
.confirmButtonConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 90px;
  border-radius: 10px;
  padding: 12.5px 20px 12.5px 20px;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.confirmButtonConfirm {
  background-color: rgba(0, 148, 255, 1);
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 13px 57px 13px 57px;
  width: 155px;
}

.confirmButtonConfirm p {
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.customerDetailsNames {
  display: flex;
  gap: 15px;
}

.customerInputNames,
.customerFullInput,
.customerIndivContacts {
  height: 48px;
  width: 312px;
  padding: 15px 20px;
  background-color: rgba(246, 246, 246, 1);
  margin-top: 9px;
}

.customerFullInput {
  width: 639px;
}

.customerIndivContacts {
  width: 203px;
}

.customerInputNames::placeholder,
.customerFullInput::placeholder,
.customerIndivContacts::placeholder {
  font-size: 16px;
}

.customerDetailsBusiness,
.customerDetailsBilling {
  margin-top: 25px;
}

.textAndInputContainer {
  display: flex;
  gap: 13px;
}

.textAndInputContainerJustify {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.textAndInputContainer:nth-child(2) {
  margin-top: 9px;
}

.customerContactDetails {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}

.customerTypeTerms {
  display: flex;
  gap: 15px;
}

.customerType {
  margin-top: 27px;
}

.customerTypeDropdown {
  box-sizing: border-box;
  display: flex;
  padding: 15px 20px;
  height: 48px;
  width: 312px;
  justify-content: space-between;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 10px;
  margin-top: 9px;
}

.customerTypeDropdown p {
  color: rgba(150, 150, 150, 1);
}

.marginAdjustment {
  margin-top: 30px;
}

.marginAdjustmentMore {
  margin-top: 44px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.nextButton,
.cancelButton,
.backButton,
.disabledNextButton,
.bookingButton {
  width: 71px;
  height: 44px;
  padding: 13px 20px;
  border-radius: 10px;
  background-color: rgba(0, 148, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  cursor: pointer;
}

.disabledNextButton {
  opacity: 0.5;
  pointer-events: none;
}

.nextButton p,
.disabledNextButton p {
  color: white;
  font-weight: bold;
}

.cancelButton,
.backButton {
  width: 84px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 227, 227, 1);
}

.backButton {
  width: 72px;
}

.subButtonContainer {
  display: flex;
  gap: 12px;
}

.createBookingButton {
  width: 144px;
  height: 42px;
  white-space: nowrap;
}

.modalCustomerSummary {
  width: 332px;
  height: auto;
  padding: 24px;
  border-radius: 10px;
  background-color: white;
  margin-top: 14px;
  margin-bottom: 36px;
}

.accountStatusSummary {
  padding: 4px 11px;
  background-color: rgba(236, 236, 236, 1);
  border-radius: 25px;
  display: inline-block;
  margin-top: 13px;
}

.accountStatusSummary p {
  font-size: 12px;
}

.margin4 {
  margin-top: 4px;
}

.accountSummaryContainer {
  display: flex;
  gap: 23px;
  margin-top: 25px;
}

.accountSummaryEmail {
  margin-top: 25px;
}

.accountSummaryAddress {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.accountSummaryViewProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  gap: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 10px;
  margin-top: 38px;
  width: 140px;
  height: 42px;
  white-space: nowrap;
  cursor: pointer;
}

.marginNotes {
  margin-top: 36px;
  margin-bottom: 8px;
}

.bookingCancellation {
  padding: 36px;
  background-color: rgba(255, 255, 255, 1);
  height: 360px;
  width: 330px;
  margin-top: 36px;
  border-radius: 10px;
}

.bookingReasonCancel {
  margin-top: 36px;
}

.bookingButtonContainer {
  display: flex;
  gap: 12px;
}

.dropdownContainer {
  width: 301px;
}

.hoverColor:hover {
  background-color: rgba(246, 246, 246, 1) !important;
}

.bookingDropdownContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.bookingDropdownOptions {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  margin-top: 5px;
}

.bookingDropdownItems {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.primaryCustomerContainer {
  display: flex;
  align-items: center;
  gap: 9px;
}

.primaryCustomer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  width: auto;
  height: 22px;
  box-sizing: border-box;
  padding: 4px 11px;
  background-color: rgba(236, 236, 236, 1);
}

.primaryCustomer p {
  font-size: 12px;
}

:global(.react-calendar) {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid rgba(227, 227, 227, 1) !important;
}

:global(.react-calendar__tile--hasActive) {
  background: rgba(0, 148, 255, 1) !important;
}

:global(.react-calendar__tile--hasActive:enabled:hover),
:global(.react-calendar__tile--hasActive:enabled:focus) {
  background: rgba(0, 148, 255, 1) !important;
}

:global(.react-calendar__tile--active) {
  background: rgba(0, 148, 255, 1) !important;
}

:global(.react-calendar__tile--active:enabled:hover),
:global(.react-calendar__tile--active:enabled:focus) {
  background: rgba(0, 148, 255, 1) !important;
}

:global(.pac-container) {
  background-color: #fff;
  border-radius: 10px;
}

:global(.pac-item) {
  padding: 8px;
  font-size: 16px;
  height: 52px;
  box-sizing: border-box;
  margin: 0;
}

.pac-item:hover {
  background-color: #f0f0f0;
}

:global(.pac-item-query) {
  font-size: 16px;
}

.customerCardOwner {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customerCardLeft {
  display: flex;
  gap: 14px;
}

.customerCardRight {
  display: flex;
  gap: 14px;
}

.customerDetailsButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}