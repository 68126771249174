.iconMessage {
  height: 16px;
  width: 16px;
  background: url('../../../public/images/scheduler/message.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  pointer-events: auto;
  background: transparent;
}

.modalContainer {
  position: absolute;
  background: white;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0px 4px 18.7px 0px #0000000d;
  pointer-events: auto;
  min-width: 330px;
}

.profileHeader {
  background: #0e1e29;
  padding: 16px 38px 20px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.profileImageContainer {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #0000000f;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.nameSection {
  display: flex;
  justify-content: space-between;
}

.name {
  color: #ffffff;
  margin: 0;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.role {
  color: #ffffff;
  margin: 0;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.viewProfile {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
}

.content {
  padding: 36px;
}

.balanceSection,
.mobileSection,
.emailSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.balanceSection {
  margin-bottom: 38px;
}

.mobileSection {
  margin-bottom: 24px;
}

.emailSection {
  margin-bottom: 42px;
}

.sectionTitle {
  color: #000000;
  font-size: 14px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.sectionValue {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.viewButton {
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  color: #1a2632;
  cursor: pointer;
  font-size: 14px;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0;
}

.smsButton,
.assignButton {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: #f8fafc;
  color: #1a2632;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.status {
  text-align: center;
  color: #0e1e29;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}
