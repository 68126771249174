.messageBubble {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.sender {
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  color: #000000;
}

.senderName {
  align-self: flex-end;
}

.receiverName {
  align-self: flex-start;
}

.messageWrapper {
  max-width: 80%;
  border-radius: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.sent {
  align-self: flex-end;
  background: #f6fbff;
}

.received {
  align-self: flex-start;
  background: #fafafa;
}

.message {
  margin: 0;
  padding: 8px 10px;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
}
