.iconCheckmark {
  height: 24px;
  width: 24px;
  background: url('../assets/xeroCheckmark.svg') no-repeat;
  display: flex;
}

.xeroModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.xeroModal {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 543px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.progressContainer {
  display: flex;
  flex-direction: column;
}

.progressItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e6e6e6;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
}

.progressItem h3 {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.93px;
}

.accountInfo {
  display: flex;
  flex-direction: column;
}

.accountDetail {
  color: #0094ff;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 30px 24px 24px;
}
