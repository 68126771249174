.infoPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  min-height: 100px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.detailsGroup {
  display: flex;
  align-items: center;
  gap: 120px;
}

.techInfo {
  display: flex;
  align-items: center;
  gap: 22px;
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 13.4px 0px #74747440;
}

.nameGroup {
  display: flex;
  flex-direction: column;
}

.firstName {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.lastName {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.details {
  display: flex;
  gap: 120px;
}

.detailGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.techInfo,
.detailGroup {
  min-width: 120px;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
  margin: 0;
}

.value {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.actions {
  display: flex;
  gap: 16px;
  margin-left: auto;
}
