.materialsContent {
  padding: 36px;
}

.ordersTableContainer {
  padding-top: 16px;
}

.materialsUsedTableContainer {
  margin-top: 50px;
}

.orderTable {
  width: 100%;
  margin-bottom: 32px;
}

.materialsTable {
  width: 100%;
}

.statusColumn {
  display: flex;
  gap: 8px;
}

.statusChip {
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
  background: #e8f5e9;
  color: #2e7d32;
}

.spacer {
  width: 200px;
}

.productColumn {
  text-align: left;
}

.quantityColumn,
.costColumn {
  text-align: right;
}
