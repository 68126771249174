a {
    text-decoration: none;
}

.propertyComponent {
	display: flex;
	flex-direction: column;
	margin: 35px;
	padding-bottom: 35px;
}

.propertyHeaderTitle {
	font-size: 24px;
	font-weight: 700;
	line-height: 29.05px;
	text-align: left;
	margin-bottom: 16px;
	margin-top: 38px;
}

.propertyHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	border-radius: 11px;
	background: #ffffff;
	box-shadow: 0px 9px 10.8px 0px #f3f3f338;
	padding: 20px 24px;
	margin-bottom: 6px;
}

.propertyHeaderLeft {
	display: flex;
	align-items: center;
	gap: 20px;
}

.propertyHeaderLeft h3 {
	font-size: 18px;
	font-weight: 700;
	line-height: 21.09px;
	text-align: left;
	color: #000000;
}

.propertyHeaderLeft > div {
	display: flex;
	gap: 10px;
	align-items: center;
}

.propertyHeaderDropdown {
	background: #f2f2f2;
	border-radius: 10px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
}

.propertyHeaderRight {
	display: flex;
	gap: 11px;
}

.propertyHeaderEdit {
	height: 40px;
	width: 48px;
	top: 773px;
	gap: 6.09px;
	border-radius: 10px;
	border: 1px solid #d8d8d8;
	box-shadow: 0px 4px 4px 0px #0000000a;
	display: flex;
	justify-content: center;
	align-items: center;
}

.propertyHeaderEdit img {
	height: 16px;
	width: 16px;
}

.propertyHeaderAddProperty {
	display: flex;
	align-items: center;
	box-shadow: 0px 4px 4px 0px #0000000a;
	border: 1px solid #d8d8d8;
	background: #ffffff;
	height: 40px;
	gap: 6.09px;
	padding: 4.87px 16px;
	border-radius: 10px;
}

.addPropertyIcon {
	width: 16px;
	height: 16px;
}

.addPropertyText {
	font-size: 16px;
	font-weight: 400;
	line-height: 18.75px;
	text-align: left;
	color: #000000;
	text-decoration: none;
}

.propertySiteDetails {
	background: #ffffff;
	box-shadow: 0px 0px 64.1px 0px #00000008;
	box-shadow: 0px 12px 48px 0px #f2f2f2;
	border-radius: 10px;
}

.propertySiteDetailsHeader {
	padding: 24px 26px;
}

.propertySiteDetailsHeader h3 {
	font-size: 16px;
	font-weight: 700;
	line-height: 21.93px;
	text-align: left;
	margin-bottom: 18px;
}

.propertySiteDetailsHeader h4,
p {
	font-size: 16px;
}

.propertySiteAddress {
	display: flex;
	gap: 10px;
	margin-bottom: 14px;
}

.customerInfo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 40px;
	padding: 0;
	margin-bottom: 20px;
}

.contactPhoneIcon {
	display: flex;
	align-items: center;
	gap: 6px;
}

.jobsTableHeader .navItem {
	font-size: 16px;
}

.jobsTable {
	display: flex;
	flex-direction: column;
	margin: 33px;
}

.jobsTableHeader {
	display: flex;
}

.jobsTableHeader a {
	cursor: pointer;
	padding: 10px 24px;
	display: flex;
	gap: 6.09px;
	align-items: center;
}

.jobsNotification {
	font-size: 12px;
	font-weight: 700;
	line-height: 14.06px;
	text-align: center;
	color: #15191b;
	background: #f1f1f1;
	border-radius: 14px;
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.jobsTableSpanSelected {
	border: 1px solid #0094ff;
	box-shadow: 0px 2.43px 2.43px 0px #00000008;
	border-radius: 10px;
	height: 40px;
}

.jobsTableList {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	margin-top: 10px;
	table-layout: fixed;
}

.jobsTableList thead {
	height: 41px;
	background: #f4f7f9;
	border-radius: 29px 29px 0 0;
}

.jobsTableList th {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 18.75px;
	text-align: left;
	color: #0e1e29;
	padding: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.jobsTableList thead th:first-child {
	border-radius: 29px 0 0 29px;
}

.jobsTableList thead th:last-child {
	border-radius: 0 29px 29px 0;
}

.jobsTableList tbody {
	background: white;
}

.jobsTableList tbody td {
	font-size: 16px;
	font-weight: 400;
	line-height: 18.75px;
	text-align: left;
	padding: 10px;
	color: #0e1e29;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 10px;
}

.jobsTableList td.description {
	max-width: 200px;
	white-space: normal;
	word-wrap: break-word;
}

.readMore {
	cursor: pointer;
	color: #aaaaaa;
	font-size: 16px;
	font-weight: 400;
	line-height: 18.2px;
	text-align: left;
}

.jobsTableList td.status {
	font-weight: 700;
}

.jobsTableList td.status.completed {
	color: #19cc76;
}

.jobsTableList td.status.ongoing {
	color: #ff7a00;
}

.jobsTableList td.status.canceled {
	color: #a7a7a7;
}

.jobsTableList td.balance.positive {
	color: #e22e2e;
}

.jobsTableList th:nth-child(1),
.jobsTableList td:nth-child(1) {
	width: 9%;
	padding-left: 28px;
}

.jobsTableList th:nth-child(2),
.jobsTableList td:nth-child(2) {
	width: 10%;
}

.jobsTableList th:nth-child(3),
.jobsTableList td:nth-child(3) {
	width: 25%;
}

.jobsTableList th:nth-child(4),
.jobsTableList td:nth-child(4) {
	width: 12%;
}

.jobsTableList th:nth-child(5),
.jobsTableList td:nth-child(5) {
	width: 12%;
}

.jobsTableList th:nth-child(6),
.jobsTableList td:nth-child(6) {
	width: 7%;
}

.jobsTableList th:nth-child(7),
.jobsTableList td:nth-child(7) {
	width: 7%;
}

.jobsTableList th:nth-child(8),
.jobsTableList td:nth-child(8) {
	width: 7%;
}

.jobsTableList th:nth-child(9),
.jobsTableList td:nth-child(9) {
	width: 10%;
}

.summaryContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 10px;
	margin-right: 50px;
}

.summaryItem {
	display: flex;
	justify-content: space-between;
	width: 150px;
	margin-bottom: 10px;
}

.summaryLabel {
	font-size: 16px;
	font-weight: 700;
	line-height: 21.93px;
	color: #363636;
}

.summaryValue {
	font-size: 16px;
	line-height: 21.93px;
	color: #363636;
}


.jobButton {
	width: 124.09px;
	height: 40px;
	border-radius: 10px;
	border: 0.61px solid rgba(216, 216, 216, 1);
	padding: 10px 24px 10px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2.43px 2.43px 0px rgba(0, 0, 0, 0.03);
	gap: 6px;
	box-sizing: border-box;
}

.jobButton:hover {
	cursor: pointer;
}