* {
  margin: 0;
  padding: 0;
}

.cardInformation {
  display: flex;
  align-items: center;
  height: 96px;
  margin: 35px;
  gap: 10px;
}

.indivCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 96px;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  gap: 10px;
}

.indivCardsContent {
  display: flex;
  flex-direction: column;
}

.bigFont {
  font-size: 16px;
}

.biggestFont {
  font-size: 24px;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}
