/* DataTable.module.css */
.iconChevronRight {
  height: 16px;
  width: 16px;
  background: url('../assets/chevronRight.svg') no-repeat;
  display: flex;
}

.dataTable {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.primaryTable,
.secondaryTable,
.tertiaryTable {
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  margin-top: 10px;
}

.primaryTable thead,
.tertiaryTable thead {
  background: #f4f7f9;
}

.primaryTable th,
.tertiaryTable th {
  padding: 10px 24px;
  text-align: left;
  border: none;
}

.space {
  width: 0%;
}

.codeColumn {
  width: 10%;
}

.titleColumn {
  width: 25%;
}

.descriptionColumn {
  width: 27%;
  padding-right: 24px;
}

.primaryTitleContainer {
  gap: 4px;
}

.primaryTitle {
  font-size: 16px;
  font-family: robotoBold;
}

.pathIndicator {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #666;
  margin-top: 4px;
}

.primaryTable th:first-child,
.tertiaryTable th:first-child {
  background-color: #ffffff;
}

.primaryTable th:last-child,
.tertiaryTable th:last-child {
  background-color: #ffffff;
}

.primaryTable th:nth-child(2),
.tertiaryTable th:nth-child(2) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.primaryTable th:nth-last-child(2),
.tertiaryTable th:nth-last-child(2) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.primaryTable td,
.tertiaryTable td {
  padding: 10px 24px;
  border-bottom: 1px solid #f3f3f3;
}

.quantityColumn {
  width: 5%;
  text-align: center;
}

.quantity {
  text-align: center;
}

.amount {
  width: 8%;
  text-align: right !important;
}

.tertiaryImageColumn {
  width: 15%;
}

.dateColumn {
  width: 15%;
}

.userColumn {
  width: 10%;
}

.tertiaryDescriptionColumn {
  width: 15%;
}

.idColumn {
  width: 15%;
}

.typeColumn {
  width: 35%;
}

.tertiaryImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.secondaryTable {
  border-collapse: collapse;
  margin-bottom: 85px;
  display: flex;
  justify-content: flex-end;
}

.secondaryRow {
  border-bottom: 1px solid #f3f3f3;
}

.secondaryRow td {
  padding: 12px 24px 12px 24px;
}

.secondaryLabel {
  font-family: robotoBold;
  text-align: left;
}

.nonBoldText {
  font-weight: normal;
}
