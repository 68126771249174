.wrapper {
  padding: 18px 24px;
  background-color: #f8f8f8;
}

.breadcrumbs {
  padding: 0 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px 20px;
}

.header h1 {
  font-family: robotoBold;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.78px;
  color: #1a1a1a;
}

.headerButtons {
  display: flex;
  gap: 12px;
}

.accountingControls {
  background: #ffffff;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 55px;
}

.invoicesHeader {
  padding: 0 24px;
  color: var(--Text, #0e1e29);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.invoicesTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
}

.invoiceCount {
  font-family: robotoRegular;
  font-size: 22px;
  line-height: 25.78px;
}
