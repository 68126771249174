.statsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 24px;
}

.statsCard {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.statsCard h3 {
  font-family: robotoBold;
  font-size: 16px;
  margin-bottom: 16px;
}

.statsChartContainer {
  min-height: 200px;
  background: #ffffff;
}
