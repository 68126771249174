.chartContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 22.4px;
  margin: 0 !important;
}

.trendContainer {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.trendArrow {
  color: #22c55e;
  margin-right: 4px;
}

.trendText {
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 19.6px;
}

.trendValue {
  font-family: robotoBold;
}

.percentageValue {
  font-family: robotoRegular;
  font-size: 18.2px;
  line-height: 25.48px;
}

.decimal {
  font-family: robotoRegular;
  font-size: 13px;
  line-height: 18.2px;
}

.chartWrapper {
  height: 192px; /* 48rem */
}

.percentageSection,
.averageSaleContainer,
.membershipContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 12px;
}

.averageSaleContainer > span,
.membershipContainer > span {
  font-family: robotoRegular;
  font-size: 19.5px;
  line-height: 27.3px;
}
