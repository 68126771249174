.container {
  background-color: #ffffff;
  border: 1px solid #d4dfe7;
  border-radius: 8px;
  margin-bottom: 8px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 24px;
}

.purchaseNumberStyle {
  font-family: robotoRegular;
  font-size: 16px;
  margin-right: 24px;
}

.expandedContent {
  padding: 0 24px 24px;
}

.detailsTable,
.materialTable {
  width: 100%;
  margin-bottom: 24px;
}

.detailsRowHeader,
.detailsRow,
.materialRowHeader,
.materialRow {
  display: flex;
  align-items: center;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.detailsRowHeader,
.materialRowHeader {
  padding: 12px 8px;
  background-color: #f4f7f9;
  border-radius: 8px;
}

.detailsRowHeader > .detailsCell,
.materialRowHeader > .materialCell {
  font-family: robotoBold !important;
}
.detailsRowHeader {
  background-color: #ffffff;
}

.detailsRow,
.materialRow {
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 8px;
}

.detailsRow {
  border-bottom: none;
}

.detailsCell,
.materialCell {
  width: 20%;
}

.materialCell:nth-child(1) {
  width: 60%;
  padding-left: 24px;
}
.materialCell:nth-child(2) {
  width: 25%;
}
.materialCell:nth-child(3) {
  width: 10%;
}

.instructions,
.notes {
  font-family: robotoRegular;
  margin-top: 16px;
  padding: 24px;
  background-color: #fafafa;
  border-radius: 10px;
}

.iconNavArrow {
  height: 10px;
  width: 10px;
  background: url('../../../assets/navArrow.svg') no-repeat;
  display: flex;
  margin-left: auto;
  transition: transform 0.3s ease;
}

.iconNavArrow.expanded {
  transform: rotate(180deg);
}

.statusBadge {
  font-family: robotoBold;
  font-size: 12px !important;
  color: #44c989;
  background-color: #e3fff2;
  padding: 4px 10px;
  border-radius: 25px;
  text-align: center;
  width: 120px;
}
