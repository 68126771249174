/* icons */
.iconClock {
  height: 16px;
  width: 16px;
  background: url('../../../assets/clock.svg') no-repeat;
  display: inline-block;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 8px;
}

.iconTechnician {
  height: 34px;
  width: 34px;
  display: inline-block;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 8px;
}

.technicianInfo {
  display: flex;
  align-items: center;
}

.dateTime {
  display: flex;
  align-items: center;
}

.timeSlotLabel {
  font-family: robotoBold !important;
}

.container {
  display: flex;
}

.dashDesign {
  width: 3px;
  background-color: #ff7a00;
  margin-right: 6px;
  border-radius: 10px;
  flex-shrink: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
}

.milestoneBody {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.schedulingInfo,
.urgencyMove {
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.urgencyMove {
  margin-bottom: 16px;
}

.jobScheduled {
  font-family: robotoBold !important;
}

.technicianSchedule {
  text-decoration: underline;
}

.technicianScheduling {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 4px;
}
