.filtersContainer {
  display: flex;
  gap: 60px;
  border-bottom: 1px solid #eeeeee;
  padding: 24px 0 20px 45px;
}

.filterGroup {
  display: flex;
  gap: 16px;
  align-items: center;
}

.filterGroup label {
  font-family: robotoBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  color: var(--Text, #0e1e29);
}
