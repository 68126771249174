.jobPerformance {
  padding: 36px 36px 20px;
  background: white;
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;
}

.container {
  display: flex;
  gap: 48px;
}

.leftSection {
  flex: 1;
}

.rightSection {
  flex: 2;
  display: flex;
  align-items: center;
}

.title {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 24.67px;
  text-align: left;
  margin-bottom: 28px;
}

.metricsGrid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
}

.metricItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  align-items: center;
}

.metricLabel {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.metricValue {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.circularMetrics {
  display: flex;
  justify-content: space-between;
  gap: 36px;
}

.circularMetricContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressContainer {
  position: relative;
  width: 140px;
  height: 140px;
}

.progressRing {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Material Cost Ring */
.progressRingMaterial {
  background: conic-gradient(#22c55e var(--progress), #e8e8e8 var(--progress));
}

/* Labour Efficiency Ring */
.progressRingLabour {
  background: conic-gradient(#22c55e var(--progress), #e8e8e8 var(--progress));
}

/* Profit Score Ring */
.progressRingProfit {
  background: conic-gradient(#ef4444 var(--progress), #e8e8e8 var(--progress));
}

.progressRing::before {
  content: '';
  position: absolute;
  inset: 16px;
  border-radius: 50%;
  background: white;
}

.progressInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.progressValue {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
}

.progressPercentage {
  font-family: robotoBold;
  font-size: 15.13px;
  line-height: 17.73px;
  text-align: left;
}

.progressLabel {
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  text-align: center;
}
