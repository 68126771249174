.iconPlus {
  height: 34px;
  width: 34px;
  background: url('../assets/plus.svg') no-repeat;
  display: flex;
}

.cartModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.cartContainer {
  position: absolute;
  top: 0;
  right: -50%;
  width: 50%;
  height: 100%;
  background-color: white;
  transition: right 0.15s ease-out;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.cartContainer.open {
  right: 0;
  box-shadow: -1px 0px 10.6px 0px #00000024;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  border-bottom: 1px solid #eee;
}

.cartHeader h2 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  text-align: left;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.cartContent {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cartItems {
  padding: 1rem;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #f6f6f6;
}

.itemImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.itemImage {
  width: auto;
  height: 40px;
  margin-right: 1rem;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
}

.itemDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.itemPrice {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.quantityControl {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 20%;
}

.incrementDecrement {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.emptyCartButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 0;
}

.recentlyRemovedItem {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.recentlyRemovedItem h3 {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 12px 36px;
}

.recentlyRemoved {
  border-top: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
}
