.iconCheck {
  height: 16px;
  width: 16px;
  background: url('../../../assets/tickCircle.svg') no-repeat;
  display: flex;
}
.iconClose {
  height: 16px;
  width: 16px;
  background: url('../../../assets/closeCircle.svg') no-repeat;
  display: flex;
}

.header {
  padding: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 24.67px;
  text-align: left;
  margin: 0;
}

.button {
  background-color: #e8f5e9;
  color: #4caf50;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-family: robotoRegular;
  font-size: 14px;
  cursor: pointer;
}
