.iconPlus {
  height: 34px;
  width: 34px;
  background: url('../assets/plus.svg') no-repeat;
  display: flex;
}

.iconCalendar {
  height: 16px;
  width: 16px;
  background: url('../assets/calendar.svg') no-repeat;
  display: flex;
}

.iconFolder {
  height: 16px;
  width: 16px;
  background: url('../assets/folder.svg') no-repeat;
  display: flex;
}

.iconArrowDown {
  height: 9px;
  width: 12px;
  background: url('../../JobCard/assets/arrowDown.svg') no-repeat;
  display: flex;
}

.iconGreenCheck {
  height: 16px;
  width: 16px;
  background: url('../assets/greenCheck.svg') no-repeat;
  display: flex;
}

.paymentModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.paymentModal {
  max-width: 1295px;
  width: 782px;
  max-height: 80vh;
  height: auto;
  background: rgba(247, 249, 251, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.paymentModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  background: white;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.paymentModalTitle {
  font-family: 'robotoBold' !important;
  font-size: 22px;
  text-align: left;
  padding: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.paymentModalBody {
  padding: 36px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.paymentModalBodyTitle {
  font-family: 'robotoBold' !important;
  font-size: 18px;
  color: rgba(24, 24, 24, 1);
}

.paymentModalBodyContent {
  display: flex;
  height: 424px;
  width: 710px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 16px;
  gap: 35px;
}

.paymentBodyItem {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 24px;
  justify-content: center;
}

.paymentBodyItem:last-child {
  margin-bottom: 0;
}

.paymentBodyItemTitle {
  font-family: 'robotoBold' !important;
  font-size: 16px;
  color: rgba(14, 30, 41, 1);
}

.paymentBodyItem input {
  width: 331px;
  height: 40px;
  padding-left: 20px;
}

.paymentBodyRight .paymentBodyItem input {
  width: 286px;
}

.paymentBodyItem input::placeholder {
  font-family: 'robotoRegular' !important;
  font-size: 16px;
  color: rgba(53, 57, 61, 1);
}

.paymentBodyItemSubtitle {
  font-family: 'robotoRegular' !important;
  font-size: 14px;
  color: rgba(14, 30, 41, 1);
  display: block;
}

.paymentUploadContainer {
  height: 107px;
  width: 286px;
  background: rgba(241, 248, 253, 1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.paymentUploadTitle {
  font-family: 'robotoBold' !important;
  font-size: 12px;
  color: rgba(14, 30, 41, 1);
}

.paymentUploadTitle span {
  font-family: 'robotoRegular' !important;
  font-size: 12px;
  color: rgba(14, 30, 41, 1);
}

.paymentUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.paymentModalFooter {
  background: rgba(254, 254, 255, 1);
  padding: 22px 36px 22px 36px;
  border-top: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  justify-content: space-between;
}
