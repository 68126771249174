.calendar {
  width: 100%;
  max-width: 300px;
  box-shadow: 0px 4px 6px 0px #00000000;
  padding: 16px;
  background: #ffffff;
  border-radius: 10px;
}

.title {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  margin-bottom: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.monthDisplay {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
}

.navButton {
  background: none;
  border: none;
  font-size: 24px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  color: #333333;
}

.weekdaysGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
}

.weekdayLabel {
  text-align: center;
  font-family: robotoRegular;
  font-size: 12.69px;
  line-height: 14.88px;
  color: #b1b1b1;
}

.daysContainer {
  display: flex;
  flex-direction: column;
}

.weekRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 8px;
}

.dateCell {
  position: relative;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Range styling */
.dateCell.rangeStart {
  background: #e3f3ff;
  border-radius: 50%;
}

.dateCell.rangeStart.hasHover {
  border-radius: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.dateCell.rangeEnd {
  background: #e3f3ff;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.dateCell.inRange {
  background: #e3f3ff;
}

.dateCell.inRange .dateButton,
.dateCell.rangeStart .dateButton,
.dateCell.rangeEnd .dateButton {
  border-color: transparent;
}

.dateButton {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  border: 1px solid #d0e6f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 12.69px;
  line-height: 14.88px;
}

.dateButton:hover {
  background-color: #f5f5f5;
}

/* .today {
  border-color: #0094ff;
} */

.otherMonth {
  color: #000000;
  border: none !important;
}

.selectedDate {
  background-color: #0094ff !important;
  color: white;
  border-color: #0094ff;
  font-family: robotoBold;
}

.selectedDate:hover {
  background-color: #0094ff !important;
}
