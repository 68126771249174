.officeUsersButton {
	background: none;
	font: inherit;
	font-family: "robotoBold";
	font-size: 16px;
	text-align: inherit;
	padding: 9px 15px !important;
	border-radius: 8px;
	box-shadow: 0px 4.6px 4.6px 0px #0000000a;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 93px;
}

.enabled {
	color: #35393d;
	border: 2px solid #3faeff;
}

.disabled {
	color: #c8c8c8;
	border: 2px solid #f0f0f0;
}
