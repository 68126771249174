.breadcrumbs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0;
}

.breadcrumbItem {
  display: flex;
  align-items: center;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  color: #1a1a1a;
}

.iconBreadCrumbsArrow {
  width: 10px;
  height: 10px;
  background: url('../../Pages/Accounting/assets/breadcrumbsArrow.svg') no-repeat;
  display: flex;
  margin: 0 5px;
}
