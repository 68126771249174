.dropdownMenuItem {
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.dropdownMenuItem:hover {
	background-color: #f0f0f0;
}

.dropdownItem {
	text-decoration: none;
	color: inherit;
	display: block;
	padding: 8px 24px;
}
