/* icons */
.iconCheck {
  height: 16px;
  width: 16px;
  background: url('../assets/check.svg') no-repeat;
  display: flex;
}

.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../assets/arrowDown.svg') no-repeat;
  display: flex;
}

.iconClockBlue {
  height: 24px;
  width: 24px;
  background: url('../assets/clockEight.svg') no-repeat;
  display: flex;
}

.iconCalendarBlue {
  height: 24px;
  width: 24px;
  background: url('../assets/calendarBlue.svg') no-repeat;
  display: flex;
}

.iconRemove {
  height: 8px;
  width: 8px;
  background: url('../assets/remove.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContainer {
  max-width: 768px;
  width: 100%;
  height: 80vh;
  max-height: 900px;
  background: #f7f9fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.modalHeader {
  padding: 30px 36px 30px 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
}

.headerTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.iconClose {
  height: 34px;
  width: 34px;
  background: url('../assets/close.svg') no-repeat;
  display: flex;
}

.modalBody {
  padding: 36px;
  height: calc(100% - 200px);
  overflow-y: auto;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 24px 36px;
  margin-top: auto;
}

.footerLeft {
  /* Left footer content */
}

.footerRight {
  display: flex;
  gap: 16px;
}

/* job site contact tab */

.tabContainer {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 48px 0 0 0;
  border-bottom: 1px solid #e5e5e5;
}

.tab {
  padding: 8px 0;
  border: none;
  background: none;
  font-size: 18px;
  font-family: robotoRegular;
  cursor: pointer;
  position: relative;
}

.activeTabStyle {
  color: #0e1e29;
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  text-align: left;
}

.activeTabStyle::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #0094ff;
}

.contactTabs {
  display: flex;
  justify-content: space-around;
  gap: 18px;
  margin-bottom: 30px;
}

.contactTab {
  background: #f7f9fb;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 18px;
}

.activeContactTab {
  font-family: robotoBold;
}

.primaryLabel {
  padding: 4px 12px;
  background: #ececec;
  border-radius: 25px;
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  text-align: left;
}

.formContainer {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 36px;
}

.inputGroup {
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputGroup label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.inputField {
  padding: 16px 20px;
  height: 48px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
}

.inputField::placeholder {
  color: #969696;
}

.fullWidthInput {
  width: 100%;
}

.fullWidthInput textarea {
  min-height: 100px;
  resize: vertical;
}

.toggleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 42px 36px 0;
}

.toggleContainer label {
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
}

.toggle {
  width: 48px;
  height: 24px;
  appearance: none;
  background: #e5e7eb;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
}

.toggle:checked {
  background: #0066ff;
}

.toggle::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle:checked::before {
  transform: translateX(24px);
}

/* job details tab */

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.detailsFormContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 36px;
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  text-align: left;
  margin-left: 18px;
}
.selectionGroup {
  margin-bottom: 30px;
}

.buttonGroup {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.selectionButton {
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.selected {
  border-color: #0066ff;
  background: #e5f0ff;
  color: #0066ff;
}

.rowContainer {
  display: flex;
  gap: 24px;
  margin-bottom: 30px;
}

.detailsInputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
  flex: 1;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.rowContainer .detailsInputGroup {
  margin-bottom: 0;
}

.dateAndTimeContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dateAndTimeContainer > div {
  display: flex;
  gap: 48px;
}

.dateAndTimeContainer .detailsInputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.dateTimeInputWrapper {
  position: relative;
  width: 100%;
}

.dateTimeInput {
  width: 100%;
  height: 48px;
  padding: 16px 20px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  appearance: none;
  -webkit-appearance: none;
}

.dateTimeInput::-webkit-calendar-picker-indicator,
.dateTimeInput::-webkit-inner-spin-button,
.dateTimeInput::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.dateTimeInput::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.dateTimeInput[type='date']::-webkit-datetime-edit,
.dateTimeInput[type='time']::-webkit-datetime-edit {
  padding: 0;
}

.dateTimeInput[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.inputIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.textarea {
  padding: 16px 20px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  resize: vertical;
  min-height: 180px;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.textarea::placeholder {
  color: #969696;
}

.jobTypesContainer {
  position: relative;
}

.jobTypeInput {
  width: 100%;
  height: 48px;
  padding: 16px 20px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
}

.jobTypeInput::placeholder {
  color: #969696;
}

.jobTypeDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.jobTypeOption {
  padding: 8px 12px;
  cursor: pointer;
}

.jobTypeOption:hover {
  background: #f3f4f6;
}

.selectedJobTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.jobTypeTag {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #e5f0ff;
  border-radius: 4px;
  font-size: 14px;
  color: #0066ff;
}

.jobTypeTag button {
  border: none;
  background: none;
  color: #0066ff;
  cursor: pointer;
  padding: 0 2px;
}

/* select job type */

.jobTypesContainer {
  position: relative;
  width: 100%;
}

.selectedJobTypesArea {
  min-height: 48px;
  padding: 8px 12px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.selectedJobType {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background: white;
  border: 1px solid #0094ff;
  border-radius: 10px;
  color: #0e1e29;
  box-shadow: 0px 4px 4px 0px #00000012;
  cursor: pointer;
  gap: 6px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
}

.jobTypeButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.jobTypeButton {
  padding: 7px 15px;
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  font-size: 14px;
  color: #0e1e29;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  user-select: none;
  -webkit-user-select: none;
}

.removeButton {
  margin-left: 4px;
  padding: 0 2px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}
