.summaryContainer {
  display: flex;
  gap: 65px;
  padding: 40px 45px 0;
}

.summaryCard {
  background-color: #ffffff;
  cursor: pointer;
  padding-bottom: 16px;
}

.summaryCard h3 {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
  color: #0e1e29;
}

.summaryCard p {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 22.4px;
  color: #0e1e29;
}

.active {
  border-bottom: 3px solid #0094ff;
}
