.iconTrash {
  height: 16px;
  width: 16px;
  background: url('../assets/trash.svg') no-repeat;
  display: flex;
}

.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../assets/arrowDown.svg') no-repeat;
  display: flex;
}

.additionalWorker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}

.additionalWorkerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e7e7e7;
}

.additionalWorkerBody {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 0;
}

.dropdownGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dropdownRow {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropdownRow > * {
  flex: 1;
  width: 100%;
}
