.calendarContainer {
  width: 100%;
  height: 100%;
  margin-top: 7px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  min-height: 0;
}

.timeRow,
.calendarRow {
  display: flex;
  width: 100%;
  min-width: 1000px;
}

.timeRow {
  height: 45px;
}

.calendarRow {
  display: flex;
  width: 100%;
  min-width: 1000px;
  min-height: 65px;
  height: 100%;
  flex: 1;
}

.calendarBox {
  flex: 1;
  min-width: 100px;
  min-height: 65px;
  height: 100%;
  border: 0.5px solid rgba(239, 239, 239, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  transition: background-color 0.2s;
  box-sizing: border-box;
}

.margin10 {
  margin-top: 20px;
}

.calendarBox.dragOver {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.calendarFirstBox {
  width: 136px;
  min-width: 136px;
  min-height: 65px;
  height: 100%;
  border: 0.5px solid rgba(239, 239, 239, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  flex-direction: column;
}

.workerContainer {
  width: 136px;
  min-width: 136px;
  min-height: 65px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.calendarFirstBoxContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  max-height: 45px;
  border-radius: 37px;
  padding: 4px;
  cursor: pointer;
}

.calendarFirstBoxIcon {
  width: 36px;
  height: 36px;
}

.calendarFirstBoxContentActive {
  background-color: var(--Text, #0e1e29);
}

.calendarFirstBoxContentActive p {
  color: #ffffff !important;
}

.calendarFirstBox {
  border: 0.5px solid rgba(239, 239, 239, 1);
}

.timeSlotsFirstBox {
  width: 136px;
  min-width: 136px;
  min-height: 65px;
  height: 100%;
  border: 0.5px solid rgba(239, 239, 239, 1);
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}



.calendarBoxBlue {
  background-color: rgba(248, 252, 255, 1);
}

.calendarFirstBox p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.calendarFirstBox .calendarOfficeTitle {
  font-weight: bold;
  margin-right: 55px;
}

.calendarFirstBox .calendarTitle p {
  font-weight: bold;
}

.calendarBox .calendarTitle {
  color: rgba(14, 30, 41, 1);
  font-size: 14px;
  font-weight: bold;
}

.calendarJobTitleRow {
  display: flex;
  height: 25px;
  width: auto;
  border: 1px rgba(239, 239, 239, 1) solid;
  padding: 5px 0px 5px 19px;
}

.calendarJobTitleRow p {
  font-size: 14px;
  font-weight: bold;
  color: rgba(14, 30, 41, 1);
}

.calendarNames {
  display: flex;
  flex-direction: column;
}

.scheduledBox {
  position: absolute;
  height: 55px;
  margin: 5px 0;
  z-index: 1;
  top: 0;
  left: 2.5px;
  right: 2.5px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  cursor: move;
  user-select: none;
}

.scheduledBoxTitle,
.scheduledBoxSubtitle {
  font-size: 12px;
  line-height: 13.8px;
  padding-left: 11px;
}

.scheduledBoxTitle {
  padding-top: 8px;
}

.scheduledBoxSubtitle {
  font-weight: bold;
}

.scheduledBoxLabel p {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  padding-left: 11px;
}

.calendarRowWrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.calendarBoxCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}

.currentTimeIndicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  border-right: 1px dotted rgba(255, 162, 162, 1);
  z-index: 10;
  pointer-events: none;
  transition: left 0.5s ease;
}

.currentTimeIndicatorDot {
  position: absolute;
  top: 40px;
  left: -2px;
  width: 5px;
  height: 5px;
  background-color: rgba(255, 162, 162, 1);
  border-radius: 50%;
  z-index: 1;
}

.calendarNames .calendarStatus {
  font-size: 12px;
  color: rgba(157, 157, 157, 1);
}

.apprenticesContainer {
  width: 80%;
  background-color: rgba(249, 249, 249, 1);
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-bottom: 7px;
}

.apprenticeCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  margin-left: -8px;
}

.apprenticeCircle:first-child {
  margin-left: 0;
}

.apprenticeCircle img {
  width: 100%;
  border-radius: 50%;
}