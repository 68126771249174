.materialsCostSlider {
  width: 100%;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin-bottom: 16px;
  display: block;
}

.sliderContainer {
  position: relative;
  width: 100%;
  margin-top: 20px;
  padding: 15px 0;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #d7edff;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0094ff;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0094ff;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.sliderValue {
  font-family: robotoRegular;
  font-size: 22px;
  font-weight: 400;
  line-height: 25.78px;
  text-align: left;
  margin-top: 8px;
}

.sliderLimits {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.sliderLimit {
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}
