/* icons */
.iconMessage {
	height: 16px;
	width: 16px;
	background: url("./assets/messageText.svg") no-repeat;
	display: flex;
}

.notesTabGroup {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 9px 10.8px 0px #f3f3f380;
	border-radius: 10px;
	margin-top: 16px;
}

.notesTabGroup * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

/* header */

.notesTabHeader {
	display: flex;
	gap: 10px;
	border-bottom: 1px solid #f2f2f2;
	padding: 35px 0 0 35px;
}

/* body */
.notesTabBody {
	display: flex;
	flex-direction: column;
	padding: 40px 0 35px 35px;
	gap: 16px;
	position: relative;
}

.notesTabTop {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.notesTabDate {
	font-size: 14px;
	line-height: 16.41px;
}

.notesTabAuthor {
	font-size: 12px;
	line-height: 14.06px;
	color: #86949e;
}

.notesTabContent {
	line-height: 25.6px;
	padding-right: 60px;
}

.notesTabBottom {
	display: flex;
	align-items: center;
	gap: 10px;
}

.verticalLine {
	position: absolute;
	right: 35px;
	top: 35px;
	bottom: 0px;
	height: 150px;
	width: 4px;
	background-color: #f9f9f9;
}
