/* icons */
.iconCheck {
  height: 16px;
  width: 16px;
  background: url('../assets/check.svg') no-repeat;
  display: flex;
}

.iconEdit {
  height: 16px;
  width: 16px;
  background: url('../assets/edit.svg') no-repeat;
  display: flex;
}

.iconWaiting {
  height: 16px;
  width: 16px;
  background: url('../assets/orangeClock.svg') no-repeat;
  display: flex;
}

.iconNote {
  height: 16px;
  width: 16px;
  background: url('../assets/note.svg') no-repeat;
  display: flex;
}

.iconClose {
  height: 34px;
  width: 34px;
  background: url('../assets/close.svg') no-repeat;
  display: flex;
}

.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../assets/arrowDown.svg') no-repeat;
  display: flex;
}

.iconFile {
  height: 16px;
  width: 16px;
  background: url('../assets/file.svg') no-repeat;
  display: flex;
}
.iconImage {
  height: 16px;
  width: 16px;
  background: url('../assets/image.svg') no-repeat;
  display: flex;
}

/* Modal container */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContainer {
  max-width: 1200px; /* Adjust based on your design */
  width: 100%;
  height: 80vh;
  max-height: 900px;
  background: #f7f9fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

/* Header styles */
.modalHeader {
  padding: 30px 36px 30px 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
}

.headerTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
}

.headerTabs {
  display: flex;
  gap: 8px;
}

.tabItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
  min-width: 165px;
}

.tabContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tabTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tabTextContainer > span:first-child {
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
}

.completedLabel {
  font-family: robotoBold;
  font-size: 12px !important;
  line-height: 14.06px;
  text-align: left;
  color: #44c989;
}

.selectedTab {
  background: #f2f2f2;
}

.tabNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #000000;
  padding: 16px;
  font-size: 14px;
  font-family: robotoBold;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

/* Sub-header styles */
.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 36px 24px 56px;
}

.identitySection {
  display: flex;
  gap: 50px;
}

.identityItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.identityLabel {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.identityValue {
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.complaintValue {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: robotoBold;
  gap: 8px;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 4px 0px #0000000a;
  min-width: 250px;
}

.statusSection {
  display: flex;
  align-items: center;
  gap: 24px;
}

/* Main content styles */
.modalBody {
  padding: 0 36px 36px 36px;
  height: calc(100% - 200px);
  overflow-y: auto;
}

.contentContainer {
  display: flex;
  gap: 24px;
  height: auto;
  align-items: flex-start;
}

/* left content */

.leftContent {
  width: 65%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  height: auto;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
}

.descriptionTitle {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.descriptionTextArea {
  width: 100%;
  min-height: 170px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  padding: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  resize: vertical;
}

.descriptionTextArea::placeholder {
  color: #999999;
  font-family: Roboto;
  font-size: 16px;
}

.loggedSource {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 36px;
}

.loggedSourceTitle {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.loggedBySection {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 24px;
}

.loggedByAvatar {
  box-shadow: 0px 1.74px 1.74px 0px #00000014;
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.loggedByValue {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  text-align: left;
  margin-right: -2px;
  text-decoration: underline;
}

.surveyQuestions {
  display: flex;
  flex-direction: column;
}

.questionsGrid {
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.questionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.questionItem:last-child {
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.questionText {
}

.resolutionInput {
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  padding: 16px 20px;
  height: 50px;
}

.resolutionInput::placeholder {
  color: #969696;
}

/* resolution component */
.resolutionContainer {
  background: #ffffff;
  padding: 36px;
  border-radius: 10px;
}

.resolutionRow {
  display: flex;
  margin-top: 12px;
  gap: 30px;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 36px;
}

.inputLabel {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.refundInput {
  width: 50%;
  height: 50px;
  padding: 16px 20px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  border-radius: 10px;
}

.refundInput::placeholder {
  color: #969696;
}

/* right content */

.rightContent {
  border-radius: 10px;
  width: 35%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 36px;
  overflow-y: visible;
  height: auto;
}

.sectionTitle {
  font-family: robotoBold;
  font-size: 18px !important;
  line-height: 21.09px;
  text-align: left;
}

.notesList {
  display: flex;
  flex-direction: column;
}

.noteItem {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--Border-2, #f2f2f2);
  padding: 24px 0;
}

.noteItem:first-child {
  margin-top: 8px;
}

.noteItem:last-child {
  border-bottom: none;
}

.noteHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.noteTimestamp {
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
}

.notePostedBy {
  font-size: 12px;
  line-height: 14.06px;
  text-align: left;
  color: #86949e;
}

.noteContent {
  font-size: 16px;
  line-height: 25.6px;
  text-align: left;
  margin-top: 16px;
  margin-right: 16px;
}

.verticalLine {
  position: absolute;
  right: 0;
  top: 24px;
  bottom: 24px;
  width: 3px;
  background-color: #f9f9f9;
}

/* add note section */
.addNoteSection {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.noteContainer {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 24px;
  min-height: 200px;
}

.noteInput {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  font-size: 16px;
  line-height: 18.75px;
  padding: 12px;
  border: none;
  resize: vertical;
  overflow-y: auto;
  margin-bottom: 16px;
}

.noteActions {
  display: flex;
  gap: 8px;
}

.noteFooter {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

/* Footer styles */
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 24px 36px;
}

.footerLeft {
  /* Left footer content */
}

.footerRight {
  display: flex;
  gap: 16px;
}
