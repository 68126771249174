.searchModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.searchContainer {
  background-color: rgba(247, 249, 251, 1);
  height: auto;
  width: auto;
  top: 0;
  left: 0;
  border-radius: 10px;
  position: relative;
  margin: auto;
}

.searchArea {
  height: 70px;
  padding: 36px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.searchArea p {
  color: rgba(133, 133, 133, 1);
  font-size: 16px;
}

.searchAreaLeft {
  display: flex;
  gap: 9px;
  justify-content: center;
  align-items: center;
}

.searchAreaRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchAreaLeft input {
  border: none;
  width: 400px;
  background: transparent;
  outline: none;
  padding: 10px;
  padding: 20px 15px;
  font-size: 16px;
}

.searchAreaLeft input::placeholder {
  font-size: 16px;
  color: rgba(133, 133, 133, 1);
}

.recentSearchesContainer {
  padding: 36px;
  height: 269px;
  width: 782px;
}

.recentSearchTab {
  display: flex;
  align-items: center;
  width: 746px;
  height: 49px;
  background-color: rgba(255, 255, 255, 1);
  padding: 16px;
  margin-left: -18px;
  border-radius: 10px;
  margin-top: 25px;
  justify-content: space-between;
}

.recentSearchTabLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.recentSearchTabRight {
  display: flex;
  align-items: center;
}

.recentSearchTabRight p {
  text-decoration: underline;
}

.otherRecentSearches {
  margin-top: 33px;
}

.otherRecentSearchTab {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.searchResultsContainer {
  width: 1048px;
  height: auto;
  overflow-y: auto;
  max-height: 80vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.searchResultsHeader {
  display: flex;
  align-items: center;
  height: 73px;
  border-top: 1px solid rgba(238, 238, 238, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding: 18px 36px;
  gap: 20px;
}

.searchResultFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  height: 36px;
  width: auto;
  border: 1px solid rgba(230, 230, 230, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.searchResultFilter:hover {
  background-color: rgba(14, 30, 41, 0.1);
}

.selected {
  background-color: rgba(14, 30, 41, 1);
  color: rgba(248, 248, 248, 1);
  font-weight: bold;
}

.searchResultsBody {
  width: 100%;
}

.resultCategory {
  width: 100%;
  padding: 10px 46px;
  height: 37px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.resultCategory p {
  font-size: 14px;
  font-family: 'robotoBold';
  color: rgba(172, 172, 172, 1);
}

.resultItem {
  padding: 24px 46px;
  height: 67px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultItemLeft {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 200px;
}

.resultItemMiddle {
  display: flex;
  align-items: center;
  gap: 44px;
}

.jobStatus {
  padding: 4px 11px;
  background-color: rgba(0, 148, 255, 1);
  border-radius: 25px;
}

.jobStatus p {
  color: rgba(255, 255, 255, 1);
  font-family: 'robotoBold';
}

.resultItemRight {
  text-decoration: underline;
  cursor: pointer;
}

.mobileNumber {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mobileNumberInner {
  display: flex;
  align-items: center;
  gap: 3px;
}

.location {
  display: flex;
  align-items: center;
  gap: 3px;
}

.loadingState {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #666;
}