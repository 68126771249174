/* icons */
.iconArrowDown {
  height: 9px;
  width: 12px;
  background: url('../assets/downArrow.svg') no-repeat;
  display: flex;
}

.iconGreenCheck {
  height: 16px;
  width: 16px;
  background: url('../assets/tickcircle.svg') no-repeat;
  display: flex;
}

.iconSMS {
  height: 16px;
  width: 16px;
  background: url('../assets/sms.svg') no-repeat;
  display: flex;
}

/* quote subheader */

.quoteSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
}

.quoteSubHeader * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

.subHeaderLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.quote {
  font-size: 18px !important;
}

.quoteNumber {
  font-size: 16px;
  font-family: robotoBold;
}

.subHeaderRight {
  display: flex;
  align-items: center;
  gap: 24px;
}

.quoteStatus {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #fff4e5; /* Changed to a light orange background for pending status */
}

/* quote summary */
.quoteSummary {
  display: flex;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 54px;
}

.summaryPendingTag {
  margin: 0 32px 0 48px;
  padding: 4px 12px;
  border-radius: 25px;
  font: interBold;
  font-size: 12px;
  font-family: robotoBold;
  color: #ff9800; /* Changed to orange for pending status */
  background: #fff4e5; /* Light orange background */
}

.summaryCustomer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
}

.summaryCustomer span {
  text-decoration: underline;
}

.summaryDate {
  display: flex;
  align-items: center;
  font-family: robotoBold;
  gap: 6px;
}

.summaryDate span {
  font-weight: 400;
}

.summaryQuoteNumber {
  margin-left: auto;
}

/* quote table */
.quoteTable {
  /* Add any specific styles for the quote table if needed */
}
