.iconClose {
  height: 34px;
  width: 34px;
  background: url('../assets/close.svg') no-repeat;
  display: flex;
}

.iconClockBlue {
  height: 24px;
  width: 24px;
  background: url('../assets/clockEight.svg') no-repeat;
  display: flex;
}

.iconCalendarBlue {
  height: 24px;
  width: 24px;
  background: url('../assets/calendarBlue.svg') no-repeat;
  display: flex;
}

.iconCheck {
  height: 16px;
  width: 16px;
  background: url('../assets/check.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background: #f7f9fb;
  border-radius: 17px;
  width: 100%;
  max-width: 780px;
  overflow: hidden;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 36px 42px 54px;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
}

.headerTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.modalBody {
  padding: 36px 36px 68px;
}

.sectionTitle {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.jobLocationContainer {
  margin-bottom: 24px;
}

.jobLocationContainer > .sectionTitle {
  font-size: 18px;
  margin-bottom: 16px;
}

.infoGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  row-gap: 24px;
  margin-bottom: 24px;
  background: #ffffff;
  padding: 36px;
  border-radius: 10px;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.value {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dateTimeContainer {
  padding: 36px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 10px;
}

.dateTimeWrapper {
  display: flex;
  gap: 16px;
}

.dateTimeGroup {
  flex: 1;
  display: flex;
  gap: 12px;
  align-items: center;
}

.dateTimeGroup > label,
.descriptionContainer > label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.dateTimeInputWrapper {
  position: relative;
  width: 100%;
}

.dateTimeInput {
  width: 100%;
  height: 48px;
  padding: 16px 20px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  appearance: none;
  -webkit-appearance: none;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dateTimeInput::-webkit-calendar-picker-indicator,
.dateTimeInput::-webkit-inner-spin-button,
.dateTimeInput::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.dateTimeInput::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.dateTimeInput[type='date']::-webkit-datetime-edit,
.dateTimeInput[type='time']::-webkit-datetime-edit {
  padding: 0;
}

.dateTimeInput[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.inputIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
}

.textarea {
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  min-height: 143px;
  color: var(--Text, #0e1e29);
  padding: 15px 20px 33px 20px;
}

.textarea::placeholder {
  color: #969696;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
  padding: 24px 36px;
  background: #fefeff;
}
