/* icons */
.iconToggleOn {
  height: 20px;
  width: 40px;
  background: url('../assets/toggleOn.svg') no-repeat;
  display: flex;
  cursor: pointer;
}
.iconToggleOff {
  height: 20px;
  width: 40px;
  background: url('../assets/toggleOff.svg') no-repeat;
  display: flex;
  cursor: pointer;
}

.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../assets/arrowDown.svg') no-repeat;
  display: flex;
}

.customServiceOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.customService {
  max-width: 1295px;
  width: 100%;
  height: 80vh;
  max-height: 900px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customServiceHeader {
  display: flex;
  align-items: center;
  padding: 30px 36px;
  border-bottom: 1px solid #e7e7e7;
  gap: 24px;
}

.customServiceHeaderTitle {
  font-family: robotoBold;
  font-size: 24px;
  line-height: 28.13px;
  text-align: left;
}

.customServiceBody {
  display: flex;
  overflow-y: auto;
}

.customServiceContent {
  flex: 0 0 55%;
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow-y: auto;
}

.customServiceSummary {
  flex: 0 0 45%;
  padding: 36px;
  border-left: 1px solid #e7e7e7;
  overflow-y: auto;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 0;
}

.input,
.textarea {
  width: 100%;
  padding: 16px 20px;
  border: 2px solid transparent;
  border-radius: 10px;
  background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #0e1e29;
  outline: none;
}

.textarea {
  min-height: 140px;
  resize: vertical;
}

.toggleGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.toggleRow {
  display: flex;
  align-items: center;
  gap: 20px;
}

.customPriceInput {
  width: 200px;
}

.hiddenSection {
  display: flex;
  flex-direction: column;
}

.billableHours {
  margin: 0 -36px;
  padding: 0 36px 36px;
  border-bottom: 1px solid #e7e7e7;
}

.billableHoursSubtitle {
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 4px 0 16px;
}

.dropdownGroup {
  display: flex;
  justify-content: space-between;
}

.dropdownRow {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropdownRow > * {
  flex: 1;
  width: 100%;
}

.additionalSection {
  margin: 0 -36px;
  padding: 36px;
  border-bottom: 1px solid #e7e7e7;
}

.additionalSectionHeader {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
}

.additionalSection.searchUtility {
  border-bottom: 0 !important;
  padding-bottom: 0;
}

.additionalSection > * {
  width: 100%;
}

.additionalSection > .label {
  margin-bottom: 16px;
}

.additionalWorkersContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.summaryCard {
  background-color: #edf2f5;
  border-radius: 10px;
  padding: 36px;
}

.summaryTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
  text-align: left;
  margin-bottom: 36px;
}

.summarySubtitle {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin-bottom: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.summaryDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 48px;

  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  min-height: 9em;
  max-height: 9em;
  overflow-y: auto;
}

.summaryTable {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 36px;
}

.summaryTableRow {
  display: flex;
  justify-content: space-between;
}

.summaryTableColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.summaryTableLabel {
  font-weight: bold;
  margin-bottom: 4px;
}

.summaryTableValue {
  font-size: 16px;
}

.totalCost {
  font-size: 16px;
  margin-bottom: 36px;
}
