.dashboardPage {
  /* padding: 24px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.chartSection {
  background: #ffffff;
  border-radius: 10px;
  padding: 36px;
  min-height: 300px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0e1e29;
}

.dateRange {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dateRange span {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 22.4px;
  color: #0e1e29;
}

.chartPlaceholder h2 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 24.67px;
}

.tablesSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  border-radius: 10px;
  overflow: hidden;
}
