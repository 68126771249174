/* icons */
.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../assets/arrowDown.svg') no-repeat;
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  /* margin: 0 -24px; */
  padding: 24px;
  border-bottom: 1px solid #e7e7e7;
}

.industrySelector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.billableHoursSubtitle {
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 4px 0 16px;
}

.dropdownRow {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropdownRow > * {
  flex: 1;
  width: 100%;
}

.label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  margin: 0;
}
