.iconPlus {
  height: 34px;
  width: 34px;
  background: url('../assets/plus.svg') no-repeat;
  display: flex;
}

.itemViewModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.itemViewContainer {
  position: absolute;
  top: 0;
  right: -50%;
  width: 50%;
  height: 100%;
  background-color: white;
  transition: right 0.15s ease-out;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.itemViewContainer.open {
  right: 0;
  box-shadow: -1px 0px 10.6px 0px #00000024;
}

.itemViewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  border-bottom: 1px solid #eee;
}

.itemViewHeader h2 {
  font-family: robotoBold;
  font-size: 24px;
  line-height: 26.95px;
  text-align: left;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.itemImagesContainer {
  display: flex;
  justify-content: space-between;
  height: 300px;
  margin: 60px 0;
  flex-shrink: 0;
}

.itemImageLarge {
  width: 80%;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.itemImageLarge img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.itemImageThumbnails {
  width: 20%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.itemImageSmall {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px 0px #00000005;
}

.itemImageSmall img {
  height: 72px;
  width: auto;
  object-fit: cover;
}

.itemViewContent {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 44px;
}

.itemTitle {
  font-family: robotoBold;
  font-size: 24px;
  line-height: 28.13px;
  text-align: left;
}

.itemSubtitle {
  font-family: robotoBold;
  font-size: 24px;
  line-height: 28.13px;
  text-align: left;
  color: #88a5bb;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.itemDescription {
  font-size: 16px;
  line-height: 22.5px;
  text-align: left;
  margin-bottom: 24px;
}

.specialNotesContainer {
  display: flex;
  align-items: flex-start;
  min-height: 150px;
}

.specialNotes {
  flex: 1;
  background: #fcfeff;
  border: 1px solid #c7e8ff;
  border-radius: 10px;
  padding: 30px;
  font-size: 16px;
  line-height: 22.5px;
}

.itemViewFooter {
  border-top: 1px solid #e8e8e8;
  padding: 20px 36px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
