.iconCheck {
  height: 14px;
  width: 14px;
  background: url('../assets/statusCheckIcon.svg') no-repeat;
  display: flex;
}

.stepsContainer {
  display: flex;
  gap: 8px;
}

.statusStep {
  padding: 6px 16px 6px 12px;
  background: linear-gradient(90deg, #ecfee8 0%, #adf3d2 105.84%);
  color: #0e1e29;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: robotoBold;
  font-size: 14px;
  line-height: 16.41px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  clip-path: polygon(0 0, calc(100% - 12px) 0, 100% 50%, calc(100% - 12px) 100%, 0 100%);
}

.incomplete {
  background: #eeffeb;
  color: #b1cec0;
}

.checkmark {
  font-size: 12px;
}
