/* icons */
.iconKey {
	height: 16px;
	width: 16px;
	background: url("./assets/key.svg") no-repeat;
	display: flex;
}

.iconPen {
	height: 16px;
	width: 16px;
	background: url("./assets/pen.svg") no-repeat;
	display: flex;
}

.iconLocation {
	height: 16px;
	width: 16px;
	background: url("./assets/location.svg") no-repeat;
	display: flex;
}

.iconMap {
	height: 16px;
	width: 16px;
	background: url("./assets/map.svg") no-repeat;
	display: flex;
}

.iconPhone {
	height: 16px;
	width: 16px;
	background: url("./assets/call.svg") no-repeat;
	display: flex;
}

.iconSMS {
	height: 16px;
	width: 16px;
	background: url("./assets/sms.svg") no-repeat;
	display: flex;
}

.iconCalendar {
	height: 16px;
	width: 16px;
	background: url("./assets/calendar.svg") no-repeat;
	display: flex;
}

.iconClock {
	height: 16px;
	width: 16px;
	background: url("./assets/clock.svg") no-repeat;
	display: flex;
}

.jobDetails {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	box-shadow: 0px 8px 12px 0px #f3f3f380;
	border-radius: 10px;
	padding: 35px 35px 40px;
}

.jobDetails * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

/* header */
.header {
	display: flex;
	justify-content: space-between;
}

.headerLeft {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.jobIdContainer {
	display: flex;
	gap: 20px;
}

.jobDetailsText {
	font-size: 16px;
	font-family: robotoBold;
}

.jobId {
	font-size: 24px;
	font-family: robotoBold;
	color: #0e1e29;
}

.jobIdStatus {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-family: "interBold";
	color: white;
	background-color: #0094ff;
	border-radius: 25px;
	padding: 4px 12px;
}

.headerRight {
	display: flex;
	gap: 10px;
}

/* job site address */

.jobsiteAddress {
	display: flex;
	flex-direction: column;
	margin-top: 35px;
	gap: 8px;
}

.addressLabel {
	display: flex;
	font-size: 18px;
	font-family: robotoBold;
}

.address {
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 16px;
}

.mapContainer {
	position: relative;
}

.map {
	border-radius: 10px;
}

.mapIcon {
	right: 20px;
	bottom: 16px;
	height: 40px;
	width: 40px;
	position: absolute !important;
	padding: 0 !important;
	cursor: pointer;
}

/* site contact */

.siteContact {
	margin-top: 35px;
}

.siteContactTitle {
	margin-bottom: 35px;
	font-size: 18px;
}

.siteContactContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.siteContactTop,
.siteContactBottom {
	display: flex;
	justify-content: flex-start;
}

.siteContactTop .item:nth-child(2) {
	margin-right: auto; /* Pushes the second item to the left and leaves space for the third item */
}

.contact {
	flex-basis: 33.33%;
	box-sizing: border-box;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.contactLabel {
	font-family: robotoBold;
}

.contactValue {
	display: flex;
	gap: 8px;
}

/* job details */

.jobDetailsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	padding: 35px;
	background-color: #f4f7f9;
	border-radius: 10px;
	margin-top: 36px;
}

.jobDetail {
	display: flex;
	flex-direction: column;
}

.jobDetailLabel {
	font-family: robotoBold;
	margin-bottom: 4px;
}

.jobDetailValueContainer {
	display: flex;
	gap: 14px;
}

.jobDetailValue {
	display: flex;
	align-items: center;
	gap: 8px;
}

.jobDescriptionTitle {
	margin: 24px 0 16px;
}

.description {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px;
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	color: #0e1e29;
}

.verticalLine {
	position: absolute;
	right: 20px;
	top: 24px;
	bottom: 24px;
	width: 4px;
	background-color: #f9f9f9;
}

.securityPinContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 24px;
  border-radius: 6px;
  height: 40px;
}

.securityPinText {
  color: #000000;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.securityPinValue {
  color: #000000;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
