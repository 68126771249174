.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../../assets/downArrow.svg') no-repeat;
  display: flex;
}

.container {
  width: 100%;
  padding: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  border-radius: 10px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 22.4px;
}

.chartWrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

.chartContainer {
  width: 180px;
  height: 180px;
}

.legendContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.legendItem {
  display: flex;
  align-items: center;
  position: relative;
}

.colorIndicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 12px;
}

.labelStyle {
  margin-right: 8px;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 19.6px;
}

.dottedLine {
  flex: 1;
  border-bottom: 1px dotted #dedede;
  margin: 0 8px;
}

.tradeCategory {
  color: #666;
  background: #eef5fa;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 19.6px;
  color: #0e1e29;
  padding: 4px 12px;
  border-radius: 34px;
  min-width: 90px;
  text-align: center;
}
