/* icons */
.iconArrow {
  width: 12px;
  height: 12px;
  background: url('../assets/arrowRight.svg') no-repeat;
  display: flex;
}

.iconAdd {
  height: 16px;
  width: 16px;
  background: url('../assets/add.svg') no-repeat;
  display: flex;
}

.iconMinus {
  height: 16px;
  width: 16px;
  background: url('../assets/minus.svg') no-repeat;
  display: flex;
}

.itemCard {
  height: 320px;
  padding: 24px 24px 18px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  flex-basis: calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  min-width: 200px;
}

.selectedItem {
  border: 2px solid var(--Action, #0094ff);
  padding: 23px 23px 17px;
}

.unselectedItem {
  border: 1px solid var(--Border-1, #d8d8d8);
}

.clickable {
  cursor: pointer;
}

.itemHeader {
  display: flex;
  align-items: center;
}

.itemTag {
  font-family: robotoBold;
  font-size: 12px;
  line-height: 14.06px;
  text-align: left;
  color: white;
  padding: 4px 16px;
}

.popularTag {
  background-color: #2bda86;
}

.saleTag {
  background-color: #ff6f6f;
}

.itemImage {
  height: 150px;
  width: 100%;
  overflow: hidden;
}

.itemImage > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: top left;
}

.itemDescription {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.itemSubtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  color: #7d7d7d;
  margin-bottom: 5px;
}

.itemDetails {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.itemPriceContainer {
  display: flex;
  flex-direction: column;
}

.itemOriginalPrice {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  color: #c2c2c2;
  text-decoration: line-through;
}

.itemPrice {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  color: #555555;
}

.quantityControl {
  display: flex;
  align-items: center;
  gap: 8px;
}

.quantityControl span {
  font-family: robotoBold;
  color: #555555;
  margin: 0 10px;
}
