/* MaterialsUsedTable.module.css */
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.materialsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
  border-radius: 8px;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  margin-left: 24px;
  margin-bottom: 16px;
}

.materialsTable th {
  padding: 12px 16px;
  text-align: left;
  background: #f4f7f9;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.materialsTable td {
  padding: 16px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.materialsTable th:first-child,
.materialsTable td:first-child {
  padding-left: 42px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.materialsTable th:last-child,
.materialsTable td:last-child {
  padding-right: 42px;
  text-align: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tableTitle {
  font-weight: 600;
  margin-bottom: 16px;
}

/* Column specific styles */
.productColumn {
  width: 80%;
  text-align: left;
}

.quantityColumn {
  width: 10%;
  text-align: center;
  padding-right: 16px !important;
}

.costColumn {
  width: 10%;
  text-align: right;
  padding-right: 40px !important;
}

/* Total row styles */
.totalRow {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.totalRow td {
  padding: 0 16px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.totalRow td:first-child {
  text-align: right;
  padding-right: 16px;
  width: 90%;
}

.totalRow td:last-child {
  text-align: right;
  width: 10%;
  padding-right: 40px;
}

/* Header text alignment adjustments */
.materialsTable th.quantityColumn {
  text-align: right;
  padding-right: 48px !important;
}

.materialsTable th.costColumn {
  text-align: right;
  padding-right: 40px !important;
}
