/* icons */
.iconPlus {
  height: 34px;
  width: 34px;
  background: url('../assets/plus.svg') no-repeat;
  display: flex;
}

.iconAdd {
  height: 16px;
  width: 16px;
  background: url('../assets/add.svg') no-repeat;
  display: flex;
}

.iconMinus {
  height: 16px;
  width: 16px;
  background: url('../assets/minus.svg') no-repeat;
  display: flex;
}

.iconCart {
  height: 16px;
  width: 16px;
  background: url('../assets/cart.svg') no-repeat;
  display: flex;
}

.priceBookModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.priceBook {
  max-width: 1295px;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

/* header */

.priceBookHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  border-bottom: 1px solid #e7e7e7;
}

.priceBookTitle {
  font-family: 'robotoBold' !important;
  font-size: 24px;
  line-height: 28.13px;
  text-align: left;
  padding: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

/* body */
.priceBookBody {
  display: flex;
  height: 100%;
  overflow-y: auto;
}

/* left nav */

.priceBookLeftNavigation {
  display: flex;
  flex-direction: column;
  min-width: 370px;
  border-right: 1px solid #e6e6e6;
}

.priceBookLeftNavigationHeader {
  border-bottom: 1px solid #e6e6e6;
  padding: 18px 24px;
}

.priceBookLeftNavigationBody {
  overflow-y: auto;
}

.parentTab {
  display: flex;
  flex-direction: column;
}

.parentTabOpen {
  border-bottom: 1px solid #e6e6e6;
}

.parentTabHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 18px 30px;
  border-bottom: 1px solid #e6e6e6;
}

.parentTitle {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.childTabContainer {
  display: flex;
  flex-direction: column;
  padding: 18px 30px;
  gap: 10px;
}

.childTab {
  display: flex;
  flex-direction: column;
}

.childTabHeader {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  border-radius: 10px;
  cursor: pointer;
}

.childTitle {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.childTitleSelected {
  font-family: robotoBold;
}

/* content */

.priceBookContent {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 24px;
  flex: 1;
  overflow: hidden;
}

.priceBookContentHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.priceBookContentHeader input {
  flex: 1;
  height: 40px;
  margin-right: 10px;
}

/* content body */

.priceBookContentBody {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
}
