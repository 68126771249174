/* icons */
.iconTrash {
  height: 16px;
  width: 16px;
  background: url('../assets/trash.svg') no-repeat;
  display: flex;
}

.searchUtilities {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.utilityContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 140px;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.header > span {
  font-family: robotoBold;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.content > div {
  display: grid;
  grid-template-columns: 1fr 1fr 140px;
  align-items: center;
  gap: 16px;
  padding: 8px 20px;
  font-size: 14px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-family: robotoBold;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
  margin-top: 20px;
}
