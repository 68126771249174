.chartContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chartContainer h3 {
  font-family: robotoBold;
  font-size: 16px;
  margin-bottom: 16px;
}

.chartArea {
  position: relative;
  width: 360px;
  height: 180px;
  align-self: center;
}
