.listContainer {
  padding: 24px 12px;
  overflow-y: auto;
  /* margin: 0 -24px; */
  flex: 1;
}

.category {
  margin-bottom: 24px;
}

.categoryTitle {
  padding: 0 24px;
  margin: 0 0 20px;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.techniciansList {
  display: flex;
  flex-direction: column;
}

.technicianItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}

.technicianItem:first-child {
  border-top: 1px solid #e8e8e8;
}

/* .technicianItem:hover {
  
} */

.technicianItem.active {
  outline: 1px solid #46b1ff;
  box-shadow: 0px 9px 10.8px 0px #fbfdff;
  border-radius: 10px;
}

.techInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.id {
  font-family: robotoRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.93px;
  color: #0e1e29;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.blueBadge {
  background: #0085ff;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}
