.tableContainer {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 20px;
}

.searchControls {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.searchWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  left: 20px;
  color: #858585;
  pointer-events: none;
}

.searchWrapper input {
  flex: 1;
  width: 100%;
  height: 48px;
  padding: 0 16px 0 56px;
  color: #0e1e29;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.searchWrapper input::placeholder {
  color: #858585;
}

.tableWrapper {
  overflow-x: auto;
  padding: 0px 24px 24px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  padding: 12px 30px;
  background: #f4f7f9;
  text-align: left;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  color: #0e1e29;
}

.table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table td {
  padding: 8px 30px;
  border-bottom: 1px solid #f3f3f3;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.table td:last-child {
  display: flex;
  justify-content: center;
}

.receipt {
  background: #f4f7f9;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: robotoRegular;
  font-size: 14px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}
