@charset "UTF-8";

@font-face {
  font-family: "robotoRegular";
  src: url("/public/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "robotoBold";
  src: url("../public/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "interBold";
  src: url("../public/fonts/Inter-Variable.ttf");
}

* {
  font-family: robotoRegular;
  color: #32404A;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  font-size: 14px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #F8F8F8;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar:horizontal {
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #F8F8F8;
}

*::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
}

html {
  overflow-y: overlay;
}

body {
  background-color: #F7F9FB;
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.App {
  font-size: 16px;
  height: calc(100% - 70px);
}

.App>nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 97;
  box-shadow: 1px -1px 5px 0px #F1F1F1;
}

button {
  position: relative !important;
  overflow: hidden;
  transition: background 400ms !important;
}

h1,
h2,
h3,
h4,
b,
strong {
  font-family: robotoBold;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

span.ripple {
  position: absolute;
  /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(0, 0, 0, 0.178);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.hide {
  display: none !important;
}

/* // fonts */
.bold {
  font-family: RobotoBold;
}

.not-bold {
  font-family: RobotoRegular;
  font-weight: normal;
}

.c-0094FF {
  color: #0094FF;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

/* // heights */
/* For WBSelector */
.max-height-207 {
  max-height: 207px !important;
}

.max-height-200 {
  max-height: 200px !important;
}

.max-height-245 {
  max-height: 240px !important;
}

.max-height-350 {
  max-height: 350px !important;
}

.h-full {
  height: calc(100vh - 70px);
}

.h-max {
  height: 100%;
}

/* // fonts  */
.text-base {
  font-size: 22px;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 15px;
}

.shortDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.shortDesc b {
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.longDesc {
  font-size: 16px;
  text-wrap: wrap;
  line-height: 22px;
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* // tables */
.table {
  position: relative;
  width: 100%;
  height: auto;
  border-collapse: separate !important;
}

.table .tableHeadInfo {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.table .tableHeadInfo #pageInfo {
  align-items: center;
  display: flex;
}

.table .pageCount {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.table .pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 5px;
  margin-top: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.table .pagination .active {
  box-shadow: inset 0 0 0px 2px #0094FF;
}

.table .table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  display: flow;
}

.table .table-container.h-custom1 {
  height: calc(100vh - 330px);
  min-height: 150px;
}

.table .table-container.h-custom2 {
  height: calc(100vh - 280px);
}

/* .table .table-container.h-custom3 {
  height: calc(100vh - 273px);
}

.table .table-container.h-custom4 {
  height: calc(100vh - 213px);
} */

.table .table-container::-webkit-scrollbar:horizontal {
  height: 20px;
  position: fixed !important;
  bottom: 0;
}

.table .table-container .image_row {
  width: 50px;
}

.table .table-container .status {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: flex;
  position: absolute;
  left: 0;
  top: 30px;
}

.table .table-container .status.active {
  background-color: #44C989;
}

.table .table-container .status.inactive {
  background-color: #f44336;
}

.table .table-container .status.not-active {
  background-color: #e2e2e2;
}

.table .table-container .nowrap {
  white-space: nowrap;
}

.table .table-container .wrap {
  white-space: wrap;
}

.table .table-container .header {
  display: table-header-group;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #F4F7F9;
  padding-bottom: 5px;
  line-height: 42px;
  border: 1px solid #F4F7F9;
  border-radius: 10px;
  width: 100%;
}


.table .table-container .header .cell {
  max-width: 200px;
  font-family: RobotoBold;
}

.table .table-container .header .cell .longtitle {
  width: 100%;
}

.table .table-container .header .cell:nth-last-child(-n+2) {
  text-align: right;
}

.table .table-container .cell {
  padding: 5px 20px;
  display: table-cell;
  align-items: center;
  border-bottom: 1px solid #F1F1F1;
  text-wrap: nowrap;
}

.table .table-container .body {
  display: table-row-group;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
}

.table .table-container .body .no_data {
  z-index: 100;
  padding: 20px;
}

.table .table-container .body .row {
  gap: 0;
  padding: 0;
  display: table-row;
  min-height: 60px;
  max-height: 75px !important;
}

.table .table-container .body .row:hover {
  /* transform: scale(1.001); */
}

.table .table-container .body .row .cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  max-width: 200px;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.table .table-container .body .row .static.cell {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: none;
}

.table .table-container .body .row .cell.title {
  max-width: 300px;
  min-width: 200px;
  text-wrap: wrap;
  overflow-wrap: break-word;
}

.table .table-container .body .row .cell.description {
  max-width: 400px;
  min-width: 250px;
  text-wrap: wrap;
  overflow-wrap: break-word;
}

.table .table-container .body .row .cell img {
  max-height: 65px;
  max-width: 65px;
  height: auto;
  width: auto;
}

.table .table-container .cellTitle {
  vertical-align: middle;
}

.table .table-container .body .row .cell .profile_image {
  border-radius: 50%;
  max-width: 34px;
  height: auto;
  top: 19px;
  position: absolute;
}

.table .table-container .body .row .cell .categories {
  display: flex;
  flex-direction: row;
  text-wrap: wrap;
  overflow-wrap: break-word;
  align-items: center;
}

.table .table-container .body .row .cell .categories p {
  font-size: 14px;
}

.table .table-container .body .row .cell .categories div {
  display: flex;
  align-items: center;
  line-height: normal;
}

/* .table .table-container .body .row .cell .categories div:nth-child(2) {
  font-size: 14px;
  padding-left: 10px;
  gap: 10px;
} */

/* .table .table-container .body .row .cell .categories div:nth-child(3) {
  font-size: 14px;
  padding-left: 25px;
  gap: 10px;
} */

.table .table-container .body .row .cell:nth-last-child(-n+2) {
  text-align: right;
  justify-content: end;
}

.table .table-container .body .row .cell:nth-last-child(1) {
  width: max-content;
}

.table .table-container .body .row .cell:nth-last-child(-n+2)>div {
  justify-content: end;
}

.table .table-container .row {
  border-bottom: 1px solid #F1F1F1;
}

.table .table-container .row:hover {
  background-color: #F3FAFF;
}

.table .table-container .row:hover .option .dot-icon {
  background-color: #5E7E95;
}

.table .table-container .row:hover .cell {
  background-color: #F3FAFF;
}

.table .table-container .static {
  background-color: #FCFCFC;
  min-width: 73px;
  width: 100%;
  line-height: 50px;
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
  max-width: 200px;
  position: sticky;
  left: 0px;
  white-space: nowrap;
}

.table .table-container .static1 {
  /* background-color: #FCFCFC; */
  min-width: 73px;
  line-height: 50px;
  /* box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03); */
  max-width: 200px;
  position: sticky;
  left: 0px;
  white-space: nowrap;
}

.table .table-container .static2 {
  background-color: #FCFCFC;
  min-width: 100px;
  line-height: 50px;
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
  width: 100%;
  position: sticky;
  left: 73px;
  white-space: nowrap;
}

.table .table-container .cellTitle {
  min-width: 280px;
  line-height: 30px;
  position: sticky;
  white-space: nowrap;
}

.table .table-container .serviceTitle {
  min-width: 250px;
  max-width: 280px;
  line-height: 30px;
  position: sticky;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .table-container .body .row .cell.code {
  width: 120px !important;
}

.table .table-container .serviceTitle .serviceParts {
  display: flex;
  align-items: baseline;
  font-size: 14px;
}

.table .table-container .cell.serviceLogo {
  display: flex !important;
  height: 75px !important;
  width: 75px !important;
  position: sticky;
  white-space: nowrap;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.table .table-container .staticAuto {
  /* width: 0; */
  /* background-color: #FCFCFC; */
  line-height: 30px;
  /* box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03); */
  position: sticky;
  left: 0px;
  white-space: nowrap;
}

.table .table-container .auto {
  width: 100%;
  background-color: #FCFCFC;
  line-height: 50px;
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
  left: 0px;
  white-space: nowrap;
}

.table .table-container .head {
  display: inline-table;
  border-bottom: 1px solid #F1F1F1;
}

.table .table-container .head .cell {
  font-family: RobotoBold;
}

/* // gaps{ */
.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px
}

.gap-20 {
  gap: 20px
}

.gap-30 {
  gap: 30px;
}

/* //text colors*/
.text-green {
  color: #15c36f;
}

.text-grey {
  color: #bcbcbc;
}

/* // dispplays*/
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
  align-items: center;
}

.just {
  display: flex;
}

/* // ---colors---*/
.color1 {
  color: #324552;
}

/* // ---backgrounds---*/
.bg-light-grey {
  background-color: #f0f0f0;
}

.bg-light-blue {
  background-color: #f5f9fc;
}

.bg-light-grey {
  background-color: #f3f3f3;
}

.bg-white {
  background-color: white;
}

.bg-wbblue {
  background-color: #EDF7FF;
}

/* // ---paddings--- */
/* // both */
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-40 {
  padding: 40px !important;
}

/* // top */
.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-40 {
  padding-top: 40px;
}

/* // left  */
.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px !important;
}

/* // right*/
.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-70 {
  padding-right: 70px !important;
}

/* // bottom*/
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

/* // Padding sides*/

.ps-5 {
  padding-right: 2.5px;
  padding-left: 2.5px;
}

.ps-10 {
  padding-left: 5px;
  padding-right: 5px;
}

/* // ---margins---*/
.m-10 {
  margin: 10px;
}

/* // left*/
.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-100 {
  margin-left: 100px;
}

/* // top*/
.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}


/* // right*/
.mr-10 {
  margin-right: 10px;
}

/* // bottom*/
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

/* // top and bottom*/
.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* // left and right*/
.mlr-20 {
  margin-left: 20px;
  margin-right: 20px;
}

/* // widths */
.w-auto {
  width: auto !important;
}

.w-200 {
  width: 200px !important;
}

.wp-30 {
  width: 30% !important;
}

.wp-40 {
  width: 40% !important;
}

.wp-50 {
  width: 50% !important;
}

.w-full {
  width: 100% !important;
  max-width: none !important;
}

.width-full {
  width: 100% !important;
  max-width: none !important;
}

.minw-500 {
  min-width: 500px;
}

.minw-720 {
  min-width: 720px;
}

.minw-800 {
  min-width: 800px;
}

.maxw-1500 {
  max-width: 1500px;
}

.maxw-300 {
  max-width: 300px;
}

.maxw-250 {
  max-width: 250px;
}

.maxw-200 {
  max-width: 200px;
}

.maxw-100 {
  max-width: 100px;
}

.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-500 {
  width: 500px;
}

.fw-150 {
  width: 150px;
}

nav {
  display: flex;
  background-color: #fff;
  padding: 10px;
  justify-content: space-between;
}

#leftnav {
  z-index: 96;
  font-size: 16px;
}

#leftNavBar {
  display: flex;
}

@media (max-width: 768px) {
  #leftNavBar {
    display: none;
  }
}

nav #left {
  display: flex;
}

nav #right {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

nav #profile-image {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  height: 46px;
  width: 46px;
}

.no-border {
  border-radius: none !important;
}

.no-background {
  background-color: transparent !important;
}

.navbar {
  background: var(--Text, #0E1E29);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.navbar-brand {
  display: flex;
  padding-left: 30px;
}

.subnav {
  background-color: #0e1e29;
  border-top: 1px solid #344048;
  padding: 10px 30px;
}

.subnav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.subnav li {
  position: relative;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  color: #ffffff;
}

.subnav .active {
  background: #0094ff;
  color: #ffffff;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  border-radius: 5px;
}

/* .w {
  &-48 {
      width: 48px;
  }

  &-100-flex {
      width: 100%;
  }

} */
.h-48 {
  height: 48px;
}

/* // buttons */
.response_btn {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 15px 15px;
  font-family: RobotoBold;
}

.response_btn:hover {
  cursor: pointer;
  border: 1px solid #0094FF;
}

.option .dot-icon {
  background-color: #d0d0d0;
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 50%;
}

.btn-nav-right {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  position: relative;
  border: solid 1px #e1e1e1
}

.btn-nav-right:hover {
  border: 1px solid #eeeeee;
  cursor: pointer;
}

.btn-nav-right.noborder {
  border: none !important;
}

.btn-dropdown {
  border: 1px solid #eeeeee;
  height: 48px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 20px;
  position: relative;
  -webkit-user-select: none;
  /* Safari */
  /* IE 10 and IE 11 */
  -moz-user-select: none;
  user-select: none;
  /* Standard syntax */
  transition: border 100ms ease-out;
  gap: 10px;
}

.btn-dropdown.green {
  background-color: #19CC76;
  color: white;
}

.btn-dropdown-menu {
  display: none;
  width: inherit;
  position: absolute;
  top: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 100;
  min-width: 176px;
  right: 0;
}

.btn-dropdown-menu ul {
  padding: 0;
  margin: 0;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  gap: 0;
}

.btn-dropdown-menu ul li {
  list-style: none;
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.btn-dropdown-menu ul li:last-child {
  border-bottom: none;
}

.btn-dropdown-menu ul li a {
  text-decoration: none;
  padding: 16px 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  flex: 1;
}

.btn-dropdown-menu ul li a:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.btn-dropdown-menu ul li a:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-dropdown-menu ul li a:hover {
  background-color: #fdfdfd;
}

.btn-dropdown-menu ul li a:first-child {
  border-bottom: 0px;
}

.btn-dropdown-menu ul li a:last-child {
  border-top: none;
}

.btn-dropdown .title {
  /* // margin-right: 10px; */
  text-wrap: nowrap;
}

.btn-dropdown .title-white {
  color: white;
}

.btn-dropdown:hover {
  border: 1px solid #0094FF;
  cursor: pointer;
}

/* icons */
i.icon-link {
  height: 14px;
  width: 14px;
  background: url("/public/images/icons/link.svg") no-repeat;
  display: flex;
}

i.icon-scheduler {
  height: 15px;
  width: 15px;
  background: url("/public/images/icons/scheduler.svg") no-repeat;
  display: flex;
}

i.icon-reports {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/reports.svg") no-repeat;
  display: flex;
}

i.icon-price-book {
  height: 19px;
  width: 20px;
  background: url("/public/images/icons/price-book.svg") no-repeat;
  display: flex;
}

i.icon-accounting {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/accounting.svg") no-repeat;
  display: flex;
}

i.icon-settings {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/settings.svg") no-repeat;
  display: flex;
}

i.icon-settings-primary {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/settings-primary.svg") no-repeat;
  display: flex;
}

i.icon-settings-light {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/settings-primary-light.svg") no-repeat;
  display: flex;
}

i.icon-active {
  height: 9px;
  width: 8px;
  background: url("/public/images/icons/active.svg") no-repeat;
  display: flex;
}

i.icon-inactive {
  height: 9px;
  width: 8px;
  background: url("/public/images/icons/inactive.svg") no-repeat;
  display: flex;
}

i.icon-circle-plus {
  height: 41px;
  width: 42px;
  background: url("/public/images/icons/circle-plus.svg") no-repeat;
  display: flex;
}

i.icon-circle-minus {
  height: 41px;
  width: 42px;
  background: url("/public/images/icons/circle-minus.svg") no-repeat;
  display: flex;
}

i.icon-arrow-down {
  height: 22px;
  width: 24px;
  background: url("/public/images/icons/new-arrow-down.svg") no-repeat;
  display: flex;
}

i.icon-arrow-down-2 {
  height: 10px;
  width: 10px;
  background: url("/public/images/icons/arrow-down-2.svg") no-repeat;
  display: flex;
}

i.icon-arrow-up {
  height: 22px;
  width: 24px;
  background: url("/public/images/icons/new-arrow-down.svg") no-repeat;
  display: flex;
  transform: scaleY(-1);
}

i.icon-arrowLeft {
  height: 10px;
  width: 6px;
  background: url("/public/images/accounting/paginationLeft.svg") no-repeat;
  display: flex;
}

i.icon-arrowRight {
  height: 10px;
  width: 6px;
  background: url("/public/images/accounting/paginationRight.svg") no-repeat;
  display: flex;
}

i.icon-linked {
  height: 22px;
  width: 24px;
  background: url("/public/images/icons/Iconlinked.svg") no-repeat;
  display: flex;
  margin-top: 10px;
}

i.icon-unlinked {
  height: 20px;
  width: 20px;
  background: url("/public/images/icons/Iconunlinked.svg") no-repeat;
  display: flex;
  margin-top: 10px;
}

i.icon-enabled {
  height: 24px;
  width: 44px;
  background: url("/public/images/icons/switch_on.svg") no-repeat;
  display: flex;
}

i.icon-disabled {
  height: 24px;
  width: 44px;
  background: url("/public/images/icons/switch_off.svg") no-repeat;
  display: flex;
}

i.icon-google {
  height: 20px;
  width: 20px;
  background: url("/public/images/google-icon.svg") no-repeat;
  display: flex;
}

/* i.icon-arrow-down.green {
  background-color: #17B669;
} */

i.icon-media {
  height: 13px;
  width: 16px;
  background: url("/public/images/icons/media.svg") no-repeat;
  display: flex;
}

i.icon-arrow-right-light {
  height: 10px;
  width: 6px;
  background: url("/public/images/icons/arrow-right-light.svg") no-repeat;
  display: flex;
}

i.icon-arrow-right-dark {
  height: 12px;
  width: 6px;
  background: url("/public/images/icons/arrow-right-dark.svg") no-repeat;
  display: flex;
}

i.icon-arrow-left-dark {
  height: 10px;
  width: 6px;
  background: url("/public/images/icons/arrow-left-dark.svg") no-repeat;
  display: flex;
}

i.icon-search {
  height: 22px;
  width: 22px;
  background: url("/public/images/icons/search.svg") no-repeat;
  display: flex;
}

i.icon-phone {
  height: 19px;
  width: 19px;
  background: url("/public/images/icons/phone-incoming.svg") no-repeat;
  display: flex;
}

i.icon-search-white {
  height: 22px;
  width: 22px;
  background: url("/public/images/icons/search-white.svg") no-repeat;
  display: flex;
}

i.icon-bell {
  height: 18px;
  width: 18px;
  background: url("/public/images/icons/bell.svg") no-repeat;
  display: flex;
}

i.icon-trash {
  height: 14px;
  width: 15px;
  background: url("/public/images/icons/trash.svg") no-repeat;
  display: flex;
}

i.icon-trash-red {
  height: 12.74px;
  width: 12.74px;
  background: url("/public/images/icons/trash-red.svg") no-repeat;
  display: flex;
}

i.icon-help {
  height: 19px;
  width: 20px;
  background: url("/public/images/icons/help.svg") no-repeat;
  display: flex;
}

i.icon-bell.notification::before {
  height: 10px;
  width: 10px;
  background-color: #ff7a00;
  right: 5px;
  top: 5px;
  content: "";
  border-radius: 50%;
  position: absolute;
}

i.icon-info-circle {
  height: 24px;
  width: 24px;
  background: url("/public/images/icons/info-circle.svg") no-repeat;
  display: flex;
}

i.icon-close {
  height: 34px;
  width: 34px;
  background: url("/public/images/icons/close.svg") no-repeat;
  display: flex;
}

i.icon-3square {
  height: 22px;
  width: 22px;
  background: url("/public/images/icons/3square.svg") no-repeat;
  display: flex;
}

i.icon-folder-open {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/folder-open.svg") no-repeat;
  display: flex;
}

.icon-dashboard {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/dashboard.svg") no-repeat;
  display: flex;
}

i.icon-filter {
  height: 16px;
  width: 16px;
  background: url("/public/images/accounting/filter.svg") no-repeat;
  display: flex;
}

i.icon-briefcase {
  height: 16px;
  width: 16px;
  background: url("/public/images/accounting/briefcase.svg") no-repeat;
  display: flex;
}

i.icon-coin {
  height: 27px;
  width: 27px;
  background: url("/public/images/inventory/dashboard/coin.svg") no-repeat;
  display: flex;
}

i.icon-more-options {
  height: 5px;
  width: 19px;
  background: url("/public/images/icons/more-options.svg") no-repeat;
  display: flex;
}

i.icon-truck-products {
  height: 16px;
  width: 16px;
  background: url("/public/images/inventory/trucks/truck-products.svg") no-repeat;
  display: flex;
}

i.icon-truck-restock {
  height: 24px;
  width: 24px;
  background: url("/public/images/inventory/trucks/truck-restock.svg") no-repeat;
  display: flex;
}

i.icon-open-folder {
  height: 17px;
  width: 16px;
  background: url("/public/images/icons/openfolder.svg") no-repeat;
  display: flex;
}

i.icon-trash-red-alt {
  height: 17px;
  width: 16px;
  background: url("/public/images/icons/trash-red-alt.svg") no-repeat;
  display: flex;
}

i.icon-blankprofile {
  height: 100px;
  width: 100px;
  background: url("/public/images/icons/default_logo.svg") no-repeat;
  display: flex;
}

i.icon-checkbox-empty {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/checkbox-empty.svg") no-repeat;
  display: flex;
}

i.icon-checkbox-tick {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/checkbox-tick.svg") no-repeat;
  display: flex;
}

i.icon-card-tick {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/card-tick.svg") no-repeat;
  display: flex;
}

i.icon-pencil {
  height: 12.5px;
  width: 12.5px;
  background: url("/public/images/icons/pen.svg") no-repeat;
  display: flex;
}

i.icon-check {
  height: 16px;
  width: 16px;
  background: url("/public/images/icons/check.svg") no-repeat;
  display: flex;
}

/* // ---borders--- */
.border-grey {
  border: 1px solid #F0F0F0;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-bottom-10 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-bottom-grey {
  border-bottom: 1px solid #EEEEEE;
}

.border-top-grey {
  border-top: 1px solid #EEEEEE;
}


/* // backgrounds */
.bg-black {
  background-color: rgba(0, 0, 0, 0.5607843137);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 98;
}

.bg-darkblue {
  background-color: rgba(19, 39, 73, 0.54);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 98;
}

/* .bg-darkblue:not(.popup-window) {
  filter: blur(1px);
} */

/* .bg-white {
  background-color: #fff;
  border-left: 1px solid #F8F8F8;
  border-right: 1px solid #F8F8F8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
} */

#filename {
  display: none;
  align-items: center;
  gap: 10px;
  border: 2px solid #038ced;
  border-radius: 10px;
  padding: 15px;
  margin-left: 0;
}

#filename p {
  padding: 0;
  margin: 0;
}

#filename img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 4px;
}

/* pricebook */
.add-service-page {
  position: absolute;
  z-index: 10;
  width: calc(100% - 426px);
  background-color: white;
}

.add-service-page p *,
.add-service-page h3 * {
  margin: 8px;
}

.add-service-page .header {
  background-color: #0094FF;
  line-height: 30px;
}

.add-service-page .headerfiles {
  line-height: 30px;
}

.add-service-page .header .cell {
  color: #fff;
  background-color: #0094FF !important;
  padding: 0px 20px;
}

.add-service-page .row .cell {
  border-bottom: 1px solid #D0E0EB !important;
}

.add-service-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
}

.add-service-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.add-service-row input {
  width: 100%;
}

/* .stickyTable {
  overflow-x: auto;
  white-space: nowrap;
} */

.add-service-page .table .table-container .body {
  font-size: 14px;
}

.add-utility-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.add-utility-page .content {
  position: absolute;
  right: 0;
  z-index: 99;
  width: calc(60% - 393px);
  /* Adjust this value as needed */
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.add-utility-page .content .header {
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #F1F1F1;
  background-color: white;
  padding: 20px;
  z-index: 101;
}

.add-utility-page .content .footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #F1F1F1;
  background-color: white;
  padding: 20px;
  box-shadow: 0px -4px 13px #00000029;
  z-index: 101;
}

.add-utility-page .content .body {
  flex: 1;
  overflow-y: auto;
}


.add-utility-page .content .modal_body {
  height: 100%;
}

.add-utility-page .content .row:not(.wbselector .row) {
  margin: 20px;
}

select {
  display: block;
  width: 15em;
  height: 40px;
  border: 1px solid #e2e3e5;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsPSJncmF5IiBkPSJNMTAsMjMyLjdsNDkwLDUzNC41bDQ5MC01MzQuNUgxMHoiIC8+DQo8L3N2Zz4=);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right;
  background-position-x: calc(100% - 10px);
}

.content-center {
  align-items: center;
}

.content-between {
  justify-content: space-between;
}

#search_input_icon {
  position: relative;
}

#search_input_icon::after {
  width: 22px;
  height: 22px;
  background: url("/public/images/icons/search.svg") no-repeat;
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  right: 13px;
}

#search_filters {
  padding-right: 46px;
}

#pricebook {
  width: 100%;
}

#pricebook #tbl_orgs_service_price {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pricebook #tbl_orgs_service_price td,
#pricebook #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#pricebook #tbl_orgs_service_price tr:nth-child(even) {
  background-color: #f2f2f2;
}

#pricebook #customers tr:hover {
  background-color: #ddd;
}

#pricebook #tbl_orgs_service_price th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0094FF;
  color: white;
}

#pricebook .select_label {
  display: block;
  text-align: left;
  font-size: 11pt;
  font-weight: bold;
  padding-left: 5px;
}

.div_cat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  flex-shrink: 0;
}

#pricebook .div_cat {
  display: inline-block;
  margin-right: 20px;
  padding: 1em;
}

#pricebook .cat_select {
  display: block;
  width: 14em;
  border: 1px solid #e2e3e5;
}

#pricebook select {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  padding-left: 15px;
  padding-right: 40px;
  font-size: 16px;
}

#pricebook select:focus {
  outline: none;
  border: 2px solid #0094FF;
}

#pricebook .cat_label {
  display: block;
  margin-left: 0.3em;
  text-align: left;
  font-weight: 600;
}

#pricebook .form_input {
  display: block;
  width: 30em;
  border: 1px solid #e2e3e5;
  border-radius: 5px 5px 5px 5px;
}

#pricebook .form_input_height {
  height: 50px;
}

#pricebook .div_form_item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

#pricebook .div_code_block {
  display: inline-block;
  vertical-align: top;
}

#pricebook .div_code_block_border {
  border: 1px solid #e2e3e5;
  border-radius: 5px 5px 5px 5px;
  margin-left: 90px;
  margin-top: 16px;
}

#pricebook .success {
  background-color: #04AA6D;
  margin-top: 5px;
}

#pricebook .success:hover {
  background-color: #46a049;
}

#pricebook .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

#pricebook .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

#pricebook .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

a.delete {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background: #82854C;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
}

a.nav-link {
  display: flex;
  gap: 5px;
  height: 48px;
  align-items: center;
  padding: 13px;
  text-decoration: none;
  border-radius: 10px;
  font-family: RobotoRegular;
  border: 1px solid var(--Text, #0E1E29);
  transition: border 100ms ease-out;
  text-wrap: nowrap;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a.nav-link:hover {
  border: 1px solid #0094FF;
  /* box-shadow: 0px 5px 7.6px 0px #0094FF; */
}

a.nav-link.active {
  border: 1px solid #0094FF;
}

#navMenu {
  display: flex;
}

ul {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
}

li {
  list-style: none;
}

/* Search */
#search {
  position: fixed;
  background-color: #fff;
  top: 0;
  bottom: 0;
  width: 600px;
  z-index: 99;
  overflow-y: scroll;
  right: -600px;
  animation: searchContent 200ms forwards ease;
}

@keyframes searchContent {
  0% {
    right: -600px;
  }

  100% {
    right: 0px;
  }
}

.easyOut {
  animation: searchContentOut 200ms forwards ease !important;
}

@keyframes searchContentOut {
  0% {
    right: 0px;
  }

  100% {
    right: -600px;
  }
}

.fadeOut {
  display: none;
  transition: opacity 100ms ease-out;
  opacity: 0;
}

#search-content {
  display: flex;
  gap: 10px;
}

#search-content span {
  display: flex;
  justify-content: center;
  align-items: center;
}

#search-content span:hover {
  background-color: #038ced;
  cursor: pointer;
}

.modal {
  display: none;
}

.modal_content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  top: 0;
  bottom: 0;
  /* // min-width: 600px; */
  max-width: 100%;
  z-index: 99;
  overflow-y: scroll;
  right: -600px;
  animation: searchContent 200ms forwards ease;
  position: fixed;
  /* // span {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     &:hover {
  //         background-color: #038ced;
  //         cursor: pointer;
  //     }
  // } */
}

.modal_content .header {
  justify-content: start;
}

.modal_content input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #CFD9E1;
  padding-left: 15px;
  /* // flex: 1; */
}

.modal_content input:focus {
  outline: none;
  border: 2px solid #0094FF;
}

.modal_content textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #CFD9E1;
  padding: 15px;
  /* // flex: 1; */
}

.modal_content textarea:focus {
  outline: none;
  border: 2px solid #0094FF;
}

.modal_content .search-content {
  display: flex;
  gap: 10px;
}

.modal_content h4.title {
  font-family: RobotoBold;
  font-size: 14px;
  margin: 0;
}

.modal_content .permissions {
  margin-bottom: 20px;
}

.modal_content .permissions>.title {
  background-color: #0094FF;
  color: #fff;
  font-family: RobotoBold;
  padding: 20px 40px;
}

.modal_content .permissions .list {
  background-color: #F8FCFF;
  border-bottom: 1px solid #EAF4FB;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}

.modal_content .permissions .list .details .title {
  font-family: RobotoBold;
}

#search-recent-content {
  margin: 15px 0;
}

#search-recent {
  display: flex;
  gap: 10px;
}

#search-recent span {
  border: 1px solid #0094FF;
  background-color: #FAFDFF;
  color: #0094FF;
  border-radius: 10px;
  padding: 10px 15px;
}

#search-recent span b {
  color: #0094FF;
}

#search-recent span:hover {
  background-color: #edf8fe;
  color: #0094FF;
  border: 1px solid #0094FF;
  cursor: pointer;
}

#search-recent-title {
  margin: 15px 0;
}

#search_result .result-list {
  border-top: 3px solid #F4F4F4;
}

#search_result .result-list h5 {
  padding: 0 20px;
}

#search_result .items {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F5F5F5;
  padding: 20px;
  align-items: center;
}

#search_result .items b {
  color: #038ced;
}

#search-modal {
  display: none;
}

::-moz-placeholder {
  color: #C3CFD7;
}

::placeholder {
  color: #C3CFD7;
}

.width-200 {
  width: 200px;
  flex: none !important;
}

.width-auto {
  width: auto;
  flex: none !important;
}

.integration-list {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 25px;
  align-items: center;
}

.integration-list .left-side {
  display: flex;
  gap: 30px;
}

.integration-list .left-side .inner-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.integration-list .left-side .inner-right .header {
  font-family: RobotoBold;
}

.integration-list .left-side .inner-right .status {
  color: #D8D8D8;
}

.btn {
  border-radius: 10px;
  width: -moz-max-content;
  width: max-content;
  min-width: 40px;
  height: 40px;
  font-family: robotoRegular;
  font-size: 16px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  text-wrap: nowrap;
  transition: box-shadow 0.1s ease;
}

.btn:hover:hover {
  cursor: pointer;
  /* // box-shadow: inset 0 0 0 2px #dddddd; */
}

.btn.btn-success {
  background-color: #19CC76;
  color: #fff;
  font-family: RobotoBold;
  padding: 10px 20px;
}

.btn.btn-success:hover {
  border: none;
  background-color: #15c36f;
}

.btn.btn-action {
  background-color: #0094ff;
  color: #fff;
  font-family: RobotoBold;
}

.btn.btn-alert {
  background-color: #ff0004;
  color: #fff;
  font-family: RobotoBold;
}

.btn.btn-default {
  background-color: #fff;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4.5985403061px 4.5985403061px 0px rgba(0, 0, 0, 0.04);
  color: #4D5357;
  padding: 15px;
}

.btn.btn-default.no-border {
  background-color: transparent;
  border: none;
  padding: 15px;
  box-shadow: none;

}

.btn.btn-default:hover {
  border: 1px solid #0094ff;
  background-color: #fff;
}

.btn.btn-default.no-border:hover {
  background-color: #eeeeee;
  border: none;
}

.btn.btn-action-border {
  border: 1px solid #eeeeee;
  font-family: robotoBold;
  padding-left: 20px;
  padding-right: 20px;
  /* // border:solid 2px #0094ff; */
}

.btn.btn-action-border:hover {
  border-color: #0094ff;
  border-width: 1px;
}

.btn.btn-action-no-border {
  border: 1px solid transparent;
  font-family: robotoBold;
  padding-left: 20px;
  padding-right: 20px;
  /* // border:solid 2px #0094ff; */
}

.btn.btn-action-no-border:hover {
  border: 1px solid #0094ff;
  border-width: 1px;
}

.btn.btn-action-no-border:hover .option .dot-icon {
  background-color: #5E7E95;
}

.btn.btn-sm {
  padding: 8px 15px;
  height: auto;
  border-radius: 8px;
  font-family: "robotoRegular" !important;
}

label {
  margin-bottom: 5px;
  display: block;
  margin-left: 10px;
  font-size: 14px;
  font-family: RobotoBold;
}

form label {
  margin-bottom: 5px;
  display: block;
  margin-left: 10px;
  font-size: 16px;
  font-family: RobotoBold;
}

form p {
  margin-left: 10px;
  margin-top: 0;
}

input {
  height: 40px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #F1F1F1;
  background: linear-gradient(0deg, #F6F6F6 0%, #F3F3F3 100%), #FFF;
}

textarea {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #F1F1F1;
  background: linear-gradient(0deg, #F6F6F6 0%, #F3F3F3 100%), #FFF;
}

input:focus {
  outline: none;
  border: 2px solid #0094FF;
  background: #FFF;
}

input:focus::placeholder {
  color: transparent;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

input.half {
  width: 50%;
}

textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  padding: 15px;
}

textarea:focus {
  outline: none;
  border: 2px solid #0094FF;
}

.flex-1 {
  flex: 1;
}

.custom-dropdown {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-dropdown select {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  padding-left: 15px;
  padding-right: 40px;
}

.custom-dropdown select:focus {
  outline: none;
  border: 2px solid #0094FF;
}

.custom-dropdown::after {
  /* content: "˅"; */
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 13px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
}

.row .col {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* max-width: 70%; */
}

.add-utility-page .row .col .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 0px;
}

.response_create_new {
  background-color: #EDF7FF;
  border-radius: 10px;
}

/* // organisation */
.response,
.response2 {
  margin-top: 5px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  max-height: 244px;
  overflow-y: auto;
}

.response p,
.response2 p {
  margin: 10px;
  color: #939393;
}

.response .row,
.response2 .row {
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 5px;
}

.response .row:last-child,
.response2 .row:last-child {
  border-bottom: none;
}

.response-selected,
.response-selected2,
.response-selected-utility,
.response-selected-filter,
.response-selected-material {
  border-radius: 10px;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.response-selected p,
.response-selected2 p,
.response-selected-utility p,
.response-selected-filter p,
.response-selected-material p {
  margin: 10px;
  color: #939393;
}

.response-selected .row,
.response-selected2 .row,
.response-selected-utility .row,
.response-selected-filter .row,
.response-selected-material .row {
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 5px;
}

.response-selected .row:last-child,
.response-selected2 .row:last-child,
.response-selected-utility .row:last-child,
.response-selected-filter .row:last-child,
.response-selected-material .row:last-child {
  border-bottom: none;
}

.response-selected2 {
  border-radius: 10px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e9e9e9;
}

.response-selected2 p {
  margin: 10px;
  color: #939393;
}

.response-selected2 .row {
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 5px;
}

.response-selected2 .row:last-child {
  border-bottom: none;
}

.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
}

.result .text {
  padding-left: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.container .form-group {
  flex: 1;
}

.container .form-group.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container .form-group.row label {
  width: 50%;
}

.container .form-group.row .custom-dropdown {
  width: 100%;
}

.space-around {
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

.all-upload-buttons {
  display: flex;
  width: 100%;
  margin: 10px 0px;
}

.add-service-upload_media {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  display: flex;
  padding: 15px 20px;
  gap: 10px;
  align-items: center;
  width: auto;
}

.add-service-upload_media.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0.5px solid #E8E8E8;
}

.add-service-upload_media.middle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-left: 0.5px solid #E8E8E8;
  border-right: 0.5px solid #E8E8E8;
}

.add-service-upload_media.left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0.5px solid #E8E8E8;
}

.add-service-upload_media:hover {
  border: 1px solid #0094FF;
  cursor: pointer;
}

.upload_media {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  display: flex;
  padding: 15px 20px;
  gap: 10px;
  align-items: center;
  min-width: 260px;
}

.upload_media:hover {
  border: 1px solid #0094FF;
  cursor: pointer;
}

.file-list-table {
  display: table;
}

.col {
  display: flex;
  flex-direction: column;
}

.col-12 {
  display: flex;
  gap: 10px;
}

.col-12 .form-group {
  flex: 1;
}

.logs {
  height: calc(100vh - 70px);
}

.logs .section {
  padding: 20px;
}

.logs .log_section {
  padding: 10px 0;
}

.logs .log_section p {
  margin: 0;
}

.alert {
  padding: 20px;
  margin-bottom: 20px;
}

.alert-warning {
  background-color: #FFF8F2;
  color: #E17008;
  font-family: robotoBold;
}

i.icon-search-loader {
  height: 24px;
  width: 24px;
  background: url("/public/images/icons/search-loader.svg") no-repeat center;
  display: flex;
}

i.icon-tooltip {
  height: 24px;
  width: 24px;
  background: url("/public/images/icons/tooltip.svg") no-repeat center;
  display: flex;
}

.animate-rotate {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

/* // loading icon */
.search-spinner {
  display: flex;
  gap: 10px;
  align-items: center;
}

.border {
  height: 1px;
  background-color: #F1F1F1;
}

.divider {
  height: 1px;
  background-color: #F1F1F1;
}

#organisation-form #header {
  background-color: #F8FBFF;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1000px;
  max-width: 1260px;
}

#organisation-form #header h3 {
  margin: 0;
}

.content-area {
  width: 100%;
  background-size: cover;
  max-width: 1000px;
}

.content-area .section {
  padding: 50px;
}

.content-area .section:last-child {
  padding: 50px;
  border: none;
}

.content-area-full {
  width: 100%;
  min-width: calc(100% - 393px);
  min-height: 360px;
  height: calc(100vh - 70px);
  background-color: #F7F9FB;
}

.content-area-full .section {
  padding: 50px;
}

.content-area-full .section:last-child {
  padding: 50px;
  border: none;
}

/* // sections */
.section {
  border-bottom: 1px solid #F1F1F1;
  padding: 10px;
}

.border-top {
  border-top: 1px solid #F1F1F1;
}

.border-bottom {
  border-top: 1px solid #F1F1F1;
}

.border-bottom-thick {
  border-top: 5px solid #F1F1F1;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  /* Ensure it takes the full width */
  z-index: 10;
  /* Adjust as needed */
}

.sticky {
  position: fixed;
  top: 70px;
  left: 0;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.border-top-1 {
  border-top: 1px solid #F1F1F1;
}

#settings {
  display: flex;
  /* // margin-top: 92px; */
  padding-left: 393px;
  height: calc(100% - 70px);
}

#settings nav {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 393px;
  padding: 0;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  position: fixed;
  left: 0;
}

#settings nav .whitespace {
  padding: 10px 0;
}

#settings nav .section {
  border-bottom: 1px solid #F1F1F1;
  padding: 10px;
}

/* #settings nav ul li {
  /* padding: 14px 16px; */
/* } */

#settings nav .section ul li a {
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 16px;
}

#settings nav .section ul li a.active {
  background-color: #eaf7ff;
  /* border: 2px solid #8bcfff; */
}

#settings nav .section ul li a:not(.active):hover {
  border: 1px solid #038ced;
}

#settings nav .section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

#settings nav .section .header h4 {
  font-size: 16px;
  margin-block: 20px;
}

#settings nav .section .header .btn-dropdown-menu ul li a {
  border: 1px solid #eeeeee;
  border-radius: 0;
}

#settings nav .section .header .btn-dropdown-menu ul li a:hover {
  background-color: #ffffff !important;
  border: 1px solid #038ced;
}

#settings nav .section .header .btn-dropdown-menu ul li:first-child a {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#settings nav .section .header .btn-dropdown-menu ul li:last-child a {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

#settings nav .section .header .btn-dropdown-menu ul li:only-child a {
  border-radius: 6px;
}

#settings ul {
  flex-direction: column;
  align-items: self-start;
  margin-left: 0;
  gap: 0;
}

#settings ul li {
  display: flex;
  width: 100%;
}

#settings ul a {
  padding: 15px 20px;
  display: flex;
  flex: 1;
  text-decoration: none;
  /* // border-radius: 10px; 
   &:hover {
      // background-color: #EFF8FF;
  } */
}

#settings .settings-full-width {
  height: calc(100vh - 70px);
  width: 100%;
  /* overflow-y: auto; */
}

/* Here on are James's custom css classes */

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.invisible {
  opacity: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.bottom-sticky {
  bottom: 0;
  box-shadow: 0px -4px 13px #00000029;
  position: fixed;
  width: 100%;
}

.top-sticky {
  position: sticky;
  top: 70px;
  border-bottom: 1px solid #EAEAEA;
  display: flex;
  z-index: 2;
}

/* .top-sticky .table-container {
  overflow-x: hidden;
} */

/* The alert message box */
.alertpopup {
  padding: 20px;
  background-color: #f44336;
  color: white;
  margin-bottom: 15px;
  position: absolute;
  top: 100px;
  z-index: 101;
  width: 50%;
  margin-left: 25%;
}

/* The close button */
.alertclosebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.alertclosebtn:hover {
  color: black;
}

.image-title {
  padding: 10px;
}

.remove {
  color: red;
  padding-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.remove:hover {
  color: darkred;
}

.view-image {
  padding-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.thumbnail-prompt {
  padding-left: 10px;
  text-decoration: underline;
  color: #007AD1;
  cursor: pointer;
}

.thumbnail-prompt.selected {
  text-decoration: none;
  color: #000;
  cursor: default;
}

.thumbnail-prompt:hover:not(.selected) {
  color: #0094FF;
}

.button-row {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
}

.button-row .left {
  justify-content: flex-start;
}

.button-row .right {
  justify-content: flex-end;
}

.button-row .title {
  padding-left: 15px;
}

.fixedElement {
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px -4px 13px #00000029;
}

.font-blue {
  color: #0094FF;
}

.font-brown {
  color: #9C5E00;
}

.italic {
  font-style: italic;
}

.image-collection {
  display: flex;
}

.add-service-image {
  max-height: 200px;
  max-width: 300px;
  min-width: 100px;
  min-height: 50px;
}

.add-service-pdf {
  height: 100px;
  width: 75px;
  cursor: pointer;
}

.custom-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 50px;
  border-radius: 10px;
  background: #F4F7F9;
}

.custom-header .custom-cell {
  text-align: start;
  font-weight: bold;
  padding: 0px 20px;
}

.custom-row {
  padding-block: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #F1F1F1;
  vertical-align: middle;
  padding: 10px 20px;
}

.custom-row:nth-child(1) {
  border: none;
}

.custom-cell {
  flex-basis: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}

.custom-cell:first-child {
  flex-basis: 90%;
}

.custom-cell:nth-last-child(-n+2) {
  /* text-align: end; */
  align-items: end;
}

.popup {
  z-index: 98;
}

.popup-window {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  min-width: 550px;
  max-width: 90vw;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.popup-window .popup-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.popup-window .popup-container .title {
  padding: 1.5vw 1.5vw 0 1.5vw;

  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-window .popup-container #modalBody {
  flex: 1;
  padding: 0.5vw 1.5vw;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.popup-window .popup-container .popup-button-row {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: white;
  z-index: 10;
  padding: 1vw 1.5vw;
  border-top: 1px solid #EAEAEA;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}

.popup-container h1 {
  font-size: 20px;
}

.popup-container h2 {
  font-size: 17px;
}

.popup-container h3 {
  font-size: 13px;
}

@media (max-width: 767px) {
  .popup-container h1 {
    font-size: 15px;
  }

  .popup-container h2 {
    font-size: 12px;
  }

  .popup-container h3 {
    font-size: 8px;
  }
}

.popup-container-image {
  padding: inherit;
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: block;
}

.popup-container-image .popup-image {
  max-height: 80vh;
  min-width: 30vw;
  max-width: 80vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: #00000029 0px 4px 8px 0px;
}

.floating-x {
  position: absolute;
  top: 20%;
  right: 5%;
  transform: translate(-50%, -50%);
  z-index: 104;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: #00000029 0px 4px 8px 0px;
}

.floating-x:hover {
  cursor: pointer;
}

.popup-context {
  padding-block: 1vw;
}

.popup-context h2,
.popup-context h3,
.popup-context input {
  margin-bottom: 0.5vw;
}

.popup-footer {
  padding: 25px;
}



.flex-grid {
  padding-block: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.floating-box {
  margin: 16px;
  padding: 36px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 22px 0px rgba(231, 231, 231, 0.50);
  min-height: 350px;
  height: calc(100% - 40px);
}

.floating-box-static {
  margin: 16px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 22px 0px rgba(231, 231, 231, 0.50);
  max-width: 100%;
  overflow-x: auto;
}

.floating-box-dynamic {
  margin: 16px;
  margin-bottom: 16px;
  padding: 36px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 22px 0px rgba(231, 231, 231, 0.50);
  height: max-content;
  width: max-content;
}

.floating-box .hr,
.floating-box-dynamic .hr,
.floating-box-static .hr {
  margin: 0;
}

/* .floating-box .table .table-container.h-custom1 {
  height: calc(100% - 313px);
} */

.table-search {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.table-search-input {
  flex: 1;
  min-width: 200px;
  background: url("/public/images/icons/search.svg") no-repeat;
  padding-left: 30px;
  background-position: 9px 9px;
  padding-left: 40px;
}


.filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.filterTitle {
  margin-right: 10px;
}

.div_categories {
  flex: 1;
  max-width: max-content;
}

@media (max-width: 768px) {
  .table-search {
    flex-direction: column;
    gap: 10px;
  }
}

.filterTitle {
  display: flex;
  flex-wrap: nowrap;
  width: 110px;
}

.dualbutton {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  width: max-content;
  height: 40px;
}

.dualbutton.button .background-center img {
  height: 50px !important;
  width: 50px !important;
}

.dualbutton.button {
  display: flex;
  align-content: end;
  flex-direction: row;
  border: solid 1px #e1e1e1;
  border-radius: 10px;
  overflow: hidden;
}

.dualbutton.button:hover {
  border: solid 1px #0094FF;
}

.dualbutton.button .background-left {
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
}

.dualbutton.button .background-right {
  max-width: 50px;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  align-items: center;
}

.dualbutton.button .background-center {
  max-width: 50px;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
}

.dualbutton.button .item {
  padding: 15px;
  text-align: center;
  text-wrap: nowrap;
  user-select: none
}

.dualbutton.button .item p {
  color: white;
  margin: 0;
}

.dualbutton.button .item i {
  color: white;
  margin: 0;
}

.dualbutton-dropdown-menu {
  position: absolute;
  top: 100%;
  overflow-y: auto;
  max-height: 500px;
  z-index: 100;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* margin-top: -40px; */
  width: max-content;
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}


.dualbutton-dropdown-menu.small {
  max-height: 200px;
}

.dualbutton-dropdown-menu .dropdown-menu-list {
  padding: 0;
  margin: 0;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  gap: 0;
  z-index: 100;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item.small {
  height: 30px;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item:last-child {
  border-bottom: none;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p {
  width: max-content;
  text-decoration: none;
  padding: 16px 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  flex: 1;
  user-select: none;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a.small,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p.small {
  padding: 8px 10px;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a:first-child:hover,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a:last-child:hover,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a:hover,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p:hover {
  background-color: #fdfdfd;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a:first-child,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p:first-child {
  border-bottom: 0px;
}

.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item a:last-child,
.dualbutton-dropdown-menu .dropdown-menu-list .dropdown-menu-item p:last-child {
  border-top: none;
}

.rows {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.rows.bottom {
  padding-bottom: 10px;
}

.rows .col {
  display: flex;
  flex-direction: column;
}

.rows .col p {
  text-align: center;
}

.tempdivfortesting {
  min-width: 35vw;
  height: 100%;
  border: 1px solid #0094FF;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #0094FF;
  padding: 20px;
}

.tempdivfortesting .customcabinet {
  margin-block: 10px;
}

.rows .tempdivfortesting {
  justify-content: space-around;
}

.rightdrawer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  gap: 10px;
}

.rightdrawer .response_btn {
  margin-left: 10px;
}

.organisation-section {
  background-color: #fff;
  min-width: 1000px;
  max-width: 1260px;
  padding-top: 24px;
}

.organisation-section h2 {
  margin: 20px;
}

.organisation-section .row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.organisation-section .row .col {
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.organisation-section .row .col .input {
  flex-direction: column;
  width: 100%;
  padding: 3px;
  padding-inline: 5px;
}

.organisation-section .row .col .input label {
  padding-bottom: 1px;
}

.org-licences {
  display: flex;
  flex-direction: row;
  max-width: 40vw;
  align-items: baseline;
}

.org-licences input {
  width: 20vw;
  margin: 10px;
}

.customcabinet {
  border: 1px solid #ADD2EA;
  background-color: white;
}

.customcabinet.v2 {
  border-radius: 10px;
  border: 1px solid #E6EDF1;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.customcabinet.open:hover {
  background-color: rgb(252, 252, 252);
}

.customcabinet .body {
  border-radius: 20px;
}

.customcabinet .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.customcabinet .header.small {
  display: flex;
  height: 60px;
  padding: 19px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

}

.customcabinet .header .left {
  display: flex;
  flex-direction: column;
  left: 0;
}

.customcabinet .header .left .lefttop {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  user-select: none;
  -ms-user-select: none;
}

.customcabinet .header .left .leftbottom {
  padding-left: 20px;
  user-select: none;
  -ms-user-select: none;
  background: linear-gradient(transparent, rgb(255, 255, 255));
}

.customcabinet .header .left p {
  margin-left: 20px;
}

.customcabinet .header .right {
  padding-right: 20px;
  align-items: center;
}

.customcabinet .header .right.small {
  display: flex;
  padding-right: 20px;
  align-items: stretch;
  align-content: baseline;
  gap: 20px;
}

.cabinet-status {
  padding: 3px;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
  padding-inline: 8px;
  margin-bottom: 5px;
  user-select: none;
  font-family: 'interBold';
  margin: 10px;
}

.cabinet-status.active {
  background-color: #48C390;
}

.cabinet-status.inactive {
  background-color: rgb(109, 109, 109);
}

.cabinet-title {
  font-size: 15px;
  font-weight: bold;
  /* margin-bottom: 0px; */
}

.cabinet-title.small {
  font-size: 15px;
  font-weight: normal;
  /* margin-bottom: 0px; */
}

.titleandbutton {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

.titleandbutton label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 10px;
}

.titleandbutton .left {
  display: flex;
  align-items: center;
}

.titleandbutton .right {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 40px;
}

.inputandremove {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linking {
  border: 1px solid #F0F0F0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100px;
}

.linking .left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linking .left img {
  max-width: 150px;
  max-height: 150px;
  width: 60px;
}

.linking .left .text {
  align-self: start;
  margin-top: 50%;
  margin-bottom: 50%;
}

.linking .left .text .title {
  padding-left: 20px;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0px;
}

.linking .left .text .subtitle {
  padding-left: 20px;
  font-size: 14px;
}

.linking .left .text .subtitle.linked {
  color: rgb(92, 180, 80);
}

.linking .left .text .subtitle.notlinked {
  color: grey
}

.linking .right {
  right: 0;
}

.bpayDesc {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.bpayDesc .row {
  flex-direction: row;
}

.bpayDesc .row .col {
  flex-direction: column;
  padding-bottom: 0px;
  padding-right: 0px;
}

.bpayDesc .row .col p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}


.bpayTitle {
  font-weight: bold;
  max-width: 200px;
  min-width: 100px;
}

.bpayDesc {
  max-width: 500px;
  min-width: 300px;
}

.activebar {
  display: flex;
  background: #EEF7FD;
  height: 57px;
  padding: 16px 40px;
  align-items: center;
  gap: 45px;
  align-self: stretch;
}

.activebar .left {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.activebar .left .switch {
  padding-left: 20px;
}

.activebar .right {
  padding-right: 40px;
}

.wbselector {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.wbselector.nosearch {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.wbselector.side {
  display: flex;
  flex-direction: row;
  height: 275px;
}

.wbselector.side.wide {
  min-width: 1250px;
}

.wbselector.side.nosearch {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.wbselector .one {
  width: 100%;
  padding-right: 5px;
  height: 100%;
}

.wbselector .two {
  width: 100%;
  padding-left: 5px;
  height: 100%;
}

.wbselector .three {
  width: 100%;
  padding-left: 5px;
  height: 100%;
  padding-top: 25px;
}


/* LOGIN */

.login {
  display: flex;
  flex-direction: column;
  margin: 5% auto;
  width: 530px;
}

#loginContent * {
  font-size: 16px;
}

.login a {
  font-weight: bold;
  text-decoration: underline;
  padding-inline: 4px;
}

#loginContent button.loginbutton {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  color: #0E1E29 !important;
  background-color: #fff !important;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold !important;
}

#loginContent button.loginbutton:hover {
  background-color: #f4f4f4 !important;
}

.loginmodal .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.loginmodal .checkbox p {
  word-wrap: none;
}

#loginHeader {
  padding: 40px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: center;
}


#loginFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  width: 530px;
  padding-bottom: 40px;
}

#loginFooter p {
  font-size: 12px;
  color: #9F9F9F;
}

.loginmodal {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.loginmodal .input {
  width: 100%;
  border-radius: 40px;
  border-top-style: none;
  margin-bottom: 20px;
}

.loginmodal input[type="checkbox"] {
  height: auto !important;
  width: auto !important;
}

.loginmodal label {
  font-family: robotoRegular;
  margin: 0;
}

#loginContent {
  padding: 40px;
}

#loginContent input {
  background-color: #F5F5F5;
  border: 1px solid #F1F1F1;
}

#loginContent .input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#loginContent input::placeholder {
  color: #969696;
}

#loginContent input:hover {
  border: 1px solid #009dff;
}

.loginmodal .input.googlesignin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 10px;
}

.loginmodal .input.googlesignin p {
  margin: 10px;
}

.login .input.tos {
  background: none;
  padding-top: 20px;
}

.loginmodal .input .forgotpassword {
  background: none;
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  font-size: 14px !important;
  /* width: 110px; */
  margin-top: -40px;
  margin-right: 10px;
  background-color: transparent !important;
}

.loginmodal .input button:hover {
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(245, 245, 245);
  border-radius: 10px;
}

.loginmodal .input .signup {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
  width: 100%;
  padding-left: 40px;
  padding-bottom: 20px;
  gap: 10px;
}

.checkbox p {
  min-width: 100%;
  word-wrap: none;
}

.loginmodal img {
  width: 230px;
}

.loginmodal .btn {
  width: 85%;
  margin-inline: 20px;
}

.hr {
  width: 100%;
  border-bottom: 1px solid #F1F1F1;
  padding: 0;
  margin: 0;
}

.googlesignin {
  width: 500px;
  height: 100px;
}

.removesession {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #b3b3b3;
}

.allbh {
  overflow-y: auto;
}

.bhTitle {
  padding: 30px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
}

.bh .title {
  font-weight: bold;
}

.bhSearch {
  padding: 0 30px 30px 30px;
}

.bhBody {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 13px;
}

.billablehour {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 25px 40px;
}

.billablehour .title {
  font-size: 15px;
  margin: 0 0 10px 0 !important;
}

.billablehour .title .subtitle {
  display: flex;
  font-size: 15px;
  margin: 0 !important;
}

.billablehour.subtitle {
  width: 300px;
  display: flex;
  word-wrap: normal;
  align-items: center;
  font-size: 15px;
  margin: 0 !important;
}

.billablehour .item {
  display: flex;
  gap: 25px;
}

.billablehour .hourlyratetitle {
  display: flex;
  align-items: baseline;
  gap: 20px;
  padding-bottom: 20px;
}

.billablebody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 100%;
}

.billablebody .title {
  font-size: 15px;
  margin: 0 0 10px 0 !important;
}

.billableheader {
  display: flex;
  padding: 25px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  align-items: baseline;
}

.billabletable {
  border: 1px solid #E6EDF1;
  border-radius: 20px;
  width: 100%;
  display: table;
  margin-bottom: 25px;
}

.billabletable .row {
  width: 100%;
  display: table-row;
}

.billabletable .row .col {
  display: table-cell;
  padding-inline: 15px;
  padding-block: 10px;
  border-bottom: 1px solid #E6EDF1;
}

.billabletable .row .col .subrow {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.billabletable .row:last-child .col {
  border-bottom: none;
}

.billabletable .row:first-child .cell {
  margin-left: 15px;
}

.billabletable .row:first-child .cell p {
  font-weight: bold;
}

/* .billabletable .col:last-child .cell {
  text-align: right;
  margin-right: 15px;
} */

.billabletable .row:first-child .col:last-child .cell {
  margin-right: 20px;
}

.billabletable .row .col:last-child .cell {
  display: flex;
  flex-direction: row-reverse;
}

.billableInput {
  height: 48px;
  background-color: #F6F6F6;
  font-size: 16px;
  border: 2px solid #F6F6F6;
}

.billableInput:hover:not(:focus) {
  border: 2px solid #d6d6d6;
}

.billableInput.half {
  width: 50%;
}

.billableInput.third {
  width: 33.33%;
  width: calc(100% / 3);
}

.invisTable {
  border: 2px solid white;
}

.invisTable:hover:not(:focus) {
  border: 2px solid rgb(247, 247, 247);
}

.checkers {
  display: flex;
  flex-direction: column;
  margin: 25px 40px;
  font-size: 14px;
}

.checkers .checker {
  border: 1px solid #E6EDF1;
  border-radius: 6px;
}

.checkers .checker.selected {
  display: flex;
  flex-direction: row;
}

.checkers .checker.unselected {
  padding: 10px;
}

.checkers .checker.selected p {
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 10px;
  padding-left: 13px;
  display: flex;
  align-items: center;
}

.checkers .checker.selected .selectorItem.right p {
  padding: 12px;
}

.checkers .checker.unselected p {
  margin: 0;
}

.left-align {
  display: flex;
  top: 0;
  left: 0;
}

.align-right {
  display: flex;
  flex-direction: row-reverse;
}

.checkers .selectedCheckers {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 6px;
  background: #FFF;
  height: 46px;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.checkers .unselectedCheckers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 36px;
  width: 100%;
  cursor: pointer;
}

.checkers .selectorItem {
  display: flex;
  align-content: baseline;
  gap: 10px;
  height: 33px;
}

.checkers .selectorItem.left {
  background-color: #0094FF;
  border-radius: 6px 0 0 6px;
}

.checkers .selectorItem.right {
  background-color: #007AD1;
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
}

.checkers .selectorItem.right:hover {
  background-color: #015a99;
}

.locationService {
  width: 100%;
  gap: 25px;
}

.locationService .org {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.changelong {
  height: max-content;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.filter-blue {
  filter: brightness(0) saturate(100%) invert(37%) sepia(62%) saturate(3643%) hue-rotate(189deg) brightness(110%) contrast(101%);
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(96%) saturate(2%) hue-rotate(123deg) brightness(113%) contrast(101%);
}

.filter-black {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%);
}

.bottombar {
  display: flex;
  justify-content: space-between;
}

.wbTabs {
  overflow-x: auto;
  /* border-bottom: 1px solid #E1E1E1; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04); */
}

.wbTabs .headers {
  display: flex;
  height: 54px;
  padding: 0px 65px;
  gap: 47px;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  background-color: white;
  /* margin-bottom: 10px; */
  /* border-bottom: 1px solid #E1E1E1; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04); */
}

.wbTabs .headers .header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: white;
  border-bottom: 3px solid var(--Action, white);
}

.wbTabs .headers .header.active {
  border-bottom: 3px solid var(--Action, #0094FF);
}

.wbTabs .headers .header p {
  color: var(--Text, #0E1E29);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wbTabs .headers .header.active p {
  color: var(--Text, #0E1E29);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.settingsnav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 96;
  background: var(--Text, #0E1E29);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.settingsnav .left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.settingsnav .right {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Custom alertbox */

#modalContainer {
  /* background-color: rgba(0, 0, 0, 0.3); */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 102;
  /* required by MSIE to prevent actions on lower z-index elements */
  pointer-events: none;
}

#alertBox {
  width: 50%;
  min-height: 50px;
  margin-top: 100px;
  /* margin-top: 5px; */
  justify-content: center;
  /* border: 1px solid #666; */
  background-color: #0087e7;
  /* background-position: 20px 30px; */
  left: 50%;
  right: 50%;
  border-radius: 16px;
  z-index: 103;
  pointer-events: auto;
  animation: fadeInAndOut 5s linear 1 forwards;
  box-shadow: #000 1px;
}

#modalContainer>#alertBox {
  position: absolute;
  display: flex;
  align-self: center;
  justify-content: space-evenly;
}

#alertBox h1 {
  margin: 0;
  font: bold 0.9em verdana, arial;
  background-color: #0087e7;
  color: #FFF;
  /* border-bottom: 1px solid #000; */
  padding: 2px 0 2px 5px;
}

#alertBox p {
  font-size: 1.2em;
  margin-left: 50px;
  color: #FFF;
  width: 80%;
  text-wrap: wrap;
  overflow-wrap: break-word;
  margin: 20px;
}

#alertBox #closeBtn {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 5px 50px 5px 5px;
  min-width: 50px;
  max-height: 50px;
  padding: 12px;
  border: 0 none;
  font: 1.2em verdana, arial;
  text-transform: uppercase;
  text-align: center;
  color: #FFF;
  background-color: #0087e7;
  border-radius: 16px;
  text-decoration: none;
}

#alertBox #closeBtn:hover {
  background-color: #007bd3;
}

/* unrelated styles */

#mContainer {
  position: relative;
  width: 600px;
  margin: auto;
  padding: 5px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  font: 0.7em verdana, arial;
}

#alertbox h1,
#alertbox h2 {
  margin: 0;
  padding: 4px;
  font: bold 1.5em verdana;
  border-bottom: 1px solid #000;
}

code {
  font-size: 1.2em;
  color: #069;
}

#credits {
  position: relative;
  margin: 25px auto 0px auto;
  width: 350px;
  font: 0.7em verdana;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 90px;
  padding-top: 4px;
}

#credits img {
  float: left;
  margin: 5px 10px 5px 0px;
  border: 1px solid #000000;
  width: 80px;
  height: 79px;
}

.important {
  background-color: #F5FCC8;
  padding: 2px;
}

.tooltip {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

code span {
  color: green;
}

/*# sourceMappingURL=App.css.map */

.custom-toast {
  font-size: 14px;
  min-width: 300px;
  height: 80px;
}

.custom-toast-body {
  font-size: 14px;
}


.custom-toast .Toastify__close-button {
  font-size: 1.2em;
}

.picker {
  display: flex;
  gap: 10px;
}

.categorieswarning {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  max-width: 65%;
}

.categorieswarning i {
  margin: 10px;
  min-width: 24px;
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.cat_label {
  margin-left: 0;
  color: var(--Text, #0E1E29);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.dropdownImageFrame {
  border-radius: 12px;
  border: 2px solid #ECECEC;
  box-shadow: 0px 5px 6.3px 0px rgba(227, 227, 227, 0.50);
  max-height: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdownImageFrame .selectedThumbnail {
  display: inline-flex;
  padding: 4px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: #0094FF;
  position: relative;
  margin-top: -15px;
}

.dropdownImageFrame .selectedThumbnail p {
  color: #FCFCFC;
}

.dropdownImageFrame:hover {
  position: relative;
  border: 2px solid #0094FF;
  /* scale: 1.5; */
  z-index: 10;
  background-color: white;
  /* transition: all 0.2s ease-in-out; */
}

.dropdownImage {
  object-fit: cover;
}

.dropdownImageFrame:hover .dualbutton .dropdownImage {
  object-fit: contain;
  max-height: 200px !important;
}

.margin {
  font-weight: 700;
}

.service-organisations-sticky {
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
  overflow-x: auto;
}

.service-organisations-sticky .body {
  background-color: white;
}

.drag-drop {
  border-radius: 25px;
  background: #F0F8FE;
  padding: 4px;
}

.drag-drop-file {
  border-radius: 25px;
  border: 1px dashed #0094FF;
  background: #F0F8FE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  text-align: center;
  padding: 64px;
}

.drag-drop-file .drag-drop-file-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
}

.drag-drop-file .drag-drop-file-title .uploadtitle {
  color: var(--Text, #0E1E29);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.drag-drop-file .drag-drop-file-content {
  max-width: 50%;
  color: var(--Text, #0E1E29);
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drag-drop-file-file-types-title {
  color: var(--Text, #0E1E29);
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.drag-drop-file-file-types-content {
  color: var(--Text, #0E1E29);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dragover {
  opacity: 0.4;
  background-color: #0094FF;
}

.upload-media-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.upload-media-button label {
  margin: 0 !important;
}

.uploaded-files {
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uploaded-files-title {
  border-radius: 10px;
  background: #F4F7F9;
  display: flex;
  height: 42px;
  padding: 13px 20px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.uploaded-files-title-text {
  color: var(--Text, #0E1E29);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.uploaded-files-content {
  width: 100%;
}

.uploaded-files-content-item {
  padding: 20px;
  border-bottom: 1px solid #E2E2E2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaded-files-content-item:last-child {
  border-bottom: none;
}

.uploaded-files-content-item-text {
  text-wrap: wrap;
  overflow-wrap: break-word;
  width: 70%;
}

.customselect {
  max-width: 70%;
}

.input-upload {
  display: flex;
  flex-direction: row;
}

.orgLogoInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.org-logo {
  max-width: 100px;
  max-height: 100px;
  border: 1px solid black;
  border-radius: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;
  background-color: white;
}

.orgLogoButtons {
  display: flex;
}

.deleteOrgModal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.deleteOrgHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deleteOrgSubtitle {
  color: #35393D;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.deleteOrgActive {
  width: 100%;
  padding: 20px;
  background-color: #0094FF;
}

.deleteOrgActive .warning-title {
  color: #FFF;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.deleteOrgActive .warning-content {
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deleteOrgWarning {
  width: 100%;
  padding: 0 10px;
  background-color: #FFF;
}

.deleteOrgWarning .warning-title {
  color: #FF1717;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deleteOrgWarning .warning-title strong {
  color: #FF1717;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.deleteOrgWarning .warning-content {
  color: #35393D;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.serviceLocations {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deleteOrgInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.deleteOrgInput .deleteError {
  color: red;
  font-size: 12px;
  padding-left: 15px;
}

.collector {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 250px;
}

.collector-left {
  width: 100%
}

.wbcollector-table {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 13px;
}

.wbcollector-table p {
  font-size: 14px;
}

.wbcollector-item {
  display: flex;
  justify-content: space-between;
  line-height: 44px;
  padding-inline: 20px;
  border-bottom: 1px solid #E6EDF1;
  margin-right: 20px
}

.wbcollector-item .left {
  display: flex;
  overflow-wrap: break-word;
  overflow: hidden;
}

.wbcollector-item .right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.wbcollector-item .right p,
.wbcollector-item .left p {
  line-height: 20px;
  padding: 10px
}

.wbcollector-item:last-child {
  border-bottom: none
}

.wbcollector-add {
  padding: 15px;
  border-radius: 9px;
  background: #EDF7FF;
  margin-top: 10px;
}

.wbcollector-add .wbcollector-add-newitem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wbcollector-add .wbcollector-add-newitem .row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 !important;
  padding: 0 !important;
}

.log_section.section div {
  padding-top: 10px;
}

.changelog {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: white;
  min-width: 720px;
  overflow-y: auto;
  height: 100%;
}