.trucksPage {
  display: flex;
  gap: 24px;
  height: 100vh;
  min-width: 1200px;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 532px;
  min-width: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.rightSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 600px;
}

.infoPanel {
  background: white;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  height: 100px;
}

.stockPanel {
  background: white;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  flex: 1;
}
