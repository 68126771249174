/* icons */

.iconAvatar {
  height: 34px;
  width: 34px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 1.74px 1.74px 0px #00000014;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
}

.iconLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #0e1e29;
  border-radius: 9999px;
  padding: 2.5px 2px 2.5px 16px;
  transition: background-color 0.3s ease;
}

.iconLabel:hover {
  background-color: #0097ff;
}

.labelText {
  flex-grow: 1;
  text-align: center;
}

.label {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  color: #ffffff;
}

.dateTime {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
  gap: 4px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: right;
  color: #0e1e29;
}

.iconWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.iconLabel:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #fafafa;
  color: #0e1e29;
  padding: 10px 16px;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  white-space: nowrap;
  z-index: 1000;
  left: 60px;
  transform: translateX(-50%);
  bottom: -60px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 5px 8px 0px #6e6e6e1c;
  min-width: 80px;
  text-align: center;
}

/* .tooltip::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fafafa transparent;
} */
