.container {
  padding: 36px;
}

.title {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px 0;
}

.content {
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  background-color: #fafafa;
  padding: 24px;
}
