.header {
  padding: 36px;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  font-family: robotoBold;
  font-size: 18px !important;
  line-height: 24.67px;
  text-align: left;
}

.body {
  padding: 0 36px;
}

.detailRow {
  display: flex;
  align-items: flex-start;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  padding: 24px 0;
  border-bottom: 1px solid #f2f2f2;
}

.detailLabel {
  width: 180px;
  flex-shrink: 0;
  padding-right: 10px;
}

.detailContent {
  flex: 1;
}
