.searchWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  left: 20px;
  color: #858585;
  pointer-events: none;
}

.searchWrapper input {
  flex: 1;
  width: 100%;
  height: 48px;
  padding: 0 16px 0 56px;
  color: #0e1e29;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.searchWrapper input::placeholder {
  color: #858585;
}
