.container {
  display: flex;
}

.dashDesign {
  width: 3px;
  background-color: #0094FF;
  margin-right: 6px;
  border-radius: 10px;
  flex-shrink: 0;
}

.mainBody {
  background-color: #e6f5ff;
  border-radius: 3px;
  padding: 12px 24px;
  width: 875px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.startInfo {
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.jobStarted {
  font-family: robotoBold !important;
}

.dateTime {
  /* Styles for dateTime, if any specific styles are needed */
}
