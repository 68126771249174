.jobCardPage {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 35px;
}

.subJobs {
  flex: 1;
}

.jobDetails {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.left {
  flex: 0.8;
}

.right {
  flex: 1.2;
}

.width {
  max-width: 1512px;
}
