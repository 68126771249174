.iconMap {
  height: 16px;
  width: 16px;
  background: url('../../../assets/iconMap.svg') no-repeat;
  display: flex;
}

.container {
  display: flex;
}

.dashDesign {
  width: 3px;
  margin-right: 6px;
  border-radius: 10px;
  flex-shrink: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
}

.detailsHeader,
.detailsBody {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.detailsHeader {
  background-color: #f3ebe4;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 24px;
}

.statusLabel {
  font-family: robotoBold;
}

.dateTime {
  display: flex;
  align-items: center;
}

.mapContainer {
  position: relative;
}

.map {
  border-radius: 3px;
}

.mapIcon {
  right: 20px;
  bottom: 16px;
  height: 40px;
  width: 40px;
  position: absolute !important;
  padding: 0 !important;
  cursor: pointer;
}
