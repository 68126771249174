.inventoryPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f4f7f9;
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
  padding: 12px 24px;
  gap: 24px;
}

.inventoryPage :global(.breadcrumbs) {
  margin-bottom: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 20px;
}

.headerLeft h1 {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 25.78px;
}

.headerButtons {
  display: flex;
  gap: 10px;
  align-items: center;
}
