/* icons */
.iconBriefcase {
	height: 16px;
	width: 16px;
	background: url("./assets/briefcase.svg") no-repeat;
	display: flex;
}

.invoiceSection {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 10px 12px 0px #f3f3f380;
	border-radius: 10px;
	margin-top: 16px;
}

.invoiceSection * {
	/* margin: 0;
	padding: 0; */
	box-sizing: border-box;
	font-size: 16px;
}

/* header */

.invoiceHeader {
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid #f2f2f2;
	padding: 48px 0 0 60px;
}

/* invoice */
.invoiceTable {
	/* padding: 12px 24px; */
}

.divider {
	height: 25px;
	background: #f4f7f9;
}
