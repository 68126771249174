.iconSales {
  height: 27px;
  width: 27px;
  background: url('../../assets/coin.svg') no-repeat;
  display: flex;
}

.iconJobs {
  height: 27px;
  width: 27px;
  background: url('../../assets/briefcase.svg') no-repeat;
  display: flex;
}

.chartContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.titleSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.titleSection h2 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 25.2px;
}

.viewLink {
  all: unset;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 22.4px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.maxValueContainer {
}

.maxValueText {
  font-family: robotoRegular;
  font-size: 22px;
  line-height: 30.8px;
}

.chartArea {
  min-height: 300px;
  background: #ffffff;
}

.jobsDataContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobsDataItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.totalJobs {
  font-family: robotoRegular;
  font-size: 22px;
  line-height: 30.8px;
}

.percentageValue {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 22.4px;
}

.decimal {
  font-family: robotoRegular;
  font-size: 13px;
  line-height: 18.2px;
}
