.reportsJobPageContainer {
  width: 100%;
  height: 100%;
  padding: 36px;
  box-sizing: border-box;
}

.reportsJobPageContainer .reportsHeader {
  font-family: 'robotoBold';
  color: rgba(14, 30, 41, 1);
  font-size: 22px;
}

.reportsBox {
  min-width: auto;
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  border-radius: 10px;
  margin-top: 36px;
}

.reportColumnHeader {
  font-family: 'robotoBold';
  font-size: 18px;
  color: rgba(14, 30, 41, 1);
}
.reportsColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.reportsColumn p {
  white-space: nowrap;
}

.runReportTab {
  display: flex;
  padding: 6px 12px;
  border-radius: 10px;
  background-color: rgba(246, 249, 251, 1);
  justify-content: space-between;
  align-items: center;
  width: 397px;
  height: 39px;
  margin-left: -12px;
}

.runReportButton {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 232, 239, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 8px 11px;
  height: 32px;
  cursor: pointer;
}

.reportColumnContainer {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  width: 100%;
  padding: 36px;
  gap: 410px;
}

@media screen and (max-width: 1500px) {
  .reportColumnContainer {
    gap: 130px;
  }
}

.customReportsContainer {
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
