.header {
  padding: 36px;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 24.67px;
  text-align: left;
  margin: 0;
}

.body {
  padding: 18px 42px;
}
