/* icons */
.iconCardImage {
	height: 19px;
	width: 19px;
	background: url("./assets/cardImage.svg") no-repeat;
	display: flex;
}

.iconDropDown {
	height: 10px;
	width: 10px;
	background: url("./assets/dropdownArrow.svg") no-repeat;
	display: flex;
}

.iconPlus {
	height: 34px;
	width: 34px;
	background: url("./assets/plus.svg") no-repeat;
	display: flex;
}

.addUserModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #00000026;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 98;
}

.addUserModal {
	max-width: 615px;
	background: #ffffff;
	box-shadow: -3px 0px 4px 0px #0000000a;
	width: 100%;
	max-height: 80vh;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.addUserModal * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 14px;
}

.addUserModalContainer {
	flex-grow: 1;
	overflow-y: auto;
}

/* header */

.addUserModalHeader {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #eeeeee;
}

.headerTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;
	font-size: 18px;
	border-bottom: 1px solid #e7e7e7;
	background: #ffffff;
	padding: 0 36px;
	position: sticky;
	top: 0;
	z-index: 1000;
	flex-shrink: 0;
}

.headerTitle>span {
	font-size: 24px;
	font-family: "robotoBold";
}

.closeButton {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 34px;
}

.headerContent {
	display: flex;
	align-items: center;
	padding: 36px;
	gap: 36px;
}

.headerContentImage {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 122px;
	width: 122px;
	border-radius: 50%;
	border: 1px solid #dfdfdf;
	box-shadow: 0px 4px 4px 0px #0000000d;
	background: #ffffff33;
	cursor: pointer;
}

.headerContentUpload {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.headerContentLabel {
	font-size: 16px;
	font-family: "robotoBold";
	margin-bottom: 15px;
}

.headerContentInfo {
	font-size: 12px;
	font-weight: 400;
	line-height: 14.06px;
	text-align: left;
	color: #707070;
	margin-top: 10px;
	align-self: center;
}

/* body */

.addUserModalBody {
	display: flex;
	flex-direction: column;
	gap: 36px;

	padding: 40px 36px 36px;
}

.addUserModalBody>div:last-child {
	width: 50%;
	padding-right: 8px;
}

.row {
	display: flex;
}

.rowSingleInput {
	display: flex;
	flex-direction: column;
	gap: 10px;
	/* padding: 15px 20px; */
}

.rowDoubleInput {
	gap: 18px;
}

.rowDoubleInput>div {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 10px;
}

.label {
	font-size: 16px;
	font-family: "robotoBold";
}

.input {
	border-radius: 10px;
	padding: 15px 20px;
	background: linear-gradient(360deg, #f6f6f6 0%, #f3f3f3 100%);
}

/* permissions */

.addUserModalPermissions {
	display: flex;
	flex-direction: column;
}

.addUserModalPermissions * {
	font-size: 16px;
}

.permissionsHeader {
	height: 45px;
	background: #0094ff;
	padding: 12px 36px;
}

.permissionsHeader span {
	color: #ffffff;
	font-family: "robotoBold";
}

.permissionsBody {
	background: #f8fcff;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #eaf4fb;
}

.permissionItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 36px;
}

.createJobs {
	border-top: none;
	padding-top: 18px;
}

.permissionText {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.permissionTextTitle {
	font-family: "robotoBold" !important;
}

.permissionItemWithBorder {
	border-top: 1px solid #eaf4fb;
	border-bottom: 1px solid #eaf4fb;
}

.accountingHeader {
	margin: 16px 36px 20px;
}

/* footer */
.addUserModalFooter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 36px;
}