.customCheckbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
}

.customCheckbox:first-child {
  margin-top: 20px;
}

.hiddenCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 16px;
  font-family: robotoRegular;
  line-height: 18.75px;
  text-align: left;
}

.customCheckboxIcon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  position: relative;
}

.customCheckboxIcon::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1.5px solid #171717;
  background-color: #fff;
}

.iconChecked {
  position: absolute;
  width: 12px;
  height: 10px;
  background: url('../assets/check.svg') no-repeat center / contain;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -60%);
}

.hiddenCheckbox:checked + .checkboxLabel .customCheckboxIcon::before {
  background-color: white;
}

.hiddenCheckbox:not(:checked) + .checkboxLabel .iconChecked {
  display: none;
}
