/* Modal Base Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000002b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContainer {
  max-width: 1050px;
  width: 100%;
  height: 80vh;
  background: #f7f9fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Styles */
.modalHeader {
  padding: 30px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
}

.headerTitle {
  font-family: robotoBold;
  font-size: 22px;
  line-height: 26px;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

/* Body Styles */
.modalBody {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Search Section */
.searchSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 36px 36px 42px 36px;
  border-bottom: 1px solid #e1eaf2;
}

.searchSection h3 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
}

.searchInput {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  padding: 4px 20px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
}

.searchInput input {
  width: 100%;
  height: 33px;
  border: none;
  border-radius: 10px;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 16.41px;
  padding: 0 4px;
}

.searchInput::placeholder {
  color: #f0f0f0;
}

.filterButtons {
  display: flex;
  gap: 12px;
}

/* Technician Section */
.technicianSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px 36px 60px 36px;
  border-bottom: 1px solid #e1eaf2;
}

.technicianHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.technicianHeader h3 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
}

.selectAllButton {
  color: #0e1e29;
  background: none;
  border: none;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.technicianGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 20px;
}

.technicianCard {
  display: flex;
  align-items: center;
  padding: 12px 28px;
  background: #ffffff;
  border-radius: 10px;
  gap: 12px;
  cursor: pointer;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
}

.profileImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.technicianInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.technicianName {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.technicianRole {
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  color: #0094ff;
}

/* Date Time Section */
.dateTimeSection {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  gap: 36px;
}

.dateTimeSection h3 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21.09px;
  margin-left: 36px;
}

.dateTimeContainer {
  display: flex;
}

.dateTimeLeft {
  flex: 1;
  display: flex;
  gap: 16px;
  padding: 0 36px;
  border-right: 1px solid #e2e9ef;
}
.dateTimeRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  gap: 30px;
  justify-content: space-between;
}

.calendar {
  flex: 1;
}

.inputGroupLeft {
  display: flex;
  gap: 7px;
}

.inputGroupRight {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputGroupLeft label,
.inputGroupRight label {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  margin: 0;
}

.inputGroupRight textarea {
  border-radius: 10px;
  min-height: 250px;
  resize: vertical;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.inputGroupRight textarea::placeholder {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  color: #969696;
}

/* Footer Styles */
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  background: #ffffff;
}

.footerLeft,
.footerRight {
  display: flex;
  gap: 16px;
}
