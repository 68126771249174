.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  border-top: 1px solid #f1f1f1;
  padding: 20px 40px;
}

.pageButton {
  min-width: 40px;
  height: 40px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background: #ffffff;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 16.41px;
  color: #41484e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4.6px 4.6px 0px #00000005;
}

.pageButton.active {
  border: 2px solid #0094ff;
  font-family: robotoBold;
}
