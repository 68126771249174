.schedulerContainer {
  margin: 10px;
  display: flex;
  gap: 10px;
  /* width: 100%; */
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.schedulerContent {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.sidebarContainer {
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.sidebarContainerInner {
  width: 300px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.sidebarHeader {
  padding: 20px;
  display: flex;
  gap: 75px;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: rgba(14, 30, 41, 1);
  border-bottom: 1px solid rgba(231, 231, 231, 1);
}

.sidebarHeaderTop {
  border-top: 1px solid rgba(231, 231, 231, 1);
}

.menuBar {
  width: 99.9%;
  height: 56px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.schedulerButton,
.schedulerButtonWide {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(216, 216, 216, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.schedulerButtonWide {
  padding: 10.5px 24px;
  width: auto;
}

.menuBar {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.menuBarLeft,
.menuBarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.menuBarDate {
  font-size: 14px;
  margin-left: 7px;
}

.iconTextContainer {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.jobListContainer {
  padding: 20px;
}

.jobList {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(223, 223, 223, 1);
}

.jobListContainer p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 17px;
}

.jobList p {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0px;
}

.jobItem {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  cursor: move;
  user-select: none;
}



.jobLine {
  width: 6px;
  height: 1px;
  background-color: rgba(223, 223, 223, 1);
  margin-right: 13px;
}

.calendarContainer {
  width: 100%;
  /* overflow-x: auto; */
  height: calc(100vh - 56px - 67px - 120px);
}

/* Dropdown */



.dropdownButton {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.leftSection {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: white;
  padding: 0 24px;
  gap: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 10px 0 0 10px;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 12px;
  background-color: #44c989;
}

.actionText {
  color: #0e1e29;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.chevronIcon {
  color: white;
  width: 12px;
  height: 16px;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  margin-top: 8px;
  width: fit-content;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  z-index: 50;
  overflow: hidden;
  top: 50px;
}

.dropdownItem {
  width: 100%;
  text-align: left;
  padding: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: #0e1e29;
  font-size: 15px;
  white-space: nowrap;
}

.withIcon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.workspaceContainer {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 13px;
  max-height: 155px;
  min-height: 155px;
  border-radius: 10px;
  overflow: auto;
  min-width: 1100px;
}

.workspaceHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid rgba(239, 239, 239, 1);
  padding: 21px 24px;
}

.workspaceHeader p {
  font-family: 'robotoBold';
}

.workspaceContent {
  min-height: 100px;
  height: calc(100% - 70px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.2s;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  padding: 21px;
}

.workspaceDragOver {
  background-color: rgba(0, 148, 255, 0.1);
}

.workspaceItem {
  background: white;
  padding: 12px 11px;
  margin-bottom: 8px;
  box-shadow: 0px 4px 4px 0px rgba(184, 184, 184, 0.1);
  width: calc(20% - 10px);
  min-width: 210px;
  height: 57px;
  border: 1px solid rgba(230, 241, 252, 1);
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: move;
}

.workspaceRegular {
  font-size: 11px;
  font-family: 'robotoRegular';
}

.workspaceBold {
  font-size: 11px;
  font-family: 'robotoBold';
}

.flexMinutes {
  display: flex;
  justify-content: space-between;
}

.remindersFlex {
  display: flex;
  gap: 4px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  cursor: pointer;
}

.dropdownActions {
  padding: 10.5px 24px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid rgba(220, 220, 220, 1);
  box-shadow: 0px 4.6px 4.6px 0px rgba(0, 0, 0, 0.04);
  border-right: none;
  background-color: white;
}

.dropdownArrow {
  background-color: rgba(68, 201, 137, 1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 12px 11.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownContent {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 100%;
}

.dropdownItem {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

.dropdownItem:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdownItem:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdownText {
  white-space: nowrap;
  font-size: 12px;
}

.singleDropdownButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  width: 100%;
}

.singleDropdownButton:hover {
  background: #f5f5f5;
}

.chevronIcon {
  width: 10px;
  height: 10px;
}

.jobTime {
  white-space: nowrap;
  font-size: 12px;
  color: #ff7300;
  display: flex;
  justify-content: end;

}


/* SCHEDULER LEFT SIDE BAR */



.jobLocation {
  font-family: robotoBold;
  display: flex;
  justify-content: space-between;
}

.jobDetailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 10px;
  border-bottom: 1px solid #efefef;
  padding: 16px;
  margin: 8px 0;
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid transparent;
  /* border-radius: 10px; */
  transition: border 0.2s ease-in, box-shadow 0.2s ease-in;
}

.jobDetailsContainer:hover {
  /* border-radius: 10px; */
  border: 1px solid #525a60;
  /* box-shadow: 0px 1px 13px #f1f1f1; */
}

.jobDetailsWrapper:hover .jobDetailsContainer {
  /* border-radius: 10px; */
  /* border: 1px solid #e7e7e7; */
  /* box-shadow: 0px 1px 13px #f1f1f1; */
}

.jobTags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.jobSideDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.jobDetailsWrapper {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex: 1;
}


.tag {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 12px;
}