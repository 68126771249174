.header {
  padding: 36px;
}

.title {
  font-family: robotoBold;
  font-size: 18px !important;
  line-height: 24.67px;
  text-align: left;
}

.body {
  padding: 0 36px;
}

.saleNumber {
  display: flex;
  gap: 18px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  padding: 24px 0;
}

.tableHeader,
.tableRow,
.summaryRow {
  display: flex;
  align-items: flex-start;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.tableHeader {
  padding: 12px 30px;
  background-color: #f4f7f9;
  border-radius: 10px;
}

.tableHeader > span,
.summaryRow > .detailLabel {
  font-family: robotoBold;
}

.detailLabel {
  flex-shrink: 0;
  padding-right: 10px;
}

.detailContent {
  flex: 1;
  text-align: right;
}

.tableRow {
  border-bottom: 1px solid #f2f2f2;
  padding: 18px 30px;
}

.summary {
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.summaryRow {
  justify-content: flex-end;
}

.summaryRow .detailLabel {
  width: auto;
  margin-right: 20px;
}

.summaryRow .detailContent {
  width: 100px;
  flex: none;
}
