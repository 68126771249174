.runReportsPageContainer {
  padding: 36px;
  display: flex;
  gap: 19px;
  width: 100%;
  min-width: fit-content;
  overflow-x: auto;
}

.reportsTitle {
  font-family: 'robotoBold';
  color: rgba(14, 30, 41, 1);
  font-size: 22px;
  margin-bottom: 7px;
}

.sidebarBox {
  height: auto;
  width: 288px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.boldSidebar {
  font-family: 'robotoBold';
  color: rgba(14, 30, 41, 1);
  font-size: 16px;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.bodyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headerContainer {
  height: auto;
  width: 100%;
  min-width: auto;
  padding: 24px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 10px;
}
.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 45px;
  width: auto;
  border-radius: 10px;
  border: 1px solid rgba(216, 216, 216, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  padding: 13px 20px;
  cursor: pointer;
}

.graphContainer {
  height: auto;
  min-height: 434px;
  width: 100%;
  min-width: auto;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  padding: 36px;
}

.graphTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.graphTitleRight {
  display: flex;
  align-items: center;
  gap: 24px;
}

.tableContainer {
  width: 100%;
  min-width: auto;
  height: auto;
  min-height: 881px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  padding: 36px;
  overflow-x: auto;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1143px;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 10px;
  padding: 15px 20px;
}

input.searchInput::placeholder {
  color: rgba(133, 133, 133, 1);
  font-size: 16px;
}

input.searchInput:focus {
  border: none;
}

.searchInput {
  width: 300;
  border: none;
  height: 18px;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterContainer {
  margin-top: 14px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.filterItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 40px;
  background-color: rgba(241, 247, 251, 1);
  padding: 7px 21px;
  border-radius: 7px;
}

.filterItem:last-child {
  padding: 7px 16px;
}

.filterBold {
  font-family: 'robotoBold';
  color: rgba(14, 30, 41, 1);
  font-size: 16px;
}

.reportsTable {
  width: 100%;
  margin-top: 36px;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  min-width: 800px;
}

.reportsTable thead {
  background-color: rgba(244, 247, 249, 1);
  height: 42px;
  border-radius: 10px;
  border-bottom: none;
}

.reportsTable thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.reportsTable thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.reportsTable thead tr {
  border-bottom: none;
}

.reportsTable tbody tr {
  height: 54px;
  border-bottom: 1px solid rgba(243, 243, 243, 1);
}

.reportsTable td {
  text-align: center;
}

.pageNumberContainer {
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid rgba(241, 241, 241, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.pageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(242, 242, 242, 1);
  padding: 12px;
  box-shadow: 0px 9px 10.8px 0px rgba(243, 243, 243, 0.5);
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: rgba(247, 249, 251, 1);
  border-radius: 10px;
  width: 691px;
  height: auto;
  max-height: 80vh;
  min-width: 691px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modalHeader p {
  font-family: 'robotoBold';
  color: black;
  font-size: 22px;
}

.closeBtn {
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modalBody {
  padding: 36px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputGroup p {
  font-family: 'robotoBold';
  color: black;
  font-size: 16px;
}

.inputGroup input {
  height: 49px;
  padding: 15px 24px;
  color: rgba(14, 30, 41, 1);
  border: 1px solid rgba(240, 240, 240, 1);
  font-size: 16px;
}

.inputGroup input::placeholder {
  font-size: 16px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
}

.saveButton {
  padding: 8px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.cancelButton {
  cursor: pointer;
}

.saveButton {
  background: #007aff;
  color: white;
  border: none;
}
