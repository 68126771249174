.circularProgress {
  transform: rotate(-90deg);
}

.circleBackground {
  fill: none;
  stroke: #e5e7eb;
}

.circleProgress {
  fill: none;
  stroke: rgba(25, 204, 118, 1);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease;
} 