.underlineTab {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
}

.tab {
	bottom: -2px;
	margin: 0 24px !important;
	padding-bottom: 10px;
}

.selected {
	border-bottom: 2px solid #0094ff;
	border-radius: 0px;
	position: absolute;
	font-family: robotoBold;
}

.badgeStyle {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 4px;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: #f1f1f1;

	font-size: 12px !important;
	font-family: robotoBold;
	line-height: 14.06px;
	/* margin-bottom: 4px; */
}
