.tableContainer {
  background: #ffffff;
  border-radius: 10px;
}

.tableHeader {
  padding: 24px 24px 20px;
}

.tableHeader h2 {
  font-family: robotoBold;
  font-size: 18px;
  line-height: 21px;
  color: #0e1e29;
  margin: 0;
}

.tableWrapper {
  padding: 0px 24px 24px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  padding: 12px 20px;
  background: #f4f7f9;
  text-align: left;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  color: #0e1e29;
}

.table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 75px;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(4),
.table th:nth-child(5) {
  width: 187.5px;
}

.table th:nth-child(4),
.table th:nth-child(5),
.table td:nth-child(4),
.table td:nth-child(5) {
  text-align: center;
}

.table td {
  padding: 16px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  color: #0e1e29;
}

.status {
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
}

.lowStock {
  background: #fff4ed;
  color: #ff7e1b;
}

.awaiting {
  background: #edf6ff;
  color: #0085ff;
}
