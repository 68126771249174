/* icons */
.iconChevronRight {
	height: 16px;
	width: 16px;
	background: url("./assets/chevronRight.svg") no-repeat;
	display: flex;
}

/* InvoiceTable.module.css */
.invoiceTable {
	width: 100%;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.itemsTable,
.summaryTable,
.receiptTable {
	/* padding: 12px 24px; */
	width: 100%;
	margin-bottom: 20px;
	/* border-collapse: separate; */
	border-spacing: 0;
	margin-top: 10px;
}

.itemsTable thead,
.receiptTable thead {
	background: #f4f7f9;
}

.itemsTable th,
.receiptTable th {
	padding: 10px 24px;
	text-align: left;
	border: none;
}

/* items header sizes */

.space {
	width: 0%;
}

.codeColumn {
	width: 10%;
}

.titleColumn {
	width: 25%;
}

.descriptionColumn {
	width: 27%;
	padding-right: 24px;
}

.itemsTitleContainer {
	gap: 4px;
}

.itemsTitle {
	font-size: 16px;
	font-family: robotoBold;
}

.breadcrumb {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	color: #666;
	margin-top: 4px;
}

.itemsTable th:first-child,
.receiptTable th:first-child {
	background-color: #ffffff;
}

.itemsTable th:last-child,
.receiptTable th:last-child {
	background-color: #ffffff;
}

.itemsTable th:nth-child(2),
.receiptTable th:nth-child(2) {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.itemsTable th:nth-last-child(2),
.receiptTable th:nth-last-child(2) {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.itemsTable td,
.receiptTable td {
	padding: 10px 24px;
	border-bottom: 1px solid #f3f3f3;
}

.itemsTable tr,
.receiptTable tr {
	/* border-bottom: 1px solid #f3f3f3; */
}

.itemsTable tr:last-child,
.receiptTable tr:last-child {
	border-bottom: none;
}

.quantityColumn {
	width: 5%;
	text-align: center;
  }
  
  .quantity {
	text-align: center;
  }

.price {
	width: 8%;
	text-align: right !important;
}

/* receipt header sizes */

.receiptImageColumn {
	width: 15%;
}

.dateColumn {
	width: 15%;
}

.technicianColumn {
	width: 10%;
}

.receiptDescriptionColumn {
	width: 15%;
}

.terminalIdColumn {
	width: 15%;
}

.paymentTypeColumn {
	width: 35%;
}

.price {
	width: 10%;
	text-align: right;
}

.receiptImage {
	width: 40px;
	height: 40px;
	object-fit: cover;
}

.summaryTable {
	border-collapse: collapse;
	margin-bottom: 85px;
	display: flex;
	justify-content: flex-end;
}

.summaryRow {
	border-bottom: 1px solid #f3f3f3;
}

.summaryRow td {
	padding: 12px 24px 12px 24px;
}

.summaryLabel {
	font-family: robotoBold;
	text-align: left;
}
