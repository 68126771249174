section,
.leftSection,
.tags,
.rightSection {
	display: flex;
}

section {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e4e4e4;
	background-color: white;
	height: 65px;
	top: 70px;
	gap: 0px;
	/* opacity: 0px; */
	padding: 0 48px 0 36px;
}

.leftSection {
	gap: 20px;
}

.customerName {
	font-size: 16px;
	font-weight: 700;
	line-height: 18.75px;
	text-align: left;
	text-decoration: underline;
	cursor: pointer;
	color: rgba(0, 0, 0, 1);
}

.tags {
	gap: 6px;
	align-items: center;
}

.vip {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 43px;
	height: 23px;

	font-size: 12px;
	font-weight: 700;
	line-height: 14.52px;
	text-align: left;

	font-weight: 700;
	background: #e1f3eb;
	border-radius: 25px;
}

.vipText {
	color: #19cc76;
}

.financeApproved {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 23px;

	font-size: 12px;
	line-height: 14.52px;
	text-align: left;

	font-weight: 700;
	background: #e1f3eb;
	border-radius: 25px;
	background: #fefbf0;
	color: #9e7b00;
}

.google {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 91px;
	height: 23px;

	font-size: 12px;
	line-height: 14.52px;
	text-align: left;

	font-weight: 400;
	background: #e1f3eb;
	border-radius: 25px;
	background: #ffffff;

	border: 1px solid #dce3e9;
}

.google img {
	height: 13px;
	width: 13px;
}

.rightSection {
	gap: 10px;
}

.jobButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 144px;
	gap: 6.09px;
	border-radius: 10px;
	/* opacity: 0px; */

	cursor: pointer;
	background-color: rgba(14, 30, 41, 1);
	font-weight: 700;
	font-size: 16px;
}

.jobButton a {
	text-decoration: none;
	color: white;
}

.actionsButton {
	height: 40px;
	display: inline-flex;
	align-items: center;
	border-radius: 10px;
	text-decoration: none;
	font-weight: bold;
	overflow: hidden;
	box-shadow: 0px 4.6px 4.6px 0px #00000008;
	font-size: 16px;
}

.actionsButton .text {
	padding: 13px 20px;
	background-color: rgba(25, 204, 118, 1);
	color: white;
}

.actionsButton .arrow {
	width: 40px;
	height: 40px;
	background-color: rgba(23, 182, 105, 1);
	display: inline-flex;
	align-items: center;
	justify-content: center;
}