.complaintsTab {
  display: flex;
  flex-direction: column;
}

.complaintsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  border-bottom: 1px solid #f2f2f2;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.headerRight {
}

.complaintTitle {
  font-family: robotoRegular;
  font-size: 18px !important;
  line-height: 21.09px;
  text-align: left;
}

.complaintNumber {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.complaintsStatus {
  display: flex;
  align-items: center;
  padding: 16px 40px;
  gap: 70px;
  border-bottom: 1px solid #f2f2f2;
}

.statusText {
  font-family: robotoBold;
  color: #ff7a00;
  background: #fff9e3;
  border-radius: 25px;
  padding: 4px 12px;
  display: inline-block;
}

.statusDate,
.statusLog {
  display: flex;
  flex-direction: column;
}

.statusDateTitle,
.statusLogTitle {
  font-family: robotoBold;
}

.statusLogValue {
  text-decoration: underline;
}

.complaintsBody {
  padding: 16px 24px;
}

.descriptionTitle {
  font-family: robotoBold;
  background: #f4f7f9;
  border-radius: 10px;
  padding: 12px 24px;
}

.descriptionText {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 21.93px;
  text-align: left;
  padding: 16px 30px;
}
