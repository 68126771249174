/* icons */
.iconDownArrow {
	height: 6px;
	width: 12px;
	background: url("./assets/downArrow.svg") no-repeat;
	display: flex;
}

.jobCardHeader {
	display: flex;
	justify-content: space-between;
	background: #ffffff;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	width: 100%;
}

.jobCardHeader * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

/* left side */

.jobCardHeader-left {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 10px;
	padding-left: 48px;
	position: relative;
}

/* right side */

.jobCardHeader-right {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	padding: 10px 32px 10px 0;
}
