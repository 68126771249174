* {
  color: rgba(14, 30, 41, 1);
}

.contactNotes,
.contactSection,
.notesSection,
.contactContainer,
.contactContainerDivision,
.customerInfo,
.notesContainer {
  display: flex;
}

.contactNotes {
  gap: 21px;
  align-items: flex-end;
  margin: 36px;
}

.contactContainer {
  flex-direction: column;
  box-shadow: 0px 9px 10.8px 0px #f3f3f338;
}

.contactContainer h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.contactContainerDivision {
  margin-top: 16px;
  height: 320px;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.contactSection {
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  border-right: 1px solid #f6f6f6;
}

.contactSection .tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background: white;
  width: 243px;
  height: 73px;
  gap: 0px;
  border-radius: 13px;
  padding: 18px 24px;
  cursor: pointer;
  color: black;
}

.contactSection .tab:hover {
  background-color: #f9f9f9;
}

.contactSection .contactSectionSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #0094ff;
  box-shadow: 0px 4px 5.1px 0px #f5f5f5;
}

.chevronIcon {
  width: 32px;
  height: 32px;
  background-color: #f4f4f4;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chevronImg {
  height: 10px;
  width: 10px;
}

.contactSection > :nth-child(4) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 0.61px solid #bcbdbd;
  width: 243px;
  height: 40px;
  padding: 12px 17px;
  gap: 6.09px;
  border-radius: 10px;
}

.contactSection > :nth-child(4) a {
  color: var(--font-action-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
}

.contactSection .tab h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
}

.contactSection .tab span {
  font-family: var(--font-family-inter);
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
}

.customerInfo {
  width: 705px;
  flex-direction: column;
  padding: 36px 24px;
}

.customerInfo .location {
  display: flex;
  align-items: center;
  gap: 4px;
}

.customerInfo .locationIcon {
  width: 16px;
  height: 16px;
}

.customerInfo .locationButton {
  height: 28px;
  padding: 4.87px 12.17px;
  gap: 6.09px;
  border-radius: 7px;
  border: 0.61px solid #bcbdbd;
  box-shadow: 0px 2.43px 2.43px 0px #00000008;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-left: 15px;
}

.customerInfo h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
}

.customerInfo span {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.customerData {
  display: flex;
  flex-direction: column;
}

.text18 {
  font-size: 18px;
}

.text16 {
  font-size: 16px;
}

.customerContactInfo {
  flex-direction: column;
}

.customerContactInfo > div {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.customerContactInfo > div > img {
  height: 16px;
  width: 16px;
}

.customerContactData {
  display: flex;
  flex-direction: row !important;
  margin-top: 48px;
  gap: 72px;
}

.customerPaymentTerms {
  margin-top: 14px;
}

.customerBillingAddress {
  margin-top: 21px;
}

.floatingButton a {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.87px 12.17px;
  gap: 6.09px;
  border-radius: 10p;
  text-decoration: none;
  border: 0.61px solid #d8d8d8;
  box-shadow: 0px 2.43px 2.43px 0px #00000008;
}

.floatingButton img {
  width: 16px;
  height: 16px;
}

/* Notes Section */

.notesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.notesContainer h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.notesSection {
  height: 320px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 9px 10.8px 0px #f3f3f338;
  margin-top: 16px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 24px;
}

.notesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.notesButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6.09px;
  padding: 0 24px;
  border: 0.61px solid #d8d8d8;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0px 2.43px 2.43px 0px #00000008;
  background: white;
}

.notesButton img {
  height: 16px;
  width: 16px;
}

.notesButton a {
  color: #0e1e29;
}

.notesHeader a {
  text-decoration: none;
}

.notesSection h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
}

.notesSection span {
  color: #86949e;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  align-self: flex-start;
}

.notesSection p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  margin: 16px 0;
}

.notesSection a {
  color: var(--font-action-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  align-self: flex-start;
  padding: 10px 30px;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 3.8px 0px #ececec80;
  border-radius: 10px;
  margin-top: 16px;
  text-decoration: none;
}

.notesButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addNotesButton,
.viewNotesButton,
.addNotesModalButton,
.viewNotesModalButton {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 3.8px 0px #ececec80;
  border-radius: 10px;
  width: 137px;
  height: 40px;
  margin-top: 16px;
  gap: 6px;
}

.viewNotesButton {
  width: 150px;
}

.addNotesButton {
  width: 137px;
}

.addNotesButton:hover,
.viewNotesButton:hover,
.addNotesModalButton:hover,
.viewNotesModalButton:hover {
  cursor: pointer;
}
.fadeContainer {
  position: relative;
  max-height: 65%;
  overflow: hidden;
}

.fadeContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* Modal */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContentViewNotes,
.modalContentAddNotes,
.modalContentViewAddNotes {
  background-color: white;
  padding: 35px;
  border-radius: 8px;
  width: 643px;
  height: 595px;
  position: relative;
  z-index: 1000;
  overflow-y: hidden;
}

.modalContentAddNotes {
  height: 442px;
}

.modalContentViewAddNotes {
  height: 866px;
}

.modalNotesContainer {
  border-bottom: 1px solid rgba(231, 231, 231, 1);
  padding-bottom: 35px;
  margin: 0px -35px 0px -35px;
  padding: 0px 35px 35px 35px;
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 35px;
  margin: 0px -35px 0px -35px;
  padding: 0px 35px 35px 35px;
  border-bottom: 1px solid rgba(231, 231, 231, 1);
}

.modalSubheader {
  margin-top: 35px;
}
.modalSubheader h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  color: black;
}

.modalHeader h2 {
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.closeBtn {
  font-size: 24px;
  cursor: pointer;
}

.modalNotes {
  padding-top: 35px;
}

.modalNotes h4 {
  font-size: 14px;
  font-weight: 400px;
  line-height: 16.41px;
  color: rgba(14, 30, 41, 1);
}

.modalNotes span {
  color: rgba(134, 148, 158, 1);
  line-height: 14.06px;
}

.modalNotes p {
  color: rgba(14, 30, 41, 1);
  font-size: 16px;
  line-height: 25.6px;
}

.viewNotesModalButton {
  border-top: 1px solid rgba(231, 231, 231, 1);
  margin-top: 35px;
}

.modalTextareaContainer,
.modalTextareaViewAddContainer {
  height: 218px;
  margin-top: 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modalTextareaViewAddContainer {
  margin-top: 16px;
}

.modalTextarea {
  flex-grow: 1;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  resize: none;
  padding: 24px;
}

.addNotesButtonContainer {
  position: absolute;
  bottom: 115px;
  left: 59px;
  display: flex;
  gap: 6px;
}

.addNotesModalButtonFile,
.addNotesModalButtonImage {
  height: 34px;
  border-radius: 6px;
  border: 1px solid rgba(223, 223, 223, 1);
  padding: 8px 20px 8px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 88.25px;
  font-weight: 700;
  font-size: 15px;
  line-height: 17.58px;
}

.addNotesModalButtonFile:hover,
.addNotesModalButtonImage:hover,
.addNotesSubmissionButton:hover {
  cursor: pointer;
}

.addNotesModalButtonImage {
  width: 110px;
}

.addNotesSubmissionContainer {
  display: flex;
  justify-content: space-between;
}
.addNotesSubmissionButton {
  height: 40px;
  padding: 12px 24px 12px 24px;
  border-radius: 10px;
  gap: 6.09px;
  background-color: rgba(255, 255, 255, 1);
  border: 0.61px solid rgba(216, 216, 216, 1);
  box-shadow: 0px 2.43px 2.43px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
