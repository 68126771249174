.container {
  display: flex;
}

.dashDesign {
  width: 3px;
  background-color: #eeeeee;
  margin-right: 6px;
  border-radius: 10px;
  flex-shrink: 0;
}

.mainBody {
  background-color: #eeeeee;
  border-radius: 3px;
  padding: 12px 24px;
  width: 875px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.bookingInfo {
  font-size: 16px;
  line-height: 18.75px;
  text-align: left;
}

.jobBooked {
  font-family: robotoBold !important;
}
