.iconSend {
  height: 16px;
  width: 16px;
  background: url('../../../../../public/images/scheduler/sms-send.svg') no-repeat;
  display: flex;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  pointer-events: auto;
  background: transparent;
}

.modalContainer {
  position: absolute;
  background: white;
  border-radius: 18px;
  width: 100%;
  max-width: 340px;
  height: 100%;
  max-height: 680px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 4px 18.7px 0px #00000014;
  transition: max-width 0.25s ease;
  transform-origin: center;
  transform: scale(1);
}

.modalHeader {
  background: #0e1e29;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.profileHeader {
  display: flex;
  padding: 16px 38px 20px 16px;
  width: 100%;
  gap: 16px;
  align-items: center;
}

.profileImageContainer {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #0000000f;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.nameSection {
  display: flex;
  justify-content: space-between;
}

.name {
  color: #ffffff;
  margin: 0;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.role {
  color: #ffffff;
  margin: 0;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
}

.viewProfile {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 12px;
  line-height: 14.06px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 24px;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 16.41px;
  color: #ffffff;
}

.communicationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  border-bottom: 1px solid #eee;
}

.communicationHeader .profileHeader {
  padding: 8px 8px 12px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.communicationHeader .profileDetails {
  flex: 1;
}

.communicationHeader .name,
.communicationHeader .role {
  color: #0e1e29;
}

.communicationHeader .viewProfile {
  color: #0e1e29;
  background: none;
  border: none;
  color: #0e1e29;
  cursor: pointer;
  font-family: robotoRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
  align-self: center;
}

.backButton,
.expandButton {
  background: none;
  border: none;
  cursor: pointer;
}

.communicationTitle {
  font-family: robotoBold;
  font-size: 16px;
  margin: 0;
}

.messageContainer {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dateLabel {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin: 8px 0;
}

.messagesWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputSection {
  padding: 16px 24px;
}

.inputSectionExpanded {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}

.inputSectionExpanded:focus-within {
  border: 1px solid #0094ff;
}

.textareaWrapper {
  padding-bottom: 52px;
}

.sendButtonWrapper {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.messageInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  resize: none;
  font-family: robotoRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  transition: min-height 0.2s ease-in-out;
}

.messageInput:focus {
  outline: none;
  border: 1px solid #0094ff;
}

.messageInputCollapsed {
  min-height: 40px;
  max-height: 120px;
}

.messageInputExpanded {
  padding: 16px 16px 0px 16px;
  min-height: auto;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  border: none;
}

.messageInputExpanded:focus {
  outline: none;
  border: none;
}

.modalFooter {
  padding: 0 24px 20px 24px;
  display: flex;
  justify-content: space-between;
}

.modalContainer.expanded {
  max-width: 880px;
}

.expandedHeader {
  background: #0e1e29;
  padding: 36px 30px 36px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandedTitle {
  color: #ffffff;
  margin: 0;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.mainContent {
  display: flex;
  height: calc(100% - 60px);
}

.chatList {
  min-width: 283px;
  border-right: 1px solid #eeeeee;
  overflow-y: auto;
  background: #ffffff;
  transition: opacity 0.2s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 16px;
}

.chatListContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 32px 40px;
}

.expanded .chatList {
  opacity: 1;
}

.chatItem {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 12px;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;
  width: 100%;
}

.chatItem:hover {
  border: 2px solid #ebebeb;
}

.chatItemImage {
  flex-shrink: 0;
  position: relative;
  width: 53px;
  height: 53px;
  box-shadow: 0px 4px 4px 0px #0000000f;
  border-radius: 50%;
}

.chatItemImage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.unreadDot {
  position: absolute;
  top: 24px;
  left: -36px;
  width: 9px;
  height: 9px;
  background: #ff7a00;
  border-radius: 50%;
  pointer-events: none;
}

.chatItemInfo {
  flex: 1;
  min-width: 0;
}

.chatItemName {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatItemRole {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  margin: 0;
}

.messagesSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bottomSection {
  position: sticky;
  bottom: 0;
  background: white;
}

.modalContainer.closing {
  transform: scale(0.95);
  opacity: 0;
}

.modalContainer.expanded .bottomSection {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.modalContainer.expanded .inputSection {
  flex: 1;
  padding: 0;
}

.selectedChat {
  border: 2px solid #0094ff !important;
}
