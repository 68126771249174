.dashboardContainer {
  padding: 36px;
  background: #f7f9fb;
  min-height: 100vh;
}

.bottomSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 24px;
}

.smallChartsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.chartCard {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.chartCard h3 {
  font-family: robotoBold;
  font-size: 16px;
  margin-bottom: 16px;
}

.metricsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}
