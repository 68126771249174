.chartWrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  gap: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.chartWrapper h3 {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 22.4px;
}

.chartContainerWrapper {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.chartContainer {
  height: 100%;
  width: 150px;
}

.legendContainer {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: robotoRegular;
  font-size: 14px;
  line-height: 19.6px;
}

.legendDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
