.timePicker {
  width: 100%;
  box-shadow: 0px 4px 6px 0px #00000000;
  padding: 16px 18px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  color: #0e1e29;
  text-align: center;
  margin-bottom: 24px;
}

.timeList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.timeButton {
  width: 100%;
  border-radius: 9999px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  padding: 6.5px 24px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  border: 1px solid #d0e6f5;
}

.timeButton:hover:not(.selected) {
  background: #f5f5f5;
}

.selected {
  color: white;
  background: #0094ff;
  border-color: #0094ff;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

@media (max-width: 400px) {
  .timePicker {
    max-width: 100%;
  }

  .timeButton {
    padding: 10px;
    font-size: 16px;
  }
}
