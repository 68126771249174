@font-face {
  font-family: "robotoRegular";
  src: url("/public/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "robotoBold";
  src: url("../public/fonts/Roboto-Bold.ttf");
}

* {
  font-family: robotoRegular;
  color: #32404A;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #F8F8F8;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar:horizontal {
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #F8F8F8;
}

*::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
}


body {
  background-color: #F7F9FB;
  margin: 0;

  &>nav{
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 999;
      box-shadow: 1px -1px 5px 0px #eee;
  }
}

button{
  position: relative !important;
  overflow: hidden;
  transition: background 400ms !important;
}

span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(0, 0, 0, 0.178);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


.hide{
  display: none !important;
}

/* // fonts */
.bold {
  font-family: RobotoBold;
}

.not-bold{
  font-family: RobotoRegular;
  font-weight: normal;
}

.c-0094FF{
  color: #0094FF;
}

.font-size-14{
  font-size: 14px;
}

/* // heights */

.max-height-350{
  max-height: 350px !important;
}
.h-full{
  height: 100%;
}
/* // fonts  */
.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}

.longDesc{
  text-wrap: wrap;
  line-height: 22px;
}

/* // tables */

.table{
  position: relative;
  width: 100%;
  height: auto;

 
  
  .tableHeadInfo{
      display: flex;
      justify-content: space-between;
      padding-left: 20px;

      #pageInfo {
          align-items: center;
          display: flex;
      }
  }
  
  .pageCount{
      display: flex;
      justify-content: space-between;
      gap: 20px;
  }

  
  .pagination {
      display: flex;
      justify-content: end;
      margin-bottom: 10px;
      gap: 10px;
      margin-top: 20px;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      
      .active{
          box-shadow: inset 0 0 0px 2px #0094FF;
      }
  }

  
  .table-container {
      width: 100%;
      padding-bottom: 160px;
      overflow-x: auto;
      overflow-y: auto;
      display: flow;
      

      &.h-custom1{
          height: calc(100vh - 406px);
      }


      &::-webkit-scrollbar:horizontal {
          height: 20px;
          position: fixed !important;
          bottom: 0;
      }

      .image_row{
          width: 50px;
      }

      .status{
          
          border-radius: 50%;
          height: 8px;
          width: 8px;
          display: flex;
          position: absolute;
          left: 0;
          top: 30px;
          
          &.active{
              background-color: #44C989;
          }
          &.not-active{
              background-color: #e2e2e2;
          }
      }

      .nowrap{
          white-space: nowrap;
      }

      .wrap{
          white-space: wrap;
      }

      .header{
          display: table-header-group;
          position: sticky;
          top: 0;
          background-color: #fff;
          z-index: 1;

          .cell{
              font-family: RobotoBold;
          }

          .cell:nth-last-child(-n+2) {
              text-align: right;
          }
      }

      .cell {
          padding: 10px 20px;
          display: table-cell;
          align-items: center;
          border-bottom: 1px solid #F1F1F1;
          /* @extend .nowrap; */
          width: 100%;
      }
  
      .body {
          display: table-row-group;
          flex-direction: column;
          margin: 0;
          padding: 0;
          
          .no_data{
              padding: 20px;
          }

          .row {
              gap: 0;
              padding: 0;
              display: table-row;

              .cell{

                  vertical-align: middle;

                  .profile_image{
                      border-radius: 50%;
                      max-width: 34px;
                      height: auto;
                      top: 19px;
                      position: absolute;
                  }

                  .categories{
                      display: flex;
                      flex-direction: column;

                      div {
                          display: flex;
                          align-items: center;
                      }

                      div:nth-child(2){
                          padding-left: 10px;
                      }

                      div:nth-child(3){
                          padding-left: 25px;
                      }

                  }
                  

              }

              .cell:nth-last-child(-n+2) {
                  text-align: right;
                  justify-content: end;

                  &>div{
                      justify-content: end;
                  }
              }
          }

          
      }
  
      .row {
          border-bottom: 1px solid #F1F1F1;

          &:hover{
              background-color: #F3FAFF;
              .option{
                  .dot-icon{
                      background-color: #5E7E95;
                  }
              }

              .cell{
                  background-color: #F3FAFF;
              }

          }
      }
      
      .static {
          background-color: #FCFCFC;
          min-width: 73px;
          width: 100%;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          max-width: 200px;
          position: sticky;
          left: 0px;
          white-space: nowrap;
      }

      .static1 {
          background-color: #FCFCFC;
          min-width: 73px;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          max-width: 200px;
          position: sticky;
          left: 0px;
          white-space: nowrap;
      }

      .static2 {
          background-color: #FCFCFC;
          min-width: 100px;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          width: 100%;
          position: sticky;
          left: 73px;
          white-space: nowrap;
      }

      .serviceTitle {
          background-color: #FCFCFC;
          min-width: 600px;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          position: sticky;
          left: 73px;
          white-space: nowrap;
      }

      .staticAuto {
          width: 0;
          background-color: #FCFCFC;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          position: sticky;
          left: 0px;
          white-space: nowrap;
      }

      .auto {
          width: 100%;
          background-color: #FCFCFC;
          line-height: 50px;
          box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.03);
          left: 0px;
          white-space: nowrap;
      }
  
      .head {
          display: inline-table;
          border-bottom: 1px solid #F1F1F1;
  
          .cell {
              font-family: RobotoBold;
          }
      }
  }


}


/* // gaps{ */
.gap-10 {
  gap: 10px;
}

.gap-30 {
  gap: 30px;
}


/* //text colors */
.text-green {
  color: #15c36f;
}

.text-grey {
  color: #bcbcbc;
}

/* // dispplays */
.flex {
  display: flex;
}

.flex-column{
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
  align-items: center;
}

.just {
  display: flex;
}

/* // ---colors--- */
.color1{color: #324552;}

/* // ---backgrounds--- */
.bg-light-grey{background-color: #f0f0f0;}
.bg-light-blue{background-color: #EDF7FF;}

/* // ---paddings--- */

/* // both */
.p-20 { padding: 20px; }
.p-25 { padding: 25px; }
.p-40 { padding: 40px !important; }

/* // top */
.pt-10 { padding-top: 10px; }
.pt-40 { padding-top: 40px; }

/* // left  */
.pl-15 { padding-left: 15px; }
.pl-20 { padding-left: 20px; }
.pl-40 { padding-left: 40px; }
.pl-50 { padding-left: 50px !important; }

/* // right */
.pr-15 { padding-right: 15px;}
.pr-20 { padding-right: 20px;}
.pr-70 { padding-right: 70px !important;}

/* // bottom */
.pb-0  { padding-bottom: 0 !important;}
.pb-10 { padding-bottom: 10px;}
.pb-20 { padding-bottom: 20px;}
.pb-40 { padding-bottom: 40px;}



/* // ---margins--- */

/* // left */
.ml-0 {
  margin-left: 0px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-100{
  margin-left: 100px;
}

/* // top */
.mt-10 { margin-top: 10px;}
.mt-20 { margin-top: 20px;}

.mr-10 { margin-right: 10px;}

/* // widths */
.w-auto {
  width: auto !important;
}
.wp-50{
  width: 50% !important;
}
.w-full {
  width: 100% !important;
  max-width: none !important;
}

.width-full{
  width: 100% !important;
  max-width: none !important; 
}
.minw-500 {
  min-width: 500px;
}

.minw-720 {
  min-width: 720px;
}

.minw-800 {
  min-width: 800px;
}

.maxw-1500 {
  max-width: 1500px;
}

.w-50{
  width: 50px !important;
}
.w-100{
  width: 100px !important;
}

.w-500{
  width: 500px;
}


nav {
  display: flex;
  background-color: #fff;
  padding: 15px;
  justify-content: space-between;

  #left {
      display: flex;
  }

  #right {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 10px;
  }

  #profile-image {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      height: 46px;
      width: 46px;
  }

  
}

.no-border{
  border-radius: none !important;
}

.navbar-brand {
  display: flex;
}

/* .w {
  &-48 {
      width: 48px;
  }

  &-100-flex {
      width: 100%;
  }

} */

.h-48 {
  height: 48px;
}

/* // buttons */


.response_btn {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 10px 15px;
  font-family: RobotoBold;

  &:hover {
      cursor: pointer;
      border: 1px solid #0094FF;
  }
}

.option{
  .dot-icon{
      background-color: #d0d0d0;
      height: 5px;
      width: 5px;
      display: inline-block;
      border-radius: 50%;
  }
}

.btn-nav-right {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  position: relative;
  border: 1px solid #fff;
}

.btn-nav-right:hover {
  border: 1px solid #eeeeee;
  cursor: pointer;
}



.btn-dropdown {
  border: 1px solid #eeeeee;
  height: 48px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 20px;
  position: relative;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  -webkit-transition: border 100ms ease-out;
  -moz-transition: border 100ms ease-out;
  -o-transition: border 100ms ease-out;
  transition: border 100ms ease-out;
  gap: 10px;
}

.btn-dropdown-menu {
  display: none;
  width: inherit;
  position: absolute;
  top: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 999;
  min-width: 176px;
  right: 0;

  ul {
      padding: 0;
      margin: 0;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      gap: 0;

      li {
          list-style: none;
          list-style: none;
          display: flex;
          width: 100%;
          justify-content: end;

          &:last-child{
              border-bottom: none;
          }

          a {
              text-decoration: none;
              padding: 16px 20px;
              border-bottom: 1px solid #eeeeee;
              display: flex;
              white-space: nowrap;
              justify-content: end;
              flex: 1;

              &:first-child:hover{
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
              }
              &:last-child:hover{
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
              }

              &:hover {
                  background-color: #fdfdfd;
              }

              &:first-child{
                  border-bottom: 0px;

              }
              &:last-child{
                  border-top: none;
              }
          }
      }
  }

}
.btn-dropdown .title {
  /* // margin-right: 10px; */
  text-wrap: nowrap;
}

.btn-dropdown:hover {
  border: 1px solid #0094FF;
  cursor: pointer;
}

/* icons */

i.icon-link {
  height: 14px;
  width: 14px;
  background: url("/public/images/icons/link.svg") no-repeat;
  display: flex;
}

i.icon-scheduler {
  height: 16px;
  width: 15px;
  background: url("/public/images/icons/scheduler.svg") no-repeat;
  display: flex;
}

i.icon-reports {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/reports.svg") no-repeat;
  display: flex;
}

i.icon-price-book {
  height: 19px;
  width: 19px;
  background: url("/public/images/icons/price-book.svg") no-repeat;
  display: flex;
}

i.icon-accounting {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/accounting.svg") no-repeat;
  display: flex;
}

i.icon-settings {
  height: 17px;
  width: 17px;
  background: url("/public/images/icons/settings.svg") no-repeat;
  display: flex;
}

i.icon-arrow-down {
  height: 10px;
  width: 11px;
  background: url("/public/images/icons/arrow-down.svg") no-repeat;
  display: flex;
}

i.icon-media {
  height: 13px;
  width: 16px;
  background: url("/public/images/icons/media.svg") no-repeat;
  display: flex;
}

i.icon-arrow-right-light {
  height: 10px;
  width: 6px;
  background: url("/public/images/icons/arrow-right-light.svg") no-repeat;
  display: flex;
}

i.icon-arrow-right-dark {
  height: 12px;
  width: 6px;
  background: url("/public/images/icons/arrow-right-dark.svg") no-repeat;
  display: flex;
}

i.icon-arrow-left-dark {
  height: 10px;
  width: 6px;
  background: url("/public/images/icons/arrow-left-dark.svg") no-repeat;
  display: flex;
}

i.icon-search {
  height: 22px;
  width: 22px;
  background: url("/public/images/icons/search.svg") no-repeat;
  display: flex;
}

i.icon-search-white {
  height: 22px;
  width: 22px;
  background: url("/public/images/icons/search-white.svg") no-repeat;
  display: flex;
}

i.icon-bell {
  height: 18px;
  width: 18px;
  background: url("/public/images/icons/bell.svg") no-repeat;
  display: flex;
}

i.icon-trash {
  height: 14px;
  width: 14px;
  background: url("/public/images/icons/trash.svg") no-repeat;
  display: flex;
}

i.icon-bell.notification::before {
  height: 10px;
  width: 10px;
  background-color: #ff7a00;
  right: 5px;
  top: 5px;
  content: "";
  border-radius: 50%;
  position: absolute;
}


/* // ---borders--- */
.border-grey { border: 1px solid #F0F0F0; }
.border-radius-10 { border-radius: 10px; }
/* // backgrounds */

.bg-black {
  background-color: #0000008f;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.bg-white {
  background-color: #fff;
  border-left: 1px solid #F8F8F8;
  border-right: 1px solid #F8F8F8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}


#filename {
  display: none;
  align-items: center;
  gap: 10px;
  border: 2px solid #038ced;
  border-radius: 10px;
  padding: 15px;
  margin-left: 0;

  p {padding: 0;margin: 0;}
  img {
      max-width: 50px;
      max-height: 50px;
      border-radius: 4px;
  }

}


/* pricebook */

.add-service-page{
  .header{
      background-color: #0094FF !important;

      .cell{
          color: #fff ;
          background-color: #0094FF !important;
          padding: 0px 20px;
      }
  }
}

select{
  display: block;
  width: 20em;
  height: 50px;
  border: 1px solid #e2e3e5;
  border-radius: 5px 5px 5px 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.content-center{
  align-items: center
}

.content-between{
  justify-content: space-between;
}

#search_input_icon {
  position: relative;

  &::after {
      width: 22px;
      height: 22px;
      background: url("/public/images/icons/search.svg") no-repeat;
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      right: 13px;
  }

}

#search_filters{
  padding-right: 46px;
}




#pricebook{
  #tbl_orgs_service_price {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
  }
  
  #tbl_orgs_service_price td,
  #customers th {
      border: 1px solid #ddd;
      padding: 8px;
  }
  
  #tbl_orgs_service_price tr:nth-child(even) {
      background-color: #f2f2f2;
  }
  
  #customers tr:hover {
      background-color: #ddd;
  }
  
  #tbl_orgs_service_price th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #0094FF;
      color: white;
  }

  .select_label {
      display: block;
      text-align: left;
      font-size: 11pt;
      font-weight: bold;
      padding-left: 5px;
  }
  
  .div_cat {
      display: inline-block;
      margin-right: 20px;
  
  }
  
  .cat_select {
      display: block;
      width: 20em;
      border: 1px solid #e2e3e5;
      border-radius: 5px 5px 5px 5px
  }

  select {
      height: 50px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #CFD9E1;
      padding-left: 15px;
      padding-right: 40px;

      &:focus {
          outline: none;
          border: 2px solid #0094FF;
      }
  }
  
  
  
  .cat_label {
      display: block;
      margin-left: .3em;
      text-align: left;
      font-weight: 600;
  }

  /* // forms */
  
  .form_input {
      display: block;
      width: 30em;
      border: 1px solid #e2e3e5;
      border-radius: 5px 5px 5px 5px;
  }
  
  .form_input_height {
      height: 50px;
  }
  
  .div_form_item {
      margin-top: 8px;
  
  }
  
  .div_code_block {
      display: inline-block;
      vertical-align: top;
  }
  
  .div_code_block_border {
      border: 1px solid #e2e3e5;
      border-radius: 5px 5px 5px 5px;
      margin-left: 90px;
      margin-top: 16px;
  }
  
  .success {
      background-color: #04AA6D;
      margin-top: 5px;
  }
  
  .success:hover {
      background-color: #46a049;
  }
  
  .arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
  }
  
  .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
  }
  
  .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
  }
}


a.delete {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background: #82854C;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;


}


a.nav-link {
  display: flex;
  gap: 10px;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  text-decoration: none;
  border-radius: 10px;
  font-family: robotoBold;
  border: 1px solid #fff;
  -webkit-transition: border 100ms ease-out;
  -moz-transition: border 100ms ease-out;
  -o-transition: border 100ms ease-out;
  transition: border 100ms ease-out;
  text-wrap: nowrap;
}

a.nav-link:hover {
  border: 1px solid #eeeeee;
}

a.nav-link.active {
  border: 2px solid #0094FF;
}

b {
  font-family: robotoBold;
}

#navMenu {
  display: flex;
}

ul {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
}

li {
  list-style: none;
}

/* Search */

#search {
  position: fixed;
  background-color: #fff;
  top: 0;
  bottom: 0;
  width: 600px;
  z-index: 99999;
  overflow-y: scroll;
  right: -600px;
  animation: searchContent 200ms forwards ease;
}

@keyframes searchContent {
  0% {
      right: -600px;
  }

  100% {
      right: 0px;
  }
}

.easyOut {
  animation: searchContentOut 200ms forwards ease !important;
}

@keyframes searchContentOut {
  0% {
      right: 0px;
  }

  100% {
      right: -600px;
  }
}

.fadeOut {
  display: none;
  transition: opacity 100ms ease-out;
  opacity: 0;
}


#search-content {
  display: flex;
  gap: 10px;

  span {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
          background-color: #038ced;
          cursor: pointer;
      }
  }
}

.modal {
  display: none;
}

.modal_content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  top: 0;
  bottom: 0;
  /* // min-width: 600px; */
  max-width: 100%;
  z-index: 99999;
  overflow-y: scroll;
  right: -600px;
  animation: searchContent 200ms forwards ease;
  position: fixed;

  .header{
      justify-content: start;
  }

  /* // span {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     &:hover {
  //         background-color: #038ced;
  //         cursor: pointer;
  //     }
  // } */

  input {
      height: 50px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #CFD9E1;
      padding-left: 15px;
      /* // flex: 1; */

      &:focus {
          outline: none;
          border: 2px solid #0094FF;
      }

  }

  textarea {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #CFD9E1;
      padding: 15px;
      /* // flex: 1; */

      &:focus {
          outline: none;
          border: 2px solid #0094FF;
      }

  }

  .search-content {
      display: flex;
      gap: 10px;
  }

  h4.title{
      font-family: RobotoBold;
      font-size: 18px;
      padding: 40px;
      margin: 0;
      border-bottom: 1px solid #EEEEEE;
  }


  .permissions{
      margin-bottom: 20px;
      &>.title{
          background-color: #0094FF;
          color: #fff;
          font-family: RobotoBold;
          padding: 20px 40px;
      }
      .list{
          background-color: #F8FCFF;
          border-bottom: 1px solid #EAF4FB;
          padding: 20px 40px;
          display: flex;
          justify-content: space-between;

          .details{
              .title{
                  font-family: RobotoBold;
              }
          }
      }
  }

}

#search-recent-content {
  margin: 15px 0;
}

#search-recent {
  display: flex;
  gap: 10px;

  span {
      border: 1px solid #0094FF;
      background-color: #FAFDFF;
      color: #0094FF;
      border-radius: 10px;
      padding: 10px 15px;

      b {
          color: #0094FF;
      }

  }


}

#search-recent span:hover {
  background-color: #edf8fe;
  color: #0094FF;
  border: 1px solid #0094FF;
  cursor: pointer;
}




#search-recent-title {
  margin: 15px 0;
}

#search_result .result-list {
  border-top: 3px solid #F4F4F4;
}

#search_result .result-list h5 {
  padding: 0 20px;
}

#search_result .items {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F5F5F5;
  padding: 20px;
  align-items: center;
}

#search_result .items b {
  color: #038ced;
}


#search-modal {
  display: none;
}

::placeholder {
  color: #C3CFD7;
}

.width-200 {
  width: 200px;
  flex: none !important;
}

.width-auto {
  width: auto;
  flex: none !important;
}


.integration-list {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 25px;
  align-items: center;

  .left-side {
      display: flex;
      gap: 30px;

      .inner-right {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .header {
              font-family: RobotoBold;
          }

          .status {
              color: #D8D8D8;
          }
      }
  }
}


.btn {
  border-radius: 10px;
  width: max-content;
  min-width: 48px;
  height: 48px;
  font-family: robotoRegular;
  font-size: 16px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  text-wrap: nowrap;
  padding: 0 40px;
  transition: box-shadow .1s ease;

  &:hover:hover {
      cursor: pointer;
      /* // box-shadow: inset 0 0 0 2px #dddddd; */
  }

  &.btn-success {
      background-color: #19CC76;
      color: #fff;
      font-family: RobotoBold;
      padding: 10px 20px;

      &:hover {
          border: none;
          background-color: #15c36f;
      }

  }

  &.btn-action {
      background-color: #0094ff;
      color: #fff;
      font-family: RobotoBold;
  }

  &.btn-alert {
      background-color: #ff0004;
      color: #fff;
      font-family: RobotoBold;
  }

  &.btn-default {
      background-color: #fff;
      border: 1px solid #E8E8E8;
      box-shadow: 0px 4.598540306091309px 4.598540306091309px 0px rgba(0, 0, 0, 0.04);
      color: #4D5357;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
          border: 1px solid #0094ff;
          background-color: #fff;
      }
  }

  &.btn-action-border {
      border: 1px solid #eeeeee;
      font-family: robotoBold;
      padding-left: 20px;
      padding-right: 20px;

      /* // border:solid 2px #0094ff; */
      &:hover {
          border-color: #0094ff;
          border-width: 1px;
      }
  }

  &.btn-action-no-border {
      border: 1px solid transparent;
      font-family: robotoBold;
      padding-left: 20px;
      padding-right: 20px;

      /* // border:solid 2px #0094ff; */
      &:hover {
          border: 1px solid #0094ff;
          border-width: 1px;

          .option{
              .dot-icon{
                  background-color: #5E7E95;
              }
          }
      }
  }

  &.btn-sm {
      padding: 8px 15px;
      height: auto;
      border-radius: 8px;
      font-family: 'robotoRegular' !important;
  }

}

label {
  margin-bottom: 5px;
  display: block;
  margin-left: 10px;
  font-size: 16px;
  font-family: RobotoBold;
}

form {
  label {
      margin-bottom: 5px;
      display: block;
      margin-left: 10px;
      font-size: 16px;
      font-family: RobotoBold;
  }

  p {
      margin-left: 10px;
      margin-top: 0;
  }
}

input {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #CFD9E1;
  padding-left: 15px;

  &:focus {
      outline: none;
      border: 2px solid #0094FF;
  }

}

textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #CFD9E1;
  padding: 15px;

  &:focus {
      outline: none;
      border: 2px solid #0094FF;
  }

}

.flex-1{
  flex: 1;
}
.custom-dropdown {

  position: relative;

  select {
      height: 50px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #CFD9E1;

      padding-left: 15px;
      padding-right: 40px;
      appearance: none;

      &:focus {
          outline: none;
          border: 2px solid #0094FF;
      }

  }

  &::after {
      content: "▼";
      position: absolute;
      right: 15px;
      top: 18px;
      font-size: 13px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;

  .col {
      display: flex;
      flex-direction: column;
      flex: 1;
  }
}

.response_create_new{
  background-color: #EDF7FF;
  border-radius: 10px;
}

/* // organisation */

.response,
.response2 {
  margin-top: 5px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  max-height: 244px;
  overflow-y: auto;

  p {
      margin: 10px;
      color: #939393;
  }

  .row {
      border-bottom: 1px solid #F0F0F0;
      padding-bottom: 5px;

      &:last-child {
          border-bottom: none;

      }
  }

}

.response-selected,
.response-selected2,
.response-selected-utility,
.response-selected-filter,
.response-selected-material {
  border-radius: 10px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e9e9e9;

  p {
      margin: 10px;
      color: #939393;
  }

  .row {
      border-bottom: 1px solid #F0F0F0;
      padding-bottom: 5px;

      &:last-child {
          border-bottom: none;
      }
  }

}

.response-selected2 {
  border-radius: 10px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e9e9e9;

  p {
      margin: 10px;
      color: #939393;
  }

  .row {
      border-bottom: 1px solid #F0F0F0;
      padding-bottom: 5px;

      &:last-child {
          border-bottom: none;
      }
  }

}

.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .text {
      padding-left: 15px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.container {
  display: flex;
  gap: 10px;

  .form-group {
      flex: 1;

      &.row {
          display: flex;
          flex-direction: row;
          align-items: center;

          label {
              width: 50%;
          }

          .custom-dropdown {
              width: 100%;
          }
      }
  }
}

.space-around {
  justify-content: space-between;
}
.space-between {
  justify-content: space-between;
}

.upload_media {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  display: flex;
  padding: 15px 20px;
  gap: 10px;
  align-items: center;
  min-width: 260px;

  &:hover {
      border: 1px solid #0094FF;
      cursor: pointer;
  }
}


.col {
  display: flex;
  flex-direction: column;
}

.col-12 {
  display: flex;
  gap: 10px;

  .form-group {
      flex: 1;
  }
}

.logs {
  .section {
      padding: 20px;

  }

  .log_section {
      padding: 10px 0;

      p {
          margin: 0;
      }
  }
}


.alert {
  padding: 20px;
  margin-bottom: 20px;
}

.alert-warning {
  background-color: #FFF8F2;
  color: #E17008;
  font-family: robotoBold;
}



i.icon-search-loader {
  height: 24px;
  width: 24px;
  background: url("/public/images/icons/search-loader.svg") no-repeat center;
  display: flex;
}

.animate-rotate {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(359deg);
  }
}





/* // loading icon */
#search-spiner {
  /* // display: flex; */
  display: none;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}



.border {
  height: 1px;
  background-color: #F1F1F1;
}

.divider {
  height: 1px;
  background-color: #F1F1F1;
}

#organisation-form {
  #header {
      background-color: #F8FBFF;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
          margin: 0;
      }
  }
}

.content-area {
  width: 100%;
  max-width: 1500px;
  min-width: 1200px;

  .section {
      padding: 50px;
  }
}

.content-area-full {
  max-width: 100%;
  
  min-width: 1200px;

  .section {
      padding: 50px;
  }
}

/* // sections */

.section {
  border-bottom: 1px solid #F1F1F1;
  padding: 10px;
}

.border-top{
  border-top: 1px solid #F1F1F1;
}

.border-bottom{
  border-top: 1px solid #F1F1F1;
}


.fixed{ position: fixed;}
.sticky{ position: sticky;}
.absolute{ position: absolute;}
.bottom-0{ bottom: 0;}
.border-top-1{border-top: 1px solid #F1F1F1;}

#settings {
  display: flex;
  /* // margin-top: 92px; */
  padding-left: 400px;

  nav {
      display: flex;
      background-color: #fff;
      flex-direction: column;
      min-width: 400px;
      padding: 0;
      height: calc(100vh - 87px);
      overflow-y: scroll;
      position: fixed;
      left: 0;

      .section {
          border-bottom: 1px solid #F1F1F1;
          padding: 10px;


          ul li {
              a {
                  border: 1px solid #fff;
                  border-radius: 10px;
              }

              a.active {
                  background-color: #eaf7ff;
                  border: 2px solid #8bcfff;
              }


              a:not(.active):hover {
                  border: 1px solid #038ced;
              }
          }

          .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 0 0 20px;

              .btn-dropdown-menu {
                  ul {
                      
                      li {
                          a {
                              border: 1px solid #eeeeee;
                              border-radius: 0;

                              &:hover {
                                  background-color: #ffffff !important;
                                  border: 1px solid #038ced;
                              }

                              
                          }

                          
                          &:first-child a {
                              border-top-left-radius: 6px;
                              border-top-right-radius: 6px;
                              border-bottom-left-radius: 0px;
                              border-bottom-right-radius: 0px;
                          }
                          &:last-child a {
                              border-top-left-radius: 0px;
                              border-top-right-radius: 0px;
                              border-bottom-left-radius: 6px;
                              border-bottom-right-radius: 6px;
                          }

                          &:only-child a {
                              border-radius: 6px;
                          }
                      }
                  }
              }

          }
      }


  }

  ul {
      flex-direction: column;
      align-items: self-start;
      margin-left: 0;

      li {
          display: flex;
          width: 100%;
      }

      a {
          padding: 15px 20px;
          display: flex;
          flex: 1;
          text-decoration: none;
          /* // border-radius: 10px; */

          /* &:hover {
              // background-color: #EFF8FF;
          } */
      }
  }
}