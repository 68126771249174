/* icons */
.iconDocument {
	height: 16px;
	width: 16px;
	background: url("./assets/documentText.svg") no-repeat;
	display: flex;
}

.iconGallery {
	height: 16px;
	width: 16px;
	background: url("./assets/gallery.svg") no-repeat;
	display: flex;
}

.iconPlus {
	height: 10px;
	width: 10px;
	background: url("./assets/plus.svg") no-repeat;
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.mediaDocumentTabs {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 9px 10.8px 0px #f3f3f380;
	border-radius: 10px;
	margin-top: 16px;
}

.mediaDocumentTabs * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

/* header */

.mediaDocumentHeader {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #f2f2f2;
	padding: 0 35px;
	align-items: flex-end;
}

.headerLeft {
	display: flex;
	align-items: center;
	gap: 10px;
}

.headerRight {
	padding: 16px 0;
}

/* body */

.mediaDocumentBody {
	display: flex;
	flex-direction: column;
	padding: 35px 35px 24px;
	gap: 32px;
}

.videosSection,
.beforeSection {
	padding: 0;
}

.videosSection h3,
.beforeSection h3 {
	font-size: 18px;
	font-family: robotoBold;
	margin-bottom: 16px;
}
.videosSection div,
.beforeSection div {
	display: flex;
	gap: 10px;
}

.videosSection img,
.beforeSection img {
	height: 60px;
	width: 60px;
}
