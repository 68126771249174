.badge {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  font-family: robotoBold;
  font-size: 14px;
  line-height: 19.19px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  color: white;
}

/* Rectangular badge styles */
.lowStock {
  background-color: #ff8000;
  color: white;
}

.restockRequired {
  background-color: #ff3d3d;
  color: white;
}

.awaiting,
.apuBadge {
  background-color: #0084ff;
  color: white;
}

.default {
  background-color: #e5e5ea;
  color: #1c1c1e;
}

/* Circular badge styles */
.criticalValue {
  background-color: #ff3d3d;
  color: white;
}

.warningValue {
  background-color: #ff8000;
  color: white;
}
