/* icons */
.iconCardTick {
	height: 36px;
	width: 36px;
	background: url("./assets/cardTick.svg") no-repeat;
	display: flex;
}

.iconCoin {
	height: 36px;
	width: 36px;
	background: url("./assets/coin.svg") no-repeat;
	display: flex;
}

.iconWallet {
	height: 36px;
	width: 36px;
	background: url("./assets/wallet.svg") no-repeat;
	display: flex;
}

.summaryCards {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
}

.summaryCards * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
}

.card {
	display: flex;
	flex: 1;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	padding: 24px;
	background: #ffffff;
}

.cardDetails {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.cardDetails h3 {
	font-size: 16px;
	font-weight: 400;
}

.cardDetails p {
	font-size: 24px;
	font-family: robotoBold;
}

.cardDetails span {
	font-size: 14px;
}
