.iconDownArrow {
  height: 10px;
  width: 10px;
  background: url('../../assets/downArrow.svg') no-repeat;
  display: flex;
}

.headerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
  border-radius: 10px;
}

.filterContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 24px;
}

.filterContainer:nth-child(2) {
  gap: 40px;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filterGroup span {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 22.4px;
}
