.timelineContainer {
  position: relative;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  padding: 48px 0;
}

.timelineContainer::before {
  content: '';
  position: absolute;
  top: 48px;
  bottom: 48px;
  left: 200px;
  width: 2px;
  background-color: #e0e0e0;
  z-index: 0;
}

.milestoneRow {
  display: flex;
  gap: 24px;
  position: relative;
  padding-bottom: 16px;
}

.avatarColumn {
  flex: 0 0 220px;
  max-width: 220px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.contentColumn {
  flex: 0 0 875px;
  max-width: 875px;
}
