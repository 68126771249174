.iconTechnician {
  height: 27px;
  width: 27px;
  background: url('../../assets/technician.svg') no-repeat;
  display: flex;
}

.leaderboardContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 36px 36px 30px;
  margin-bottom: 24px;
  box-shadow: 0px 9px 10.8px 0px #f3f3f380;
}

.leaderboardTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.leaderboardTitleWrapper span {
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 22.4px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.leaderboardTitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.leaderboardTitle h2 {
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
}

.leaderboardTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0;
  background: #ffffff;
  border-radius: 8px;
  table-layout: fixed;
}

.leaderboardHeader th {
  padding: 12px 0;
  text-align: left;
  background: #f4f7f9;
  font-family: robotoBold;
  font-size: 16px;
  line-height: 18.75px;
  color: #0e1e29;
}

.leaderboardHeader th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 20px;
  width: 5%;
  text-align: center;
}

.leaderboardHeader th:nth-child(2) {
  padding-left: 35px;
  width: 55%;
}

.leaderboardHeader th:nth-child(3),
.leaderboardHeader th:nth-child(4),
.leaderboardHeader th:nth-child(5) {
  text-align: center;
  padding: 12px 35px;
  width: 10%;
}

.leaderboardHeader th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.leaderboardRow td {
  padding: 8px;
  font-family: robotoRegular;
  font-size: 16px;
  line-height: 18.75px;
  border-bottom: 1px solid #f6f6f6;
  vertical-align: middle;
}

.leaderboardRow td:first-child {
  text-align: center;
}

.leaderboardRow td:nth-child(2) {
  padding-left: 35px;
}

.leaderboardRow td:nth-child(3),
.leaderboardRow td:nth-child(4),
.leaderboardRow td:nth-child(5) {
  text-align: center;
  padding: 8px 35px;
}

.leaderboardRow td:last-child {
  padding-right: 42px;
}

.leaderboardRow:last-child td {
  border-bottom: none;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 18px;
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
}
